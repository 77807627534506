<h2 mat-dialog-title data-test-id="amtlichen-auszug-erstellen-dialog">Amtlichen Auszug erstellen</h2>
<form class="amtlichen-auszug-form" [formGroup]="form">
	<mat-dialog-content>
		<schir-int-client-spinner [stateResource]="alternativeTextList | async">
			<h3 class="mat-h4">Flaggenrecht</h3>
			<schir-int-client-amtlichen-ausdruck-erstellen-entry
					*ngFor="let formGroup of formService.formArray.controls"
					[form]="formGroup" data-test-id="entry">
			</schir-int-client-amtlichen-ausdruck-erstellen-entry>
		</schir-int-client-spinner>
	</mat-dialog-content>

	<mat-dialog-actions>
		<button mat-stroked-button (click)="onCancel()" data-test-id="cancel-button" class="focus-inwards-dark">
			Abbrechen
		</button>
		<button mat-flat-button color="primary" (click)="onSave()" [disabled]="!isValid()" class="icon-right"
		        data-test-id="drucken-button" type="submit" class="focus-inwards-dark">
			Erstellen
		</button>
	</mat-dialog-actions>
</form>
