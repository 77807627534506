<div class="grid" *ngIf="formGroup.enabled" data-test-id="aufgabe-anschreiben-root">
	<div class="row" [formGroup]="bodyGroup">
		<mat-form-field appearance="outline">
			<mat-label>Vorlage</mat-label>
			<mat-select cdkFocusInitial
			            [formControlName]="formServiceClass.feldVorlageId"
			            data-test-id="one-value-select-input">
				<mat-option *ngFor="let vorlage of vorlagen"
				            [value]="getVorlageUrl(vorlage)"
				            data-test-id="one-value-select-value" class="focus-inwards-dark">
					{{ vorlage.name }}
				</mat-option>
			</mat-select>
			<mat-error>Vorlage {{ messages.MISSING_REQUIRED_SELECT_VALUE }}</mat-error>
		</mat-form-field>
	</div>

	<div class="row" [formGroup]="bodyGroup">
		<mat-form-field appearance="outline" class="date-picker">
			<mat-label>Datum</mat-label>
			<input [formControlName]="formServiceClass.feldDatum" data-test-id="one-value-date-input" matInput
			       [matDatepicker]="picker" type="text"/>
			<mat-datepicker-toggle matSuffix [for]="picker" matTooltip="Kalender öffnen"
			                       aria-label="Kalender öffnen"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
		</mat-form-field>
	</div>

	<div class="row" [formGroup]="bodyGroup">
		<mat-form-field appearance="outline">
			<mat-label>Rechtspfleger</mat-label>
			<input [formControlName]="formServiceClass.feldRechtspfleger" matInput data-test-id="one-value-text-input"/>
		</mat-form-field>
	</div>

	<div class="row" [formGroup]="bodyGroup">
		<div class="column taetigkeit">
			<mat-radio-group aria-label="Bitte Aktion wählen" class="column"
			                 [formControlName]="formServiceClass.feldVersandTaetigkeit">
				<mat-radio-button color="primary" class="focus-inwards-dark" [value]="versandTaetigkeit.SENDEN_AN"
				                  data-test-id="senden-an-radio-button">
					senden an
				</mat-radio-button>
				<mat-radio-button color="primary" class="focus-inwards-dark"
				                  [value]="versandTaetigkeit.AUSHAENDIGEN_AN"
				                  data-test-id="aushaendigen-an-radio-button">aushändigen an
				</mat-radio-button>
			</mat-radio-group>
		</div>
		<schir-int-client-kontakt-kategorie-checkboxes class="column"
		                                               data-test-id="kontakt-kategorie-checkbox-root"
		                                               [control]="bodyGroup.controls[formServiceClass.feldVersandEmpfaenger]">
		</schir-int-client-kontakt-kategorie-checkboxes>
	</div>

	<schir-int-client-notiz-in-aufgabe-editor class="row note" [aufgabenTyp]="aufgabenTyp">
	</schir-int-client-notiz-in-aufgabe-editor>
</div>
