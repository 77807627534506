<div class="x-section__headline">
	<h2 class="mat-h1">Kontakte</h2>
</div>
<schir-int-client-kontakt-search-field #searchField class="searchfield"
                                       (doSearch)="doSearch($event)" (confirmSearch)="doConfirmUpdate()"
                                       [searchParameters]="searchParameters"
                                       (toggleKontaktDarstellung)="toggleKontaktDarstellung()">
</schir-int-client-kontakt-search-field>

<div *ngIf="isNewKontakt()" class="new-kontakt" data-test-id="new-kontakt" tabindex="0">
	<mat-icon class="kontakt-icon">add_circle</mat-icon>
	<p>Neuer Kontakt</p>
</div>

<div id="scroll-area" class="scroll-area">
	<ul #scrollarea class="c-list">
		<div #kontaktlist *ngFor="let kontakt of kontakte | async; let idPrefix = index" [id]="idPrefix+'k'"
		     [attr.self-link]="kontakt._links.self.href">
			<p class="group-separator" *ngIf="newGroupLetter(kontakt)">{{ groupChar }}</p>
			<div class="relative-container">
				<li #kontaktdiv class="kontakt-entry" data-test-id="kontakt-entry" [attr.data-title]="kontakt.title"
				    [attr.id]="idPrefix+'k'"
				    (click)="patchKontakt(kontakt)" (keyup.enter)="patchKontaktAndFocus(kontakt)" tabindex="0"
				    [ngClass]='isSelectedKontakt(kontakt)? "focused" : ""'>
					<div [ngStyle]="!kontakt.active && {'color': 'lightgray'}">
						<mat-icon class="kontakt-icon" *ngIf="kontakt.juristischePerson">business</mat-icon>
						<mat-icon class="kontakt-icon" *ngIf="!kontakt.juristischePerson">person</mat-icon>
						<span class="kontakt-name" data-test-id="kontakt-name">{{ kontakt.title }}</span>
						<div *ngIf="showAddress">
							<span class="kontakt-adresse"
							      data-test-id="kontakt-adresse">{{ kontakt.strasse }}&nbsp;{{ kontakt.hausnummer }}
								, {{ kontakt.plz }}&nbsp;{{ kontakt.stadt }} </span>
						</div>
					</div>
					<schir-int-client-button-round id="assign-button" class="assign-button" data-test-id="assign-button"
					                               toolTip="Rolle zuweisen" materialIcon="switch_account"
					                               cssClass="focus-inwards-dark"
					                               (click)="$event; openAssignMenu(kontakt, kontaktdiv, scrollarea)">
					</schir-int-client-button-round>
					<schir-int-client-button-round id="mail-address-button" class="mail-address-button"
					                               data-test-id="mail-address-button"
					                               toolTip="Adressdaten in die Zwischenablage kopieren"
					                               materialIcon="contact_mail"
					                               cssClass="focus-inwards-dark"
					                               (click)="$event; copyMailAddressToClipboard(kontakt)">
					</schir-int-client-button-round>
				</li>
			</div>
		</div>
	</ul>
	<span class="visually-hidden" role="alert">{{ audioAlert | async }}</span>
</div>

<schir-int-client-button-round class="add-button" data-test-id="hinzufuegen-button" *ngIf="!editMode && allowToCreate"
                               toolTip="Kontakt hinzufügen" materialIcon="person_add"
                               (click)="patchNewKontakt(); searchField.clearSearchField()"
                               cssClass="focus-inwards-dark">
</schir-int-client-button-round>
