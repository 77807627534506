import {Component} from '@angular/core';
import {CreateKorrespondenzVerfuegungFormService} from './create-korrespondenz-verfuegung.formservice';
import {CreateAufgabeFormService} from '@schir-int-client/aufgabe';
import {Observable} from 'rxjs';
import {VorlageFacade, VorlageResource} from '@schir-int-client/vorlage-shared';
import {BaseEditorComponent} from '@schir-int-client/tech';
import {UntypedFormGroup} from '@angular/forms';

@Component({
	selector: 'schir-int-client-create-korrespondenz-verfuegung-dialog',
	templateUrl: './create-korrespondenz-verfuegung-dialog.component.html',
	styleUrls: ['./create-korrespondenz-verfuegung-dialog.component.scss'],
	providers: [CreateKorrespondenzVerfuegungFormService, CreateAufgabeFormService],
})
export class CreateKorrespondenzVerfuegungDialogComponent extends BaseEditorComponent {
	vorlagen: Observable<VorlageResource[]>;

	constructor(public formService: CreateKorrespondenzVerfuegungFormService,
	            vorlageFacade: VorlageFacade) {
		super();

		this.vorlagen = vorlageFacade.getVorlagen();
	}

	get form(): UntypedFormGroup {
		return this.formService.form;
	}

	isValid(): boolean {
		return this.formService.checkPreconditions();
	}

	async submit(): Promise<boolean> {
		return this.formService.submit();
	}
}
