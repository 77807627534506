<schir-int-client-spinner [diameter]="20" [stateResource]="korrespondenzVorlage">

	<schir-int-client-more-button data-test-id="more-button" *ngIf="korrespondenzVorlage.loaded">

		<schir-int-client-file-upload #button
		                              *ngIf="(korrespondenzVorlage.resource | hasResourceLink: korrespondenzVorlageLinkRel.UPLOAD_FILE)"
		                              (fileChanged)="upload.emit($event)">
			<a #fileUploadButton mat-mini-fab color="primary" [matTooltipPosition]="'right'"
			   matTooltip="Korrespondenzvorlage hochladen und ersetzen"
			   aria-label="Korrespondenzvorlage hochladen und ersetzen"
			   data-test-id="upload-korrespondenz-button">
				<i class="material-icons" aria-hidden="true">cloud_upload</i>
			</a>
		</schir-int-client-file-upload>

		<schir-int-client-button-round
				*ngIf="(korrespondenzVorlage.resource | hasResourceLink: korrespondenzVorlageLinkRel.DELETE)" #button
				cssClass="focus-inwards-dark  schir-midi-button" (click)="delete.emit()"
				data-test-id="delete-korrespondenz-button" toolTip="Korrespondenzvorlage entfernen"
				toolTipPosition="right" materialIcon="remove">
		</schir-int-client-button-round>
	</schir-int-client-more-button>
</schir-int-client-spinner>
