import {Injectable} from '@angular/core';
import {isNil} from 'lodash-es';
import {take} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material/dialog';
import {PotentiallyDirty} from './dialog-warn/dialog-warn-interfaces';

@Injectable({
	providedIn: 'root',
})
export class ActiveEditorService {

	activeEditor: PotentiallyDirty;

	constructor() { }

	setActiveEditor(editor: PotentiallyDirty, dialogRef: MatDialogRef<any>) {
		this.activeEditor = editor;

		if (!isNil(dialogRef)) {
			dialogRef.afterClosed().pipe(take(1)).subscribe(data => {
				this.setActiveEditor(null, null);
			});
		}
	}

	getActiveEditor(): PotentiallyDirty {
		return this.activeEditor;
	}
}
