import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {isNull} from 'lodash-es';
import {Observable} from 'rxjs';
import {share, tap} from 'rxjs/operators';
import {apiRootSelector} from './api-root.selectors';
import {ApiRootResource} from './api-root.model';
import {LoadApiRootAction} from './api-root.actions';
import {ZaehlblattAktionTyp} from '@schir-int-client/verfahren-shared';
import {AppState} from 'apps/int-client/src/app/shared/store/app-state.model';

@Injectable({ providedIn: 'root' })
export class ApiRootFacade {

	isLFPR: boolean;
	standortValuesByRegister: { [register: string]: string[] } = {};
	zaehlblattAktionTypenByRegister: { [register: string]: ZaehlblattAktionTyp[] } = {};

	constructor(private store: Store<AppState>) {
		this.store.select(apiRootSelector).subscribe(value => {
			this.isLFPR = value?.features.lfpr;
			this.standortValuesByRegister = value?.features.standortAkteValuesByRegister;
			this.zaehlblattAktionTypenByRegister = value?.features.zaehlblattAktionTypenByRegister;
		});
	}

	apiRoot: Observable<ApiRootResource> = this.getApiRootResource();

	private getApiRootResource(): Observable<ApiRootResource> {
		return this.store.select(apiRootSelector).pipe(
			tap(apiRoot => {
				if (isNull(apiRoot)) {
					this.loadApiRootResource();
				}
			}),
			share(),
		);
	}

	public getCurrentApiRoot(): Observable<ApiRootResource> {
		return this.store.select(apiRootSelector);
	}

	private loadApiRootResource(): void {
		this.store.dispatch(new LoadApiRootAction());
	}
}
