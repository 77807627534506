import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {
	StatistikFacade,
	VerfahrenAktionStatistik,
	VerfahrenAktionStatistikRow,
	VerfahrenAktionTyp,
} from '@schir-int-client/statistik-shared';
import {RegisterName} from '@schir-int-client/register-shared';
import {ApiRootFacade, ApiRootResource} from '@schir-int-client/api-root';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl} from '@angular/forms';
import moment from 'moment';

@Component({
	selector: 'schir-int-client-verfahren-aktion-statistik',
	templateUrl: './verfahren-aktion-statistik.component.html',
	styleUrls: ['./verfahren-aktion-statistik.component.scss'],
})
export class VerfahrenAktionStatistikComponent implements OnInit, OnDestroy {
	displayedColumns: string[] = [];
	dataSource = new MatTableDataSource<TableRow>();
	vonDatumControl = new FormControl(moment().subtract(1, 'month').startOf('month'));
	bisDatumControl = new FormControl(moment().subtract(1, 'month').endOf('month'));
	private subscriptions: Subscription[] = [];

	protected readonly RegisterName = RegisterName;

	constructor(private statistikFacade: StatistikFacade, private apiRootFacade: ApiRootFacade) {}

	ngOnInit(): void {
		this.statistikFacade.loadVerfahrenAktionStatistik(this.vonDatumControl.value, this.bisDatumControl.value);
		this.subscriptions.push(this.apiRootFacade.getCurrentApiRoot().subscribe(apiRootResource => {
			this.adjustRegisters(apiRootResource);
		}));
		this.subscriptions.push(this.statistikFacade.verfahrenAktionStatistik.subscribe(value => {
			this.prepareTableData(value);
		}));
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	private adjustRegisters(apiRootResource: ApiRootResource) {
		if (apiRootResource.features.lfpr) {
			this.displayedColumns = ['Typ', RegisterName.LR];
		} else {
			this.displayedColumns = ['Typ', RegisterName.SSR, RegisterName.BSR, RegisterName.SBR];
		}
	}

	private prepareTableData(data: VerfahrenAktionStatistik) {
		if (data == null) {
			return;
		}
		let rows: TableRow[] = [];
		for (const verfahrenAktionTyp of [VerfahrenAktionTyp.NEU, VerfahrenAktionTyp.GESCHLOSSEN, VerfahrenAktionTyp.GELOESCHT]) {
			rows.push({
				typ: label[verfahrenAktionTyp],
				...data[verfahrenAktionTyp],
			});
		}
		this.dataSource.data = rows;
	}

	dateChosen() {
		const von = this.vonDatumControl.value;
		const bis = this.bisDatumControl.value;
		if (von && bis && von.isBefore(bis)) {
			this.statistikFacade.loadVerfahrenAktionStatistik(von, bis);
		}
	}
}

const label = {
	[VerfahrenAktionTyp.NEU]: 'Neu',
	[VerfahrenAktionTyp.GESCHLOSSEN]: 'Geschlossen',
	[VerfahrenAktionTyp.GELOESCHT]: 'Gelöscht',
};

interface TableRow extends VerfahrenAktionStatistikRow {
	typ: string,
}
