<div #verfahrenRoot class="x-section" *ngIf="verfahrenList | async as _verfahrenList">
	<div class=" x-section__headline">
		<h2 class="mat-h1 " tabindex="0">Verfahren</h2>
	</div>
	<div class="x-section__subline">
		<i class=" material-icons" aria-hidden="true">folder</i>
		<div class="mat-body">Anzahl:
			<span *ngIf="_verfahrenList.resource"
			      data-test-id="result-counter">{{ _verfahrenList.resource.page.totalElements }}</span>
			<span class="visually-hidden" role="alert">{{ audioAlert | async }}</span>
		</div>
		<schir-int-client-button-round *ngIf="_verfahrenList.resource | hasResourceLink: 'add'"
		                               popup="dialog"
		                               toolTip="Neues Verfahren anlegen" (click)="openCreateVerfahrenDialog()"
		                               data-test-id="create-verfahren-button" materialIcon="add"
		                               cssClass="focus-inwards-light schir-midi-button" class="add">
		</schir-int-client-button-round>
	</div>
	<schir-int-client-verfahren-list [verfahren]="_verfahrenList" class="x-section__main"
	                                 data-test-id="verfahren-list-root">
	</schir-int-client-verfahren-list>
</div>
