import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StatistikComponent} from './statistik/statistik.component';
import {MatTableModule} from '@angular/material/table';
import {TechModule} from '@schir-int-client/tech';
import {StatistikFacade, StatistikSharedModule} from '../../../statistik-shared/src';
import {MonatsStatistikComponent} from './statistik/monatsstatistik/monats-statistik.component';
import {ZaehlblattStatistikComponent} from './zaehlblatt/zaehlblatt-statistik.component';
import {VerfahrenAktionStatistikComponent} from './verfahren-aktion-statistik/verfahren-aktion-statistik.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		MatIconModule,
		MatButtonModule,
		MatTooltipModule,
		MatTableModule,
		TechModule,
		StatistikSharedModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		MatInputModule,
	],
	declarations: [
		StatistikComponent,
		MonatsStatistikComponent,
		ZaehlblattStatistikComponent,
		VerfahrenAktionStatistikComponent,
	],
	exports: [
		StatistikComponent,
		MonatsStatistikComponent,
		ZaehlblattStatistikComponent,
		VerfahrenAktionStatistikComponent
	],
	providers: [StatistikFacade],
})
export class StatistikModule {}
