import {Component} from '@angular/core';

@Component({
	selector: 'schir-int-client-whitelabel-error-page',
	templateUrl: './whitelabel-error-page.component.html',
	styleUrls: ['./whitelabel-error-page.component.scss'],
})
export class WhitelabelErrorPageComponent {

	// Es ist erst mal nur eine statische Seite, muss aber Komponente sein, um von Angular eingebunden zu werden.
}
