import {Component, OnInit} from '@angular/core';
import {VorlageFacade, VorlageResource} from '@schir-int-client/vorlage-shared';
import {AufgabeFacade, AufgabeResource} from '@schir-int-client/aufgabe-shared';
import {Observable} from 'rxjs';
import {KorrespondenzFormService} from '../korrespondenz.formservice';
import {first} from 'rxjs/operators';
import {addAriaAttributesToMatSelect, BaseEditorComponent} from '@schir-int-client/tech';
import {UntypedFormGroup} from '@angular/forms';
import {KorrespondenzValidationMessages} from '../korrespondenz.messages';
import {getUrl} from '@ngxp/rest';

@Component({
	selector: 'schir-int-client-create-korrespondenz-dialog',
	templateUrl: './create-korrespondenz-dialog.component.html',
	styleUrls: ['./create-korrespondenz-dialog.component.scss'],
	providers: [KorrespondenzFormService],
})
export class CreateKorrespondenzDialogComponent extends BaseEditorComponent implements OnInit {
	aufgabe: Observable<AufgabeResource>;
	vorlagen: Observable<VorlageResource[]>;

	readonly messages = KorrespondenzValidationMessages;

	constructor(private aufgabeFacade: AufgabeFacade,
	            vorlageFacade: VorlageFacade,
	            public formService: KorrespondenzFormService) {
		super();

		this.aufgabe = aufgabeFacade.aufgabe;
		this.vorlagen = vorlageFacade.getVorlagen();

		this.aufgabe.pipe(first()).subscribe(aufgabe => {
			this.formService.patchToAufgabe(aufgabe);
		});
	}

	ngOnInit(): void {
		addAriaAttributesToMatSelect();
	}

	getVorlageUrl(vorlage: VorlageResource) {
		return getUrl(vorlage);
	}

	get form(): UntypedFormGroup {
		return this.formService.form;
	}

	async submit(): Promise<boolean> {
		this.aufgabeFacade.createKorrespondenz(this.formService.form.value);

		return true;
	}
}
