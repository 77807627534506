<div class="x-section__headline" data-test-id="verfahren-search-headline">
	<mat-form-field class="focus-inwards-dark">
		<input type="search" matInput placeholder="Suchbegriff" (change)="onSearch()" [(ngModel)]="searchBy"
		       name="searchBy" id="searchButton" data-test-id="suche-input">
		<schir-int-client-button-round data-test-id="clear-search-button" toolTip="Suche zurücksetzen"
		                               aria-label="Suche zurücksetzen"
		                               (click)="clear()" materialIcon="close"
		                               cssClass="focus-inwards-dark schir-inline-button no-shadow">
		</schir-int-client-button-round>
		<schir-int-client-button-round data-test-id="verfahren-search-button" toolTip="Verfahren suchen"
		                               aria-label="Suche zurücksetzen"
		                               (click)="onSearch()" materialIcon="search"
		                               cssClass="focus-inwards-dark schir-inline-button no-shadow">
		</schir-int-client-button-round>
	</mat-form-field>
</div>
<div class="x-section__subline" data-test-id="verfahren-search-subline">
	<i class="material-icons">folder</i>
	<div class="mat-body">Anzahl:
		<span data-test-id="verfahren-count">{{ totalElements }}</span>
		<span class="visually-hidden" role="alert">{{ audioAlert | async }}</span>
	</div>

	<schir-int-client-button-round data-test-id="verfahren-anlegen-button" toolTip="Neue Verfahren anlegen"
	                               (click)="openCreateVerfahrenDialog()" popup="dialog" materialIcon="add"
	                               cssClass="focus-inwards-dark schir-inline-button">
	</schir-int-client-button-round>
</div>

<div class="x-section__main" data-test-id="verfahren-search-main">
	<schir-int-client-spinner [stateResource]="verfahrenResultList | async">
		<ul class="c-list" *ngIf="(verfahrenResultList | async) as _verfahrenList">
			<schir-int-client-verfahren-search-single *ngFor="let verfahren of _verfahrenList.resource"
			                                          [verfahren]="verfahren"
			                                          [selectedVerfahren]="selectedVerfahren | async"
			                                          data-test-id="verfahren">
			</schir-int-client-verfahren-search-single>
			<div class="center" *ngIf="hasMore">
				<button mat-stroked-button matDialogClose data-test-id="mehr-button" class="more-elements-button"
				        matTooltip="Weitere Verfahren anzeigen" aria-label="Weitere Verfahren anzeigen"
				        (click)="loadAllVerfahren()">Mehr
				</button>
			</div>
		</ul>
	</schir-int-client-spinner>
</div>
