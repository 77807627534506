<div class="x-section" data-test-id="posteingang-list-root">
	<div class="x-section__headline">
		<i class="material-icons" aria-hidden="true">email</i>
		<h2 class="mat-h1 margin-right-auto" tabindex="0">Post</h2>
	</div>
	<div class="x-section__subline"></div>
	<div class="x-section__main">
		<div class="c-spinner" data-test-id="spinner" #spinner>
			<mat-spinner></mat-spinner>
		</div>
		<ul class="c-list" *ngIf="(posteingaenge | async) as pList">
			<li class="c-list__item focus-inwards-dark" *ngFor="let posteingang of pList" data-test-id="eintrag">
				<a class="c-list__link" [routerLink]="['/posteingangBearbeiten', posteingang | toResourceUri]">
					<div *ngIf="posteingang.status == posteingangStatus.ERROR; else show_posteingang">
						<div class="c-row">
							<span class="c-row__item" data-test-id="fehler">Verarbeitungsfehler</span>
							<span class="c-row__item"
							      data-test-id="status">{{ posteingang.status | enumToLabel: posteingangStatusLabel }}</span>
						</div>
						<div class="c-row">
							<span class="c-row__item"
							      data-test-id="nachricht_nr">Nachricht #{{ posteingang.laufendeNr }}</span>
							<span class="c-row__item"
							      data-test-id="eingangs-datum">{{ posteingang.eingangsDatum | formatDate }}</span>
						</div>
						<div class="c-row c-row--secondary">
							<span class="c-row__item" data-test-id="admin-text">Die Nachricht wurde in den Fehler-Ordner verschoben. Bite
								informieren Sie Ihren Administrator.</span>
						</div>
					</div>
					<ng-template #show_posteingang>
						<div class="c-row c-row--header c-wrap">
							<span class="c-row__item c-row__item--header"
							      data-test-id="aktenzeichen">{{ renderAktenzeichen(posteingang.aktenzeichenVerfahren) }}</span>
							<span class="c-row__item c-row__item--secondary"
							      data-test-id="status">{{ posteingang.status | enumToLabel: posteingangStatusLabel }}</span>
						</div>
						<div class="c-row c-wrap">
							<span class="c-row__item">Kein Schiffsname</span>
							<span class="c-row__item"
							      data-test-id="eingangs-datum">{{ posteingang.eingangsDatum | formatDate }}</span>
						</div>
						<div class="c-row c-row--secondary">
							<span class="c-row__item" data-test-id="absender">{{ posteingang.firmenName }}
								- {{ posteingang.vorname }}
								{{ posteingang.nachname }}</span>
							<span class="c-row__item"></span>
						</div>
					</ng-template>
					<i class="material-icons c-list__icon" aria-hidden="true">arrow_right</i>
				</a>
			</li>
		</ul>
	</div>
</div>
