import {Action, ActionReducer} from '@ngrx/store';
import {ActionType, ApiRootLoadedAction} from './api-root.actions';
import {ApiRootResource} from './api-root.model';

export const apiRootFeatureState = 'ApiRootState';

export interface ApiRootState {
	apiRoot: ApiRootResource;
}

export const initialState: ApiRootState = { apiRoot: null };

export const apiRootReducer: ActionReducer<ApiRootState> = (state: ApiRootState = initialState, action: Action): ApiRootState => {
	switch (action.type) {
		case ActionType.ApiRootLoaded:
			return {
				...state,
				apiRoot: (<ApiRootLoadedAction>action).payload,
			};
	}
	return state;
};
