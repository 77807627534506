<div class="c-searchfield focus-inwards-dark">
	<button class="material-icons  focus-inwards-dark" type="button" [matMenuTriggerFor]="menu"
	        data-test-id="kontakt-filter-button"
	        matTooltip="Suchfilter auswählen" aria-label="Suchfilter auswählen" aria-haspopup="menu">
		<mat-icon [ngClass]="{'highlightFilter' : !standardFiltersSelected()}">filter_alt</mat-icon>
	</button>
	<div class="separator"></div>
	<mat-menu #menu="matMenu" class="short-mat-menu" data-test-id="filter-menue-kontakt-search-field">
		<div (click)="$event.stopPropagation()">
			<div mat-menu-item (keydown.enter)="toggleFilterElementViaEnter(natuerlich)" class="focus-inwards-dark">
				<schir-int-client-one-value-editor [type]="'checkbox'" [formGroup]="formGroup"
				                                   [label]="'Natürliche Person'"
				                                   [fieldControl]="formControl(natuerlich)"
				                                   (value)="onSearchModeChange()"
				                                   [attr.aria-label]="checkBoxLabel('Natürliche Person','natuerlich')"
				                                   [hideBoxShadow]=true
				                                   data-test-id="filter-natuerlicher-kontakt-checkbox">
				</schir-int-client-one-value-editor>
			</div>
			<div mat-menu-item (keydown.enter)="toggleFilterElementViaEnter(juristisch)" class="focus-inwards-dark">
				<schir-int-client-one-value-editor [type]="'checkbox'" [formGroup]="formGroup"
				                                   [label]="'Juristische Person'"
				                                   [fieldControl]="formControl(juristisch)"
				                                   (value)="onSearchModeChange()"
				                                   [attr.aria-label]="checkBoxLabel('Juristische Person','juristisch')"
				                                   [hideBoxShadow]=true
				                                   data-test-id="filter-juristischer-kontakt-checkbox">
				</schir-int-client-one-value-editor>
			</div>
			<div mat-menu-item (keydown.enter)="toggleFilterElementViaEnter(behoerden)" class="focus-inwards-dark">
				<schir-int-client-one-value-editor [type]="'checkbox'" [formGroup]="formGroup" [label]="'Behörden'"
				                                   [fieldControl]="formControl(behoerden)"
				                                   (value)="onSearchModeChange()"
				                                   [attr.aria-label]="checkBoxLabel('Behörden','behoerden')"
				                                   [hideBoxShadow]=true
				                                   data-test-id="filter-behoerden-kontakt-checkbox">
				</schir-int-client-one-value-editor>
			</div>
			<hr>
			<div mat-menu-item (keydown.enter)="toggleFilterElementViaEnter(aktiv)" class="focus-inwards-dark">
				<schir-int-client-one-value-editor [type]="'checkbox'" [formGroup]="formGroup"
				                                   [label]="'Aktive Kontakte'"
				                                   [fieldControl]="formControl(aktiv)" (value)="onSearchModeChange()"
				                                   [attr.aria-label]="checkBoxLabel('Aktive Kontakte','aktiv')"
				                                   [hideBoxShadow]=true
				                                   data-test-id="filter-aktiver-kontakt-checkbox">
				</schir-int-client-one-value-editor>
			</div>
			<div mat-menu-item (keydown.enter)="toggleFilterElementViaEnter(inaktiv)" class="focus-inwards-dark">
				<schir-int-client-one-value-editor [type]="'checkbox'" [formGroup]="formGroup"
				                                   [label]="'Inaktive Kontakte'"
				                                   [fieldControl]="formControl(inaktiv)" (value)="onSearchModeChange()"
				                                   [attr.aria-label]="checkBoxLabel('Inaktive Kontakte', inaktiv)"
				                                   [hideBoxShadow]=true
				                                   data-test-id="filter-inaktiver-kontakt-checkbox">
				</schir-int-client-one-value-editor>
			</div>
			<div>
				<hr>
				<div mat-menu-item
				     (keydown.enter)="toggleFilterElementViaEnter(kontaktdarstellung); toggleKontaktDarstellung.emit()"
				     class="focus-inwards-dark">
					<schir-int-client-one-value-editor [type]="'checkbox'" [formGroup]="formGroup"
					                                   [label]="'Lange Kontakt-Darstellung'"
					                                   [fieldControl]="formControl(kontaktdarstellung)"
					                                   (value)="toggleKontaktDarstellung.emit()"
					                                   [attr.aria-label]="checkBoxLabel('Lange Kontakt-Darstellung', kontaktdarstellung)"
					                                   [hideBoxShadow]=true
					                                   data-test-id="lange-kontakt-darstellung-kontakt-checkbox">
					</schir-int-client-one-value-editor>
				</div>
			</div>
		</div>
	</mat-menu>
	<input type="text" [(ngModel)]="searchParameters.searchString" name="search" placeholder="Suche"
	       id="kontakt-search-input" data-test-id="kontakt-search-input" (input)="search()"
	       (keyup.enter)="confirm()">
	<button class="material-icons focus-inwards-dark" type="button" (click)="clearSearchField()"
	        data-test-id="clear-search-button" aria-label="Suche zurücksetzen" matTooltip="Suche zurücksetzen">
		<mat-icon>close</mat-icon>
	</button>
	<button class="material-icons focus-inwards-dark" type="submit" data-test-id="verfahren-search-button"
	        aria-label="Kontakt suchen" matTooltip="Kontakt suchen"
	        (click)="confirm()">
		<mat-icon>search</mat-icon>
	</button>
</div>
