import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {apiRootSelector} from '@schir-int-client/api-root';
import {VerfahrenFacade} from 'libs/verfahren-shared/src/lib/verfahren.facade';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {
	AdressverwaltungActions,
	AssignKontaktAction,
	AssignKontaktSuccessAction,
	CreateKontaktAction,
	CreateKontaktSuccessAction as CreateKontaktSuccessAction,
	LoadKontakteByVerfahrenAction,
	LoadKontakteByVerfahrenSuccessAction,
	LoadKontakteSuccessAction,
	UpdateKontaktAction,
	UpdateKontaktSuccessAction,
	UpdateVerfahrenKontaktAktenzeichenAction,
	UpdateVerfahrenKontaktAktenzeichenSuccessAction,
	UpdateVerfahrenKontaktNotizAction,
	UpdateVerfahrenKontaktNotizSuccessAction,
} from './adressverwaltung.actions';
import {AdressverwaltungFacade} from './adressverwaltung.facade';
import {KontaktKategorie, KontaktWithKategorie} from './adressverwaltung.model';
import {AdressverwaltungService} from './adressverwaltung.service';
import {AppState} from '../../../../apps/int-client/src/app/shared/store/app-state.model';

@Injectable()
export class AdressverwaltungEffects {
	constructor(
		private actions: Actions,
		private adressverwaltungService: AdressverwaltungService,
		private adressverwaltungFacade: AdressverwaltungFacade,
		private verfahrenFacade: VerfahrenFacade,
		private store: Store<AppState>,
	) {}


	loadKontakte = createEffect(() => this.actions.pipe(
		ofType(AdressverwaltungActions.LOAD_KONTAKTE),
		withLatestFrom(this.store.select(apiRootSelector)),
		switchMap(([, apiRoot]) => {
			return this.adressverwaltungService.getAll(apiRoot).pipe(
				map(kontakte => new LoadKontakteSuccessAction(kontakte)),
			);
		}),
	));

	loadKontakteInVerfahren = createEffect(() => this.actions.pipe(
		ofType(AdressverwaltungActions.LOAD_KONTAKTE_BY_VERFAHREN),
		switchMap(action => {
			return this.adressverwaltungService.loadKontakteInVerfahren((<LoadKontakteByVerfahrenAction>action).verfahren).pipe(
				map(kontakte => new LoadKontakteByVerfahrenSuccessAction(kontakte)),
			);
		}),
	));

	createKontakt = createEffect(() => this.actions.pipe(
		ofType(AdressverwaltungActions.CREATE_KONTAKT),
		withLatestFrom(this.store.select(apiRootSelector)),
		switchMap(([action, apiRoot]) => {
			return this.adressverwaltungService.create(apiRoot, (<CreateKontaktAction>action).kontakt).pipe(
				map(k => new CreateKontaktSuccessAction(k)),
			);
		}),
	));


	assignKontakt = createEffect(() => this.actions.pipe(
		ofType(AdressverwaltungActions.ASSIGN_KONTAKT),
		withLatestFrom(this.verfahrenFacade.verfahrenSingle, this.adressverwaltungFacade.selectedKontakt),
		switchMap(([action, verfahren, kontakt]) => {
			const kategorie: KontaktKategorie = (<AssignKontaktAction>action).kategorie;
			return this.adressverwaltungService.assignKontakt(verfahren, kontakt, kategorie).pipe(
				map(updatedVerfahren => new AssignKontaktSuccessAction(kategorie, updatedVerfahren)));
		}),
	));


	updateKontakt = createEffect(() => this.actions.pipe(
		ofType(AdressverwaltungActions.UPDATE_KONTAKT),
		withLatestFrom(this.adressverwaltungFacade.selectedKontakt),
		switchMap(([action, kontaktExt]) => {
			return this.adressverwaltungService.update(kontaktExt, (<UpdateKontaktAction>action).newKontakt).pipe(
				map(kontakt => new UpdateKontaktSuccessAction(kontakt)));
		}),
	));


	updateVerfahrenKontaktNotiz = createEffect(() => this.actions.pipe(
		ofType(AdressverwaltungActions.UPDATE_VERFAHREN_KONTAKT_NOTIZ),
		switchMap((action) => {
			const kontaktWithKategorie: KontaktWithKategorie = (<UpdateVerfahrenKontaktNotizAction>action).kontakt;
			return this.adressverwaltungService.updateVerfahrenKontaktNotiz(kontaktWithKategorie.kontakt).pipe(
				map(value => {
					return {
						...kontaktWithKategorie,
						kontakt: value,
					};
				}),
				map(k => new UpdateVerfahrenKontaktNotizSuccessAction(k)));
		}),
	));

	updateVerfahrenKontaktAktenzeichen = createEffect(() => this.actions.pipe(
		ofType(AdressverwaltungActions.UPDATE_VERFAHREN_KONTAKT_AKTENZEICHEN),
		switchMap((action) => {
			const kontaktWithKategorie: KontaktWithKategorie = (<UpdateVerfahrenKontaktAktenzeichenAction>action).kontakt;
			return this.adressverwaltungService.updateVerfahrenKontaktAktenzeichen(kontaktWithKategorie.kontakt).pipe(
				map(value => {
					return {
						...kontaktWithKategorie,
						kontakt: value,
					};
				}),
				map(k => new UpdateVerfahrenKontaktAktenzeichenSuccessAction(k)));
		}),
	));
}
