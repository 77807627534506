import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {apiRootSelector} from '@schir-int-client/api-root';
import {DoNothingAction} from '@schir-int-client/ngrx-helpers';
import {DialogService} from '@schir-int-client/tech';
import {map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
	DownloadDocumentAction,
	LoadAssigendPosteingaengeAction,
	LoadAssigendPosteingaengeSuccessAction,
	LoadPosteingaengeAction,
	LoadPosteingangAction,
	PosteingaengeLoadedAction,
	PosteingangActions,
	PosteingangDeleteAction,
	PosteingangDeletedAction,
	PosteingangLoadedAction,
} from './posteingang.actions';
import {PosteingangFacade} from './posteingang.facade';
import {PosteingangMessages} from './posteingang.messages';
import {PosteingangService} from './posteingang.service';
import {AppState} from '../../../../apps/int-client/src/app/shared/store/app-state.model';

@Injectable()
export class PosteingangEffects {
	constructor(
		private actions: Actions,
		private posteingangService: PosteingangService,
		private store: Store<AppState>,
		private dialogService: DialogService,
		private posteingangFacade: PosteingangFacade,
		private router: Router,
	) { }


	loadPosteingaenge = createEffect(() => this.actions.pipe(
		ofType(PosteingangActions.LOAD_POSTEINGAENGE),
		withLatestFrom(this.store.select(apiRootSelector)),
		switchMap(([, apiRoot]) => {
			return this.posteingangService.getAll(apiRoot).pipe(
				map(posteingaenge => new PosteingaengeLoadedAction(posteingaenge)),
			);
		}),
	));


	loadPosteingang = createEffect(() => this.actions.pipe(
		ofType(PosteingangActions.LOAD_POSTEINGANG),
		switchMap(action => {
			return this.posteingangService.getOne((<LoadPosteingangAction>action).posteingangUri).pipe(
				map(posteingang => new PosteingangLoadedAction(posteingang)),
			);
		}),
	));


	loadAssignedPosteingang = createEffect(() => this.actions.pipe(
		ofType(PosteingangActions.LOAD_ASSIGNED_POSTEINGAENGE),
		switchMap(action => {
			const loadAction = <LoadAssigendPosteingaengeAction>action;
			return this.posteingangService.loadAssignedPosteingaenge(loadAction.resource, loadAction.linkRel).pipe(
				map(posteingaenge => new LoadAssigendPosteingaengeSuccessAction(posteingaenge, loadAction.resource)),
			);
		}),
	));


	confirmPosteingangAbschliessen = createEffect(() => this.actions.pipe(
		ofType(PosteingangActions.ASKTO_POSTEINGANG_ABSCHLIESSEN),
		switchMap(() => {
			return this.dialogService.openConfirmationDialog(PosteingangMessages.ASK_TO_POSTEINGANG_ABSCHLIESSEN).pipe(
				map(confirmed => confirmed ? new PosteingangDeleteAction() : new DoNothingAction()),
			);
		}),
	));


	deletePosteingang = createEffect(() => this.actions.pipe(
		ofType(PosteingangActions.POSTEINGANG_DELETE),
		withLatestFrom(this.posteingangFacade.posteingang),
		switchMap(([, posteingang]) => {
			return this.posteingangService.delete(posteingang).pipe(
				mergeMap(() => [new LoadPosteingaengeAction(), new PosteingangDeletedAction()]),
			);
		}),
	));


	navigateAfterPosteingangDeleted = createEffect(() => this.actions.pipe(
		ofType(PosteingangActions.POSTEINGANG_DELETED),
		tap(() => this.router.navigate(['/dashboard'])),
	), { dispatch: false });


	downloadDocument = createEffect(() => this.actions.pipe(
		ofType(PosteingangActions.DOWNLOAD_DOCUMENT),
		tap(action => this.posteingangService.downloadDocument((<DownloadDocumentAction>action).payload),
		),
	), { dispatch: false });
}
