import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BaseEditorComponent, DialogService} from '@schir-int-client/tech';
import {FormGroup, UntypedFormGroup} from '@angular/forms';
import {ZaehlblattFacade} from './zaehlblatt.facade';
import {ZaehlblattAktionTypLabel, ZaehlblattEntry} from '@schir-int-client/verfahren-shared';
import {DatePipe} from '@angular/common';
import {first} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'schir-int-client-zaehlblatt-anzeigen',
	templateUrl: './zaehlblatt-anzeigen.component.html',
	styleUrls: ['./zaehlblatt-anzeigen.component.scss'],
})
export class ZaehlblattAnzeigenComponent extends BaseEditorComponent implements OnInit, OnDestroy {

	form: UntypedFormGroup = new FormGroup<any>({});
	readonly aktenzeichenRendered: string;
	entryList: Array<ZaehlblattEntry>;
	private datePipe = new DatePipe('de-DE');
	readonly label = ZaehlblattAktionTypLabel;

	private subscription: Subscription;

	constructor(@Inject(MAT_DIALOG_DATA) data, private facade: ZaehlblattFacade, private dialogService: DialogService) {
		super();
		this.aktenzeichenRendered = data['aktenzeichen'];
	}

	ngOnInit() {
		this.subscription = this.facade.zaehlblatt.subscribe(res => {
			if (res._embedded) {
				this.entryList = Array.from(res._embedded.zaehlblattEntryList);
				this.entryList.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
			}
		});
	}

	submit(): Promise<boolean> {
		return Promise.resolve(false);
	}

	format(moment: string) {
		return this.datePipe.transform(moment, 'dd.MM.yyyy HH:mm');
	}

	deleteEntry(entry: ZaehlblattEntry) {
		const msg = 'Möchten Sie die Zählblattaktion "' + this.label[entry.type] + '" wirklich löschen?';
		this.dialogService.openDialogYesNo(msg).pipe(first()).subscribe(data => {
			if (data.agree) {
				this.facade.delete(entry);
			}
		});
	}

	onCancel() {
		this.onClickButtonClose.emit();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
