<h2 mat-dialog-title tabindex="0" *ngIf="headline" class="narrow"
    data-test-id="notification-headline">{{ headline }}</h2>
<mat-dialog-content data-test-id="notification-message">
	<p *ngIf="errorId">Fehler ID: <b>{{ errorId }}</b></p>
	<p tabindex="0" *ngFor="let message of messageTokens">{{ message }}</p>
</mat-dialog-content>
<mat-dialog-actions>
	<button *ngIf="attachment" color="secondary" mat-stroked-button type="button" class="focus-inwards-dark"
	        data-test-id="notification-attachment-button"
	        (click)="copyAttachment()">{{ attachmentLabel ? attachmentLabel : 'Hinweis kopieren' }}>
	</button>

	<button dialogFocus mat-flat-button stroked-button color="primary" type="button" (click)="ok()"
	        data-test-id="notification-ok-button" class="focus-inwards-dark">OK
	</button>
</mat-dialog-actions>
