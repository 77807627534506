import {Component, Inject, OnInit} from '@angular/core';
import {BaseEditorComponent} from '@schir-int-client/tech';
import {FormControl, FormGroup} from '@angular/forms';
import {VerfahrenFacade, VerfahrenLinkRel, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'schir-int-client-kennzeichen',
	templateUrl: './kennzeichen.component.html',
	styleUrls: ['./kennzeichen.component.scss'],
})
export class KennzeichenComponent extends BaseEditorComponent
	implements OnInit {

	private verfahren: VerfahrenResource;
	private oldKennzeichen: string;

	readonly aktenzeichenRendered: string;
	private readonly kennzeichen: string = 'lfpr.ar_kennzeichen';

	form: FormGroup = new FormGroup({
		kennzeichen: new FormControl<string>(null),
	});

	constructor(@Inject(MAT_DIALOG_DATA) data, private facade: VerfahrenFacade) {
		super();
		this.verfahren = data['verfahren'];
		this.aktenzeichenRendered = data['aktenzeichen'];
	}

	ngOnInit(): void {
		if (this.verfahren.properties) {
			this.verfahren.properties
				.filter(prop => this.kennzeichen === prop.key)
				.forEach(prop => {
					this.getFormControl().setValue(prop.value);
					this.oldKennzeichen = prop.value;
				});
		}
	}

	canSafe(): boolean {
		const newValue = this.getFormControl().value;
		return newValue != this.oldKennzeichen;
	}

	async submit(): Promise<boolean> {
		if (!this.form.valid) {
			return false;
		}
		this.facade.setVerfahrenProperty(this.verfahren, VerfahrenLinkRel.AR_KENNZEICHEN, this.getFormControl().value);
		return true;
	}

	getFormControl() {
		return <FormControl>this.form.controls['kennzeichen'];
	}


}
