<h2 mat-dialog-title data-test-id="dialog-title" tabindex="0" class="narrow">Zählblatteintrag zu
	Verfahren {{ aktenzeichenRendered }}</h2>
<mat-dialog-content>
	<form [formGroup]="form">
		<div *ngFor="let controlName of types" (click)="increment(controlName)">
			<div class="fancy-checkbox">
				<schir-int-client-one-value-editor [type]="'number'" id="{{controlName}}-input"
				                                   [attr.data-test-id]="controlName+'-input'"
				                                   [fieldControl]="getControl(controlName)" [label]="'Anzahl'"
				                                   [attr.aria-label]="'Anzahl '+labels[controlName]"
				                                   [stepsize]="1">
				</schir-int-client-one-value-editor>
				<label for="{{controlName}}-input">{{ labels[controlName] }}</label>
			</div>
			<div *ngIf="!getControl(controlName).valid" class="input-error">Anzahl muss zwischen 0 und 20 liegen.</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-stroked-button (click)="onCancel()" data-test-id="cancel-button" class="focus-inwards-dark">
		Abbrechen
	</button>
	<button mat-flat-button (click)="onSave()" [disabled]="!canSafe()" color="primary"
	        class="icon-right focus-inwards-dark"
	        data-test-id="save-button">Speichern
	</button>
</mat-dialog-actions>
