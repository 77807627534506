import {Store} from '@ngrx/store';
import {doIfLoadingRequired, LoadingGuard} from '@schir-int-client/ngrx-helpers';
import {verfahrenSingleSelector} from 'libs/verfahren-shared/src/lib/verfahren.selectors';
import {isEmpty, isNil} from 'lodash-es';
import {combineLatest, Observable, Subject, tap} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {
	AssignKontaktAction,
	ChangeKontaktEditModeAction,
	CreateKontaktAction,
	LoadKontakteAction,
	LoadKontakteByVerfahrenAction,
	SetSelectedKontaktAction,
	UpdateKontaktAction,
	UpdateVerfahrenKontaktAktenzeichenAction,
	UpdateVerfahrenKontaktNotizAction,
} from './adressverwaltung.actions';
import {
	Kontakt,
	KontakteInVerfahren,
	KontaktKategorie,
	KontaktResource,
	KontaktWithKategorie,
} from './adressverwaltung.model';
import {
	editModeSelector,
	kontakteInVerfahrenSelector,
	kontakteSelector,
	selectedKontaktSelector,
} from './adressverwaltung.selectors';
import {AdressverwaltungRootState} from './adressverwaltung.state';
import {AdressverwaltungService} from './adressverwaltung.service';
import {VerfahrenListResource} from '../../../verfahren-shared/src/lib/verfahren.model';
import {VerfahrenLinkRel} from '../../../verfahren-shared/src/lib/verfahren.linkrel';
import {ElementRef, Injectable} from '@angular/core';
import {hasLink} from '@ngxp/rest';
import {KontaktLinkRel} from './adressverwaltung.linkrel';

@Injectable({ providedIn: 'root' })
export class AdressverwaltungFacade {

	selectedKontakt: Observable<KontaktResource> = this.store.select(selectedKontaktSelector);
	editMode: Observable<boolean> = this.store.select(editModeSelector);

	alleKontakte: Observable<KontakteInVerfahren> = this.loadKontakteInVerfahren();

	private eigentuemerNeuKontakteSubject: Subject<KontaktResource[]> = new Subject<KontaktResource[]>();
	private eigentuemerAltKontakteSubject: Subject<KontaktResource[]> = new Subject<KontaktResource[]>();
	private vertreterKontakteSubject: Subject<KontaktResource[]> = new Subject<KontaktResource[]>();
	private glaeubigerNeuKontakteSubject: Subject<KontaktResource[]> = new Subject<KontaktResource[]>();
	private glaeubigerAltKontakteSubject: Subject<KontaktResource[]> = new Subject<KontaktResource[]>();
	private notarKontakteSubject: Subject<KontaktResource[]> = new Subject<KontaktResource[]>();
	private behoerdenKontakteSubject: Subject<KontaktResource[]> = new Subject<KontaktResource[]>();
	private sonstigeKontakteSubject: Subject<KontaktResource[]> = new Subject<KontaktResource[]>();
	private rechtsanwaltKontakteSubject: Subject<KontaktResource[]> = new Subject<KontaktResource[]>();
	private phgKontakteSubject: Subject<KontaktResource[]> = new Subject<KontaktResource[]>();
	private gesellschafterKontakteSubject: Subject<KontaktResource[]> = new Subject<KontaktResource[]>();
	private eingetragenerEigentuemerKontakteSubject: Subject<KontaktResource[]> = new Subject<KontaktResource[]>();
	private renderedKontaktSubject: Subject<ElementRef> = new Subject();
	private allowedToCreateSubject: Subject<boolean> = new Subject();

	eigentuemerNeuKontakte: Observable<KontaktResource[]> = this.eigentuemerNeuKontakteSubject.asObservable();
	eigentuemerAltKontakte: Observable<KontaktResource[]> = this.eigentuemerAltKontakteSubject.asObservable();
	vertreterKontakte: Observable<KontaktResource[]> = this.vertreterKontakteSubject.asObservable();
	glaeubigerNeuKontakte: Observable<KontaktResource[]> = this.glaeubigerNeuKontakteSubject.asObservable();
	glaeubigerAltKontakte: Observable<KontaktResource[]> = this.glaeubigerAltKontakteSubject.asObservable();
	notarKontakte: Observable<KontaktResource[]> = this.notarKontakteSubject.asObservable();
	behoerdenKontakte: Observable<KontaktResource[]> = this.behoerdenKontakteSubject.asObservable();
	sonstigeKontakte: Observable<KontaktResource[]> = this.sonstigeKontakteSubject.asObservable();
	rechtsanwaltKontakte: Observable<KontaktResource[]> = this.rechtsanwaltKontakteSubject.asObservable();
	phgKontakte: Observable<KontaktResource[]> = this.phgKontakteSubject.asObservable();
	gesellschafterKontakte: Observable<KontaktResource[]> = this.gesellschafterKontakteSubject.asObservable();
	eingetragenerEigentuemerKontakte: Observable<KontaktResource[]> = this.eingetragenerEigentuemerKontakteSubject.asObservable();
	renderedKontakt: Observable<ElementRef> = this.renderedKontaktSubject.asObservable();
	allowedToCreate: Observable<boolean> = this.allowedToCreateSubject.asObservable();
	loadingKontakte = new LoadingGuard(this.store);

	constructor(private store: Store<AdressverwaltungRootState>, private service: AdressverwaltungService) {
		this.alleKontakte.subscribe(kontakte => {
			this.eigentuemerNeuKontakteSubject.next(kontakte[KontaktKategorie.EIGENTUEMER_NEU]);
			this.eigentuemerAltKontakteSubject.next(kontakte[KontaktKategorie.EIGENTUEMER_ALT]);
			this.vertreterKontakteSubject.next(kontakte[KontaktKategorie.VERTRETER]);
			this.glaeubigerNeuKontakteSubject.next(kontakte[KontaktKategorie.GLAEUBIGER_NEU]);
			this.glaeubigerAltKontakteSubject.next(kontakte[KontaktKategorie.GLAEUBIGER_ALT]);
			this.notarKontakteSubject.next(kontakte[KontaktKategorie.NOTAR]);
			this.behoerdenKontakteSubject.next(kontakte[KontaktKategorie.BEHOERDEN]);
			this.sonstigeKontakteSubject.next(kontakte[KontaktKategorie.SONSTIGE]);
			this.rechtsanwaltKontakteSubject.next(kontakte[KontaktKategorie.RECHTSANWALT]);
			this.phgKontakteSubject.next(kontakte[KontaktKategorie.PHG]);
			this.gesellschafterKontakteSubject.next(kontakte[KontaktKategorie.GESELLSCHAFTER]);
			this.eingetragenerEigentuemerKontakteSubject.next(kontakte[KontaktKategorie.EINGETRAGENER_EIGENTUEMER]);
		});
	}

	rendered(kontaktDiv: ElementRef): void {
		this.renderedKontaktSubject.next(kontaktDiv);
	}

	private loadKontakteInVerfahren(): Observable<KontakteInVerfahren> {
		return combineLatest([this.store.select(verfahrenSingleSelector), this.store.select(kontakteInVerfahrenSelector)]).pipe(
			filter(([verfahren]) => !isNil(verfahren)),
			filter(([verfahren, kontakte]) => !this.loadingKontakte.mustLoadFirst(kontakte, () => new LoadKontakteByVerfahrenAction(verfahren), verfahren._links[VerfahrenLinkRel.KONTAKTE_IN_KATEGORIEN].href),
			),
			map(([, kontakte]) => kontakte.resource));
	}

	public getKontaktByUri(uri: string): Observable<KontaktResource> {
		return combineLatest([this.store.select(verfahrenSingleSelector), this.store.select(kontakteSelector)]).pipe(
			filter(([verfahren]) => !isNil(verfahren)),
			filter(([, kontakte]) => !doIfLoadingRequired(kontakte, () => this.store.dispatch(new LoadKontakteAction()))),
			map(([, kontakte]) => kontakte.resource.find(kontakte => kontakte._links.self.href === uri)),
		);
	}

	public getKontaktById(id: string): Observable<KontaktResource> {
		return combineLatest([this.store.select(verfahrenSingleSelector), this.store.select(kontakteSelector)]).pipe(
			filter(([verfahren]) => !isNil(verfahren)),
			filter(([, kontakte]) => !doIfLoadingRequired(kontakte, () => this.store.dispatch(new LoadKontakteAction()))),
			map(([, kontakte]) => kontakte.resource.find(kontakte => kontakte._links.self.href.endsWith('/' + id))),
		);
	}

	/**
	 * Gibt alle Kontakte zurück, die der Suchbedingung entsprechen.
	 * <strong>ACHTUNG!</strong> Vom Backend werden alle Kontakte geladen, obwohl dieses auch die Suchbedingung
	 * auswerten könnte. Das Frontend benötigt aber die Liste aller Kontakte, um folgende Operationen performant
	 * durchführen zu können:
	 * <ul>
	 *     <li>Update der Ergebnisliste während der Eingabe der Suchbegriffe</li>
	 *     <li>Suche nach ähnlichen Kontakten bei Neuanlage eines Kontakts</li>
	 * </ul>
	 * Um größere Umbauten in Front- und Backend zu vermeiden, wird der gegenwärtige Zustand vorerst behalten. Dem
	 * Nutzer wird die Wartezeit verborgen, indem die Kontaktliste bereits mit der Verfahrensansicht geladen wird.
	 * <P>
	 * Der Wert allowedToCreate wird bis um Umbau aus irgendeinem Kontakt abgeleitet. Später ergibt sich das aus
	 * dem CreateLink des PagedModels, leider kommt dieses aktuell hier gar nicht an.
	 * @param searchCondition
	 */
	public getKontakte(searchCondition: string): Observable<KontaktResource[]> {
		return this.store.select(kontakteSelector).pipe(
			filter(kontakte => !doIfLoadingRequired(kontakte, () => this.store.dispatch(new LoadKontakteAction()))),
			map(kontakte => kontakte.resource),
			tap(kontakte =>
				this.allowedToCreateSubject.next(!kontakte || kontakte.length === 0 || hasLink(kontakte[0], KontaktLinkRel.EDIT))),
			map(kontakte => (!isEmpty(searchCondition) && !isNil(searchCondition))
				? kontakte.filter(kontakt => this.doesKontaktMatchSearchCondition(kontakt, searchCondition))
				: kontakte),
		);
	}

	private doesKontaktMatchSearchCondition(kontakt: KontaktResource, searchCondition: string): boolean {

		var searchString: string = `
			${kontakt.firmenName}
			${kontakt.vorname}
			${kontakt.weitereVornamen}
			${kontakt.name}
			${kontakt.geburtsname}
			${kontakt.strasse}
			${kontakt.hausnummer}
			${kontakt.plz}
			${kontakt.stadt}
			${kontakt.land}
			${kontakt.firmenName1}
			${kontakt.firmenName2}
			${kontakt.telefon}
			${kontakt.email}
			${kontakt.sitz}
			${kontakt.handelsregisterNummer}`;
		searchString = this.normalize(searchString);

		return this.normalize(searchCondition).split(' ').every( element => searchString.indexOf(element) >= 0);
	}

	normalize(input: string): string {
		return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase();

	}

	public createKontakt(kontakt: Kontakt): void {
		this.store.dispatch(new CreateKontaktAction(kontakt));
	}

	public updateKontakt(toUpdateKontakt: Kontakt): void {
		this.store.dispatch(new UpdateKontaktAction(toUpdateKontakt));
	}

	public assignKontakt(kategorie: KontaktKategorie): void {
		this.store.dispatch(new AssignKontaktAction(kategorie));
	}

	public setEditMode(editMode: boolean): void {
		this.store.dispatch(new ChangeKontaktEditModeAction(editMode));
	}

	public setSelectedKontakt(kontakt: KontaktResource): void {
		this.store.dispatch(new SetSelectedKontaktAction(kontakt));
	}

	public updateVerfahrenKontaktNotiz(kontakt: KontaktWithKategorie) {
		this.store.dispatch(new UpdateVerfahrenKontaktNotizAction(kontakt));
	}

	public updateVerfahrenKontaktAktenzeichen(kontakt: KontaktWithKategorie) {
		this.store.dispatch(new UpdateVerfahrenKontaktAktenzeichenAction(kontakt));
	}

	getAllVerfahrenForKontakt(selectedKontakt: KontaktResource): Observable<VerfahrenListResource> {
		return this.service.getAllVerfahrenForKontakt(selectedKontakt);
	}
}
