<h2 mat-dialog-title tabindex="0" class="narrow" data-test-id="create-verfuegung-dialog-title">Verfügung erstellen mit
	folgenden Aufgaben</h2>

<form [formGroup]="form">
	<mat-dialog-content>
		<div class="wizard">
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-registerakte-checkbox"
			              [formControl]="formService.controlAufgabeRegisterakte">
				Registerakte anlegen
			</mat-checkbox>
			<schir-int-client-aufgabe-with-static-taetigkeit-editor data-test-id="verfuegung-registerakte"
			                                                        [aufgabenTyp]="aufgabenTypen.LFPR_REGISTERAKTE_ANLEGEN">
			</schir-int-client-aufgabe-with-static-taetigkeit-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-schuldtitel-checkbox"
			              [formControl]="formService.controlSchuldtitel">
				Schuldtitel
			</mat-checkbox>
			<schir-int-client-aufgabe-schuldtitel-editor data-test-id="verfuegung-schuldtitel">
			</schir-int-client-aufgabe-schuldtitel-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-mitteilung-checkbox"
			              [formControl]="formService.controlMitteilung">
				Eintragungsmitteilung fertigen
			</mat-checkbox>
			<schir-int-client-aufgabe-lfpr-mitteilung-editor data-test-id="verfuegung-mitteilung">
			</schir-int-client-aufgabe-lfpr-mitteilung-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-statistik-checkbox"
			              [formControl]="formService.controlStatistik">
				Statistik
			</mat-checkbox>
			<schir-int-client-aufgabe-with-static-taetigkeit-editor data-test-id="verfuegung-statistik"
			                                                        [aufgabenTyp]="aufgabenTypen.LFPR_STATISTIK">
			</schir-int-client-aufgabe-with-static-taetigkeit-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark"
			              data-test-id="verfuegung-verweisungsvermerk-checkbox"
			              [formControl]="formService.controlVerweisungsvermerk">
				Verweisungsvermerk
			</mat-checkbox>
			<schir-int-client-aufgabe-with-static-taetigkeit-editor data-test-id="verfuegung-verweisungsvermerk"
			                                                        [aufgabenTyp]="aufgabenTypen.LFPR_VERWEISUNGSVERMERK">
			</schir-int-client-aufgabe-with-static-taetigkeit-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-db-aktualisieren-checkbox"
			              [formControl]="formService.controlDBAktualisieren">
				Datenbank aktualisieren
			</mat-checkbox>
			<schir-int-client-aufgabe-with-static-taetigkeit-editor data-test-id="verfuegung-db-aktualisieren"
			                                                        [aufgabenTyp]="aufgabenTypen.LFPR_DB_AKTUALISIEREN">
			</schir-int-client-aufgabe-with-static-taetigkeit-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-blattabschrift-checkbox"
			              [formControl]="formService.controlBlattabschrift">
				Blattabschrift
			</mat-checkbox>
			<schir-int-client-aufgabe-lfpr-blattabschrift-editor data-test-id="verfuegung-blattabschrift">
			</schir-int-client-aufgabe-lfpr-blattabschrift-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark"
			              data-test-id="verfuegung-negativbescheinigung-checkbox"
			              [formControl]="formService.controlNegativbescheinigung">
				Negativbescheinigung erteilen
			</mat-checkbox>
			<schir-int-client-aufgabe-with-static-taetigkeit-editor data-test-id="verfuegung-negativbescheinigung"
			                                                        [aufgabenTyp]="aufgabenTypen.LFPR_NEGATIVBESCHEINIGUNG">
			</schir-int-client-aufgabe-with-static-taetigkeit-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-kosten-checkbox"
			              [formControl]="formService.controlKosten">
				Kosten
			</mat-checkbox>
			<schir-int-client-aufgabe-lfpr-kosten-editor data-test-id="verfuegung-kosten">
			</schir-int-client-aufgabe-lfpr-kosten-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-wiedervorlage-checkbox"
			              [formControl]="formService.controlWiedervorlage">
				Wiedervorlage
			</mat-checkbox>
			<schir-int-client-aufgabe-wiedervorlage-editor [aufgabenTyp]="aufgabenTypen.LFPR_WIEDERVORLAGE"
			                                               data-test-id="aufgabe-wiedervorlage">
			</schir-int-client-aufgabe-wiedervorlage-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-erledigt-checkbox"
			              [formControl]="formService.controlErledigt">
				Fall erledigt
			</mat-checkbox>
			<schir-int-client-aufgabe-with-static-taetigkeit-editor data-test-id="verfuegung-erledigt"
			                                                        [aufgabenTyp]="aufgabenTypen.LFPR_ERLEDIGT">
			</schir-int-client-aufgabe-with-static-taetigkeit-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-weglegen-checkbox"
			              [formControl]="formService.controlWeglegen">
				Akte weglegen
			</mat-checkbox>
			<schir-int-client-aufgabe-lfpr-weglegen-editor data-test-id="verfuegung-weglegen"
			                                               [aufgabenTyp]="aufgabenTypen.LFPR_WEGLEGEN">
			</schir-int-client-aufgabe-lfpr-weglegen-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-sammlung-checkbox"
			              [formControl]="formService.controlSammlung">
				Zur Sammlung
			</mat-checkbox>
			<schir-int-client-aufgabe-lfpr-weglegen-editor data-test-id="verfuegung-sammlung"
			                                               [aufgabenTyp]="aufgabenTypen.LFPR_SAMMLUNG">
			</schir-int-client-aufgabe-lfpr-weglegen-editor>

			<mat-form-field appearance="outline">
				<mat-label>Notiz zur Verfügung</mat-label>
				<textarea matInput data-test-id="verfuegungs-notiz-input"
				          [formControl]="formService.controlVerfuegungNotiz"></textarea>
			</mat-form-field>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions>
		<button mat-stroked-button (click)="onCancel()" type="button" class="focus-inwards-dark"
		        data-test-id="abbrechen-button">
			Abbrechen
		</button>
		<button mat-flat-button color="primary" type="button" (click)="onSave()" [disabled]="!isValid()"
		        class="icon-right focus-inwards-dark" data-test-id="speichern-button">
			Speichern
		</button>
	</mat-dialog-actions>
</form>
