<div *ngIf="verfahren | async as _verfahren" data-test-id="kontakte-in-verfahren" class="x-section x-section--accent">
	<div class="x-section__headline">
		<h2 class="mat-h1" tabindex="0">Kontakte</h2>
	</div>

	<div class="x-section__subline">
		<i class="material-icons" aria-hidden="true">contacts</i>
		<h3 class="mat-body margin-right-auto" aria-hidden="true">Kontakte</h3>
		<schir-int-client-button-round data-test-id="button-open-adressverwaltung" toolTip="Kontakte bearbeiten"
		                               materialIcon="edit" (click)="openAdressverwaltung()" popup="dialog"
		                               cssClass="focus-inwards-dark schir-inline-button">
		</schir-int-client-button-round>
	</div>

	<schir-int-client-kontakt-list data-test-id="kontakt-list-root" class="x-section__main"
	                               [verfahren]="verfahren | async" [draggable]="true" [unassignable]="true"
	                               (unassignKontakt)="unassignKontakt($event)">
	</schir-int-client-kontakt-list>
</div>
