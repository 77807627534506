import {Action} from '@ngrx/store';
import {VorlageListResource} from './vorlage.model';
import {createEmptyStateResource, createStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {VorlageActions, VorlagenLoadedAction} from './vorlage.actions';

export interface VorlageState {
	vorlagen: StateResource<VorlageListResource>;
}

export const initialState: VorlageState = {
	vorlagen: createEmptyStateResource(),
};

export function vorlageReducer(state: VorlageState = initialState, action: Action): VorlageState {
	switch (action.type) {
		case VorlageActions.LOAD_VORLAGEN:
			return {
				...state,
				vorlagen: createEmptyStateResource(true),
			};
		case VorlageActions.LOAD_VORLAGEN_SUCCESS:
			return {
				...state,
				vorlagen: createStateResource((<VorlagenLoadedAction>action).payload),
			};
		default:
			return state;
	}
}
