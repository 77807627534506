<h2 mat-dialog-title data-test-id="dialog-title" tabindex="0" class="narrow">{{ title }}</h2>
<mat-dialog-content>

	<schir-int-client-one-value-editor cdkTextareaAutosize data-test-id="kontakt-aktenzeichen-dialog-input"
	                                   [feld]="kontaktAktenzeichenFeld" [formGroup]="form">
	</schir-int-client-one-value-editor>

</mat-dialog-content>
<mat-dialog-actions>
	<button mat-stroked-button (click)="onCancel()" data-test-id="cancel-button" class="focus-inwards-dark">Abbrechen
	</button>
	<button mat-flat-button (click)="onSave()" [disabled]="!isValid()" color="primary"
	        class="icon-right focus-inwards-dark"
	        data-test-id="update-kontakt-aktenzeichen-button">Speichern
	</button>
</mat-dialog-actions>
