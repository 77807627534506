import {registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {LOCALE_ID, NgModule} from '@angular/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {AdressverwaltungModule} from '@schir-int-client/adressverwaltung';
import {ApiRootModule} from '@schir-int-client/api-root';
import {BinarySharedModule} from '@schir-int-client/binary-shared';
import {DashboardModule, DashboardPageComponent} from '@schir-int-client/dashboard';
import {EipSharedModule} from '@schir-int-client/eip-shared';
import {EnvironmentModule} from '@schir-int-client/environment';
import {KorrespondenzSharedModule} from '@schir-int-client/korrespondenz-shared';
import {NavbarModule} from '@schir-int-client/navbar';
import {PosteingangModule} from '@schir-int-client/posteingang';
import {SignaturSharedModule} from '@schir-int-client/signatur-shared';
import {UserSharedModule} from '@schir-int-client/user-shared';
import {UsignalModule} from '@schir-int-client/usignal-shared';
import {VerfahrenModule} from '@schir-int-client/verfahren';
import {VerfuegungModule} from '@schir-int-client/verfuegung';
import {VorgangModule} from '@schir-int-client/vorgang';
import {VorlageModule} from '@schir-int-client/vorlage';
import {EnumToLabelPipe, FormatDatePipe} from '@schir-int-client/tech';
import {ToastrModule} from 'ngx-toastr';
import {AppComponent} from './app.component';
import {SchirStoreModule} from './shared/store/store.module';
import {ErrorModule} from '@schir-int-client/error';
import {StatistikModule} from '@schir-int-client/statistik';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {TextbausteinModule} from '@schir-int-client/textbaustein';
import {WhitelabelErrorPageComponent} from '@schir-int-client/error';

registerLocaleData(localeDe, 'de', localeDeExtra);

const routes: Routes = [
	{
		path: '',
		component: DashboardPageComponent,
	},
	{
		path: 'error',
		component: WhitelabelErrorPageComponent,
	},
	{
		path: '**',
		redirectTo: '/error',
	}, // wildcard route to catch all undefined routes
];

@NgModule({
	declarations: [AppComponent],
	imports: [
		EnvironmentModule,
		SchirStoreModule,
		BrowserModule,
		BrowserAnimationsModule,
		NgbModule,
		RouterModule.forRoot(routes, {}),
		StoreRouterConnectingModule.forRoot(),
		ToastrModule.forRoot(),
		MatMomentDateModule,
		HttpClientModule,
		MatIconModule,
		ApiRootModule,
		NavbarModule,
		VorgangModule,
		DashboardModule,
		PosteingangModule,
		VerfahrenModule,
		VorlageModule,
		VerfuegungModule,
		AdressverwaltungModule,
		EipSharedModule,
		KorrespondenzSharedModule,
		UserSharedModule,
		BinarySharedModule,
		UsignalModule,
		SignaturSharedModule,
		ErrorModule,
		StatistikModule,
		MatFormFieldModule,
		MatInputModule,
		TextbausteinModule,
	],
	exports: [RouterModule],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: 'Window',
			useValue: window,
		},
		EnumToLabelPipe,
		FormatDatePipe,
		{
			provide: LOCALE_ID,
			useValue: 'de',
		},
		{
			provide: MAT_DATE_LOCALE,
			useValue: 'de-DE',
		},
		{
			provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
			useValue: { useUtc: true },
		},
	],
})
export class AppModule {}
