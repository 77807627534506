import {Component, Inject} from '@angular/core';
import {DialogDataYesNo} from '@schir-int-client/dialog-shared';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'schir-int-client-dialog-yes-no',
	templateUrl: './dialog-yes-no.component.html',
	styleUrls: ['./dialog-yes-no.component.scss'],
})
export class DialogYesNoComponent {

	constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataYesNo) { }

	agree(agree: boolean, keyboard: boolean) {
		this.data.agree = agree;
		this.data.keyboard = keyboard;
	}
}
