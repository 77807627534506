<div class="x-section__headline">
	<h2 class="mat-h1 margin-right-auto" tabindex="0">Vorgänge zum Posteingang</h2>
</div>
<div class="x-section__subline">
	<i class="material-icons">file_copy</i>
	<h3 class="mat-body margin-right-auto" tabindex="0">Vorgänge</h3>
</div>
<div class="x-section__main" data-test-id="zugeordnete-vorgaenge-root">
	<div class="c-spinner" data-test-id="spinner" #spinner>
		<mat-spinner></mat-spinner>
	</div>
	<ul class="c-list" *ngIf="zugeordneteVorgaenge | async as _zugeordneteVorgaenge">
		<li class="c-list__item focus-inwards-dark" *ngFor="let vorgang of _zugeordneteVorgaenge"
		    data-test-id="eintrag">
			<a class="c-list__link" data-test-id="vorgang" (click)="goToVorgang(vorgang)"
			   (keyup.enter)="goToVorgang(vorgang)" tabindex="0">
				<div class="c-row c-row--header">
					<span class="c-row__item c-row__item--header" data-test-id="vorgang-typ">
						{{ vorgang.typ | enumToLabel: vorgangsTypen }}
					</span>
					<span class="c-row__item c-row__item--secondary" data-test-id="vorgang-status">
						{{ vorgang.status | enumToLabel: vorgangStatusLabel }}
					</span>
				</div>
				<div class="c-row">
					<span class="c-row__item">
						<schir-int-client-aktenzeichen [vorgang]="vorgang"
						                               data-test-id="vorgang-aktenzeichen"></schir-int-client-aktenzeichen>
					</span>
					<span class="c-row__item" data-test-id="vorgang-datum">
						{{ vorgang.anlageDatum | formatDate }}
					</span>
				</div>

				<div class="c-row c-row--secondary">
					<span class="c-row__item">{{ vorgang.notiz }}</span>
				</div>
			</a>
			<div class="c-button-bar c-button-bar--overlay">
				<schir-int-client-button-round (click)="openAssignVorgangDialog(vorgang)"
				                               data-test-id="assign-vorgang-button"
				                               toolTip="Vorgang an Rechtspflege versenden" materialIcon="send"
				                               cssClass="focus-inwards-dark">
				</schir-int-client-button-round>
			</div>
		</li>
	</ul>
</div>
