import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {RouterModule, Routes} from '@angular/router';
import {AdressatenInAufgabeContainerComponent, AdressverwaltungModule} from '@schir-int-client/adressverwaltung';
import {TechModule} from '@schir-int-client/tech';
import {VerfuegungFacade} from '@schir-int-client/verfuegung-shared';
import {VorgangFacade, VorgangSharedModule} from '@schir-int-client/vorgang-shared';
import {WiedervorlageModule} from '@schir-int-client/wiedervorlage';
import {AufgabenInVerfuegungContainerComponent} from '@schir-int-client/aufgabe';
import {VerfuegungenInVorgangContainerComponent} from '@schir-int-client/verfuegung';
import {CreateVorgangButtonComponent} from './create-vorgang-button/create-vorgang-button.component';
import {CreateVorgangDialogComponent} from './create-vorgang-button/create-vorgang-dialog/create-vorgang-dialog.component';
import {VorgaengeInVerfahrenContainerComponent} from './vorgaenge-in-verfahren-container/vorgaenge-in-verfahren-container.component';
import {ChangeVorgangStatusDialogComponent} from './vorgaenge-in-verfahren/change-vorgang-status-dialog/change-vorgang-status-dialog.component';
import {VorgaengeInVerfahrenComponent} from './vorgaenge-in-verfahren/vorgaenge-in-verfahren.component';
import {PosteingangSelectorComponent} from './vorgaenge-in-verfahren/vorgang-in-verfahren/posteingang-selector/posteingang-selector.component';
import {VorgangInVerfahrenComponent} from './vorgaenge-in-verfahren/vorgang-in-verfahren/vorgang-in-verfahren.component';
import {VorgaengeZumVerfahrenImPosteingangComponent} from './vorgaenge-zum-verfahren-im-posteingang/vorgaenge-zum-verfahren-im-posteingang.component';
import {AssignVorgangDialogComponent} from './zugeordnete-vorgaenge/assign-vorgang-dialog/assign-vorgang-dialog.component';
import {ZugeordneteVorgaengeComponent} from './zugeordnete-vorgaenge/zugeordnete-vorgaenge.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {ButtonWithMenuComponent} from './vorgaenge-in-verfahren/button-with-menu/button-with-menu.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
import {AuthGuard} from '../../../guards/src';
import {AktenzeichenModule} from '@schir-int-client/aktenzeichen';
import {StandortAkteComponent} from './vorgaenge-in-verfahren/standort-akte/standort-akte.component';
import {ZaehlblattErfassenComponent} from './vorgaenge-in-verfahren/zaehlblatt/zaehlblatt-erfassen.component';
import {ZaehlblattAnzeigenComponent} from './vorgaenge-in-verfahren/zaehlblatt/zaehlblatt-anzeigen.component';
import {KennzeichenComponent} from './kennzeichen/kennzeichen.component';

const routes: Routes = [
	{
		path: 'verfahren/:verfahrenId',
		component: VorgaengeInVerfahrenContainerComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			{
				path: ':vorgangId',
				component: VerfuegungenInVorgangContainerComponent,
				outlet: 'vorgang',
			},
			{
				path: ':verfuegungId',
				component: AufgabenInVerfuegungContainerComponent,
				outlet: 'verfuegung',
			},
			{
				path: ':aufgabeId',
				component: AdressatenInAufgabeContainerComponent,
				outlet: 'aufgabe',
			},
		],
	},
];

@NgModule({
	imports: [
		CommonModule,
		VorgangSharedModule,
		MatTableModule,
		MatButtonModule,
		MatOptionModule,
		MatSelectModule,
		MatDialogModule,
		MatIconModule,
		MatMenuModule,
		MatTooltipModule,
		TechModule,
		MatDatepickerModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatProgressSpinnerModule,
		RouterModule.forChild(routes),
		WiedervorlageModule,
		AdressverwaltungModule,
		MatRadioModule,
		FormsModule,
		AktenzeichenModule,
	],
	declarations: [
		ZugeordneteVorgaengeComponent,
		VorgaengeInVerfahrenComponent,
		VorgangInVerfahrenComponent,
		CreateVorgangDialogComponent,
		VorgaengeZumVerfahrenImPosteingangComponent,
		AssignVorgangDialogComponent,
		VorgaengeInVerfahrenContainerComponent,
		PosteingangSelectorComponent,
		ChangeVorgangStatusDialogComponent,
		CreateVorgangButtonComponent,
		ButtonWithMenuComponent,
		StandortAkteComponent,
		KennzeichenComponent,
		ZaehlblattErfassenComponent,
		ZaehlblattAnzeigenComponent,
	],
	exports: [
		ZugeordneteVorgaengeComponent,
		VorgaengeInVerfahrenComponent,
		VorgangInVerfahrenComponent,
		VorgaengeZumVerfahrenImPosteingangComponent,
		CreateVorgangButtonComponent,
	],
	providers: [
		VorgangFacade,
		VerfuegungFacade,
	],
})
export class VorgangModule {}
