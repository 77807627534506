<div class="x-section__headline">
	<h2 class="mat-h1">Zählblattstatistik</h2>
</div>
<div class="x-section__subline">
	<div data-test-id="statistik-header" class="mat-body">Letzte 12 Monate</div>
	<schir-int-client-button-round data-test-id="export-zaehlblatt-button" (click)="exportZaehlblatt()"
	                               materialIcon="description"
	                               toolTip="Zählblatt in die Zwischenablage kopieren"
	                               cssClass="focus-inwards-dark schir-inline-button">
	</schir-int-client-button-round>
</div>
<table data-test-id="statistik-table" mat-table [dataSource]="dataSource"
       aria-label="Anzahl der Zählblattaktionen nach Typ und Monat">

	<ng-container matColumnDef="Typ">
		<th mat-header-cell *matHeaderCellDef>Typ</th>
		<td mat-cell *matCellDef="let element" [matTooltip]="element.typLang">{{ element.Typ }}</td>
	</ng-container>

	<ng-container *ngFor="let header of cols" matColumnDef="{{header}}">
		<th mat-header-cell *matHeaderCellDef><span class="month-content"
		                                            [ngClass]="header==='∑' ? 'sum' : ''">{{ header }}</span></th>
		<td mat-cell *matCellDef="let element" class="number-cell"> {{ element[header] }}</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="helper"></tr>
</table>
