<h2 mat-dialog-title data-test-id="dialog-title" tabindex="0" class="narrow">Zählblatt zu
	Verfahren {{ aktenzeichenRendered }}</h2>
<mat-dialog-content>
	<div *ngFor="let entry of entryList" class="entry-line" tabindex="0" data-test-id="zahlblatt-entry">
		<div data-test-id="entry-type">{{ label[entry.type] }}</div>
		<div>
			<span data-test-id="entry-moment">{{ format(entry.createdAt) }}</span>
			<schir-int-client-button-round (click)="deleteEntry(entry)" data-test-id="delete-button"
			                               cssClass="focus-inwards-dark" materialIcon="delete" toolTip="Aktion löschen">
			</schir-int-client-button-round>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-stroked-button (click)="onCancel()" data-test-id="cancel-button" class="focus-inwards-dark">Schließen
	</button>
</mat-dialog-actions>
