import {NgModule} from '@angular/core';
import {ErrorPageComponent} from './error-page/error-page.component';
import {CommonModule} from '@angular/common';
import {WhitelabelErrorPageComponent} from './whitelabel-error-page/whitelabel-error-page.component';

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [
		ErrorPageComponent,
		WhitelabelErrorPageComponent
	],
	declarations: [ErrorPageComponent, WhitelabelErrorPageComponent],
})
export class ErrorModule {}
