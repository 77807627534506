import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getUrl, hasLink, Resource, ResourceUri} from '@ngxp/rest';
import {doIfLoadingRequired, StateResource} from '@schir-int-client/ngrx-helpers';
import {
	assignedPosteingaengeSelector,
	LoadAssigendPosteingaengeAction,
	PosteingangResource,
} from '@schir-int-client/posteingang-shared';
import {VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {isNull, isUndefined} from 'lodash-es';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, share, tap} from 'rxjs/operators';
import {
	AskToAssignVorgangToPosteingangAction,
	AskToCreateVorgangInVerfahrenAction,
	AssignVorgangAction,
	CreateVorgangInVerfahrenAction,
	LoadFristenListeAction,
	LoadVorgaengeByVerfahrenAction,
	LoadVorgaengeInRuecklaufAction,
	LoadVorgaengeInWiedervorlageAction,
	LoadVorgaengeRechtspflegeAction,
	LoadVorgangAction,
	LoadVorgangByLinkAction,
	SelectVorgangAction,
	SelectVorgangByUriAction,
	UpdateDatumsBereichAction,
	UpdateVorgangNotizAction,
	VorgangDeleteAction,
	VorgangMarkAsAbgeschlossenAction,
	VorgangMarkAsRechtspflegeAction,
	VorgangMarkAsRuecklaufAction,
	VorgangMarkAsWiedervorlageAction,
} from './vorgang.actions';
import {VorgangLinkRel} from './vorgang.linkrel';
import {DatumsBereich, VorgangResource, VorgangStatus, VorgangTyp} from './vorgang.model';
import {VorgangState} from './vorgang.reducer';
import {
	datumsBereichSelector,
	isVorgangSelectedSelector,
	selectedVorgangAndVorgangUriSelector,
	vorgaengeByVerfahrenSelector,
	vorgaengeInRuecklaufSelector,
	vorgaengeInWiedervorlageSelector,
	vorgaengeRechtspflegerSelector,
	vorgangByUriSelector,
	vorgangSelector,
	vorgangStateSelector,
	zugeordneterVorgangExistsSelector,
	zugeordneteVorgaengeSelector,
} from './vorgang.selectors';
import {Moment} from 'moment';

@Injectable()
export class VorgangFacade {
	private emptyArray = [];

	vorgangRootState = this.store.select(vorgangStateSelector);

	vorgang: Observable<VorgangResource> = this.store.select(vorgangSelector).pipe(
		map(stateResource => stateResource?.resource),
	);

	zugeordneterVorgangExists: Observable<boolean> = this.store.select(zugeordneterVorgangExistsSelector);

	vorgaengeRechtspfleger: Observable<VorgangResource[]> = this.store.select(vorgaengeRechtspflegerSelector)
		.pipe(
			tap(vorgaengeRechtspfleger => {
				if (isNull(vorgaengeRechtspfleger)) {
					this.store.dispatch(new LoadVorgaengeRechtspflegeAction());
				}
			}),
			share(),
			map(vorgaengeRechtspfleger => {
				return vorgaengeRechtspfleger ? vorgaengeRechtspfleger : this.emptyArray;
			}),
		);
	vorgaengeInRuecklauf: Observable<VorgangResource[]> = this.store.select(vorgaengeInRuecklaufSelector)
		.pipe(
			tap(vorgaenge => {
				if (isNull(vorgaenge)) {
					this.store.dispatch(new LoadVorgaengeInRuecklaufAction());
				}
			}),
			share(),
			map(vorgaenge => {
				return vorgaenge ? vorgaenge : this.emptyArray;
			}),
		);
	zugeordneteVorgaenge: Observable<VorgangResource[]> = this.store.select(zugeordneteVorgaengeSelector)
		.pipe(
			map(zugeordneteVorgaenge => {
				return zugeordneteVorgaenge ? zugeordneteVorgaenge : this.emptyArray;
			}),
			share(),
		);

	constructor(private store: Store<VorgangState>) { }

	getVorgaengeByVerfahren(verfahren: VerfahrenResource): Observable<VorgangResource[]> {
		return this.store.select(vorgaengeByVerfahrenSelector, { verfahrenUri: getUrl(verfahren) }).pipe(
			map(vorgaenge => {
				if (isUndefined(vorgaenge)) {
					this.store.dispatch(new LoadVorgaengeByVerfahrenAction(verfahren));
					return [];
				} else {
					return vorgaenge;
				}
			}),
		);
	}

	setSelectedVorgangUri(vorgangUri: ResourceUri) {
		this.store.dispatch(new SelectVorgangByUriAction(vorgangUri));
	}

	getSelectedVorgang(): Observable<VorgangResource> {
		return this.store.select(selectedVorgangAndVorgangUriSelector).pipe(
			filter(stateResource => !doIfLoadingRequired(stateResource, () => this.store.dispatch(new LoadVorgangAction()))),
			map(stateResource => stateResource.resource),
		);
	}

	getVorgangFromLink(resource: Resource, linkRel: string): Observable<StateResource<VorgangResource>> {
		return this.store.select(vorgangByUriSelector, { uri: getUrl(resource, linkRel) }).pipe(
			tap(vorgang => doIfLoadingRequired(vorgang, () => this.store.dispatch(new LoadVorgangByLinkAction(resource, linkRel)))),
		);
	}

	loadVorgaengeRechtspfleger() {
		this.store.dispatch(new LoadVorgaengeRechtspflegeAction());
	}

	loadVorgaengeInRuecklauf() {
		this.store.dispatch(new LoadVorgaengeInRuecklaufAction());
	}

	assignVorgang(vorgang: VorgangResource, rechtspfleger: string, status: VorgangStatus) {
		this.store.dispatch(new AssignVorgangAction(vorgang, rechtspfleger, status));
	}

	createVorgangInVerfahren(vorgangTyp: VorgangTyp, createVorgangUri: ResourceUri) {
		this.store.dispatch(new CreateVorgangInVerfahrenAction(vorgangTyp, createVorgangUri));
	}

	createVorgangWithPosteingangInVerfahren(vorgangType: VorgangTyp, createVorgangUri: ResourceUri) {
		this.store.dispatch(new AskToCreateVorgangInVerfahrenAction(vorgangType, createVorgangUri));
	}

	getVorgaengeInRuecklauf(): Observable<VorgangResource[]> {
		return this.store.select(vorgaengeInRuecklaufSelector).pipe(
			tap(vorgaenge => {
				if (isNull(vorgaenge)) {
					this.store.dispatch(new LoadVorgaengeInRuecklaufAction());
				}
			}),
		);
	}

	getVorgaengeInWiedervorlage(von: Moment, bis: Moment): Observable<StateResource<VorgangResource[]>> {
		return this.store.select(vorgaengeInWiedervorlageSelector).pipe(
			filter(vorgaenge => !doIfLoadingRequired(vorgaenge, () => this.store.dispatch(new LoadVorgaengeInWiedervorlageAction(von, bis)))),
		);
	}

	refreshWiedervorlagen(von: Moment, bis: Moment) {
		this.store.dispatch(new LoadVorgaengeInWiedervorlageAction(von, bis));
		this.store.dispatch(new UpdateDatumsBereichAction(von, bis));
	}

	getDatumsBereich(): Observable<DatumsBereich> {
		return this.store.select(datumsBereichSelector);
	}

	loadFristenListe() {
		this.store.dispatch(new LoadFristenListeAction());
	}

	isSelectedVorgang(vorgangResource: VorgangResource): Observable<boolean> {
		return this.store.select(isVorgangSelectedSelector, { vorgang: vorgangResource });
	}

	setSelectedVorgang(vorgangResource: VorgangResource) {
		this.store.dispatch(new SelectVorgangAction(vorgangResource));
	}

	askToAssignVorgangToPosteingang(vorgangResource: VorgangResource) {
		this.store.dispatch(new AskToAssignVorgangToPosteingangAction(vorgangResource));
	}

	updateNotiz(notiz: string, vorgang: VorgangResource) {
		this.store.dispatch(new UpdateVorgangNotizAction(notiz, vorgang));
	}

	loadPosteingaenge(vorgang: VorgangResource): Observable<StateResource<PosteingangResource[]>> {
		return this.store.select(assignedPosteingaengeSelector, { assignedTo: getUrl(vorgang) }).pipe(
			tap(stateResource => {
				if (!stateResource.loaded && !stateResource.loading) {
					this.store.dispatch(new LoadAssigendPosteingaengeAction(vorgang, VorgangLinkRel.POSTEINGAENGE));
				}
			}),
		);
	}

	markAsRechtspflege(vorgang: VorgangResource) {
		this.store.dispatch(new VorgangMarkAsRechtspflegeAction(vorgang));
	}

	markAsRuecklauf(vorgang: VorgangResource) {
		this.store.dispatch(new VorgangMarkAsRuecklaufAction(vorgang));
	}

	markAsAbgeschlossen(vorgang: VorgangResource) {
		this.store.dispatch(new VorgangMarkAsAbgeschlossenAction(vorgang));
	}

	markAsWiedervorlage(vorgang: VorgangResource, wiedervorlageDatum: Moment) {
		this.store.dispatch(new VorgangMarkAsWiedervorlageAction(vorgang, wiedervorlageDatum));
	}

	deleteVorgang(vorgang: VorgangResource) {
		this.store.dispatch(new VorgangDeleteAction(vorgang));
	}

	/**
	 * Dient der Ausblendung des Vorgang-Löschen-Buttons, ohne den Vorgang und damit alle abhängigen Elemente neu laden zu müssen.
	 * Neuladen des aktuellen Vorgangs würde ein Flackern der Seite bewirken.
	 * Wird ein Vorgang neu geladen, ist der deletable-Status bereits vom Server bestimmt. Zusätzlich kann ein Vorgang nachträglich z.B.
	 * durch Anlegen einer Verfügung unlöschbar werden. Daher ist die hier verwendete Info nur bis zum nächsten Laden der Vorgänge
	 * relevant.
	 *
	 * TODO: prüfen, ob es nicht reicht, wenn der Vorgang über ein Observable weiß, ob es zu ihm Verfuegungen im Store gibt?
	 * @param vorgang
	 */
	private undeletablesSubject: BehaviorSubject<Set<String>> = new BehaviorSubject<Set<String>>(new Set<String>());
	undeletables$: Observable<Set<String>> = this.undeletablesSubject.asObservable();


	isDeletable(vorgang: VorgangResource): Observable<boolean> {
		return this.undeletables$.pipe(
			map(undeleteables => hasLink(vorgang, VorgangLinkRel.DELETE) && !undeleteables.has(vorgang._links['self'].href)));
	}

	registerUndeletable(vorgang: VorgangResource): void {
		const currentSet = this.undeletablesSubject.getValue();
		currentSet.add(vorgang._links['self'].href);
		this.undeletablesSubject.next(currentSet);
	}

	registerDeletable(vorgangId: string): void {
		const currentSet = this.undeletablesSubject.getValue();
		const entryToDelete = Array.from(currentSet).find(entry => entry.endsWith('/' + vorgangId));
		if (entryToDelete) {
			currentSet.delete(entryToDelete);
			this.undeletablesSubject.next(currentSet);
		}
	}
}
