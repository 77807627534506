import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {getEmbeddedResource, getUrl} from '@ngxp/rest';
import {ApiRootFacade, apiRootSelector} from '@schir-int-client/api-root';
import {DoNothingAction} from '@schir-int-client/ngrx-helpers';
import {PosteingangActions, PosteingangFacade} from '@schir-int-client/posteingang-shared';
import {AppNotificationService, ClipboardService, DialogService, EnumToLabelPipe} from '@schir-int-client/tech';
import {VerfahrenActions, VerfahrenFacade} from '@schir-int-client/verfahren-shared';
import {map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
	AskToAssignVorgangToPosteingangAction,
	AskToCreateVorgangInVerfahrenAction,
	AssignVorgangAction,
	AssignVorgangToPosteingangAction,
	AssignVorgangToPosteingangSuccess,
	CreateVorgangInVerfahrenAction,
	CreateVorgangInVerfahrenSuccessAction,
	CreateVorgangWithPosteingangInVerfahrenAction,
	CreateVorgangWithPosteingangInVerfahrenSuccessAction,
	LoadFristenListeSuccessAction,
	LoadVorgaengeByVerfahrenSuccessAction,
	LoadVorgaengeInWiedervorlageAction,
	LoadVorgaengeInWiedervorlageSuccessAction,
	LoadVorgangByLinkAction,
	LoadZugeordneteVorgaengeAction,
	UpdateVorgangNotizAction,
	UpdateVorgangNotizSuccessAction,
	VorgaengeInRuecklaufLoadedAction,
	VorgaengeRechtspflegeLoadedAction,
	VorgangActions,
	VorgangDeleteAction,
	VorgangDeleteSuccessAction,
	VorgangLoadedAction,
	VorgangMarkAsAbgeschlossenAction,
	VorgangMarkAsRechtspflegeAction,
	VorgangMarkAsRuecklaufAction,
	VorgangMarkAsWiedervorlageAction,
	VorgangMarkedSuccessAction,
	ZugeordneteVorgaengeLoadedAction,
} from './vorgang.actions';
import {VorgangLinkRel, VorgangListLinkRel} from './vorgang.linkrel';
import {VorgangMessages, VorgangTypLabelLFPR, VorgangTypLabelSchiff} from './vorgang.messages';
import {VorgangListResource, VorgangResource} from './vorgang.model';
import {selectedVorgangUriSelector, vorgangSelector} from './vorgang.selectors';
import {VorgangService} from './vorgang.service';
import {AppState} from '../../../../apps/int-client/src/app/shared/store/app-state.model';

@Injectable()
export class VorgangEffects {
	constructor(
		private actions: Actions,
		private vorgangService: VorgangService,
		private store: Store<AppState>,
		private posteingangFacade: PosteingangFacade,
		private verfahrenFacade: VerfahrenFacade,
		private notificationService: AppNotificationService,
		private dialogService: DialogService,
		private enumToLabel: EnumToLabelPipe,
		private apiRootFacade: ApiRootFacade,
		private clipboardService: ClipboardService,
	) { }


	asktoCreateVorgangInVerfahren = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.ASK_TO_CREATE_VORGANG_IN_VERFAHREN),
		switchMap(action => {
			let vgType = (<AskToCreateVorgangInVerfahrenAction>action).vorgangType;
			const createVorgangUri = (<AskToCreateVorgangInVerfahrenAction>action).createVorgangUri;
			return this.dialogService.openConfirmationDialog(VorgangMessages.CONFIRM_CREATE_VORGANG, [this.enumToLabel.transform(vgType, { ...VorgangTypLabelSchiff, ...VorgangTypLabelLFPR })], ['Ja', 'Nein'])
				.pipe(
					map(confirmed => confirmed ? new CreateVorgangWithPosteingangInVerfahrenAction(vgType, createVorgangUri) : new DoNothingAction()),
				);
		}),
	));


	createVorgangWithPosteingangInVerfahren = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.CREATE_VORGANG_WITH_POSTEINGANG_IN_VERFAHREN),
		withLatestFrom(this.posteingangFacade.posteingang),
		switchMap(([action, posteingang]) => {
			const vorgangTyp = (<CreateVorgangWithPosteingangInVerfahrenAction>action).vorgangType;
			const createVorgangUri = (<CreateVorgangWithPosteingangInVerfahrenAction>action).createVorgangUri;
			return this.vorgangService.createVorgangInVerfahren(createVorgangUri, [getUrl(posteingang)], vorgangTyp).pipe(
				map(vorgaenge => new CreateVorgangWithPosteingangInVerfahrenSuccessAction(vorgaenge)),
			);
		}),
	));


	createVorgangInVerfahren = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.CREATE_VORGANG_IN_VERFAHREN),
		withLatestFrom(this.verfahrenFacade.verfahrenSingle),
		switchMap(([action, verfahren]) => {
			const vorgangTyp = (<CreateVorgangInVerfahrenAction>action).vorgangType;
			const createVorgangUri = (<CreateVorgangInVerfahrenAction>action).createVorgangUri;
			return this.vorgangService.createVorgangInVerfahren(createVorgangUri, [], vorgangTyp).pipe(
				map(vorgaenge => new CreateVorgangInVerfahrenSuccessAction(vorgaenge, verfahren)),
			);
		}),
	));


	vorgangCreated = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.CREATE_VORGANG_WITH_POSTEINGANG_IN_VERFAHREN_SUCCESS),
		tap(() => this.notificationService.handleInfo(VorgangMessages.VORGANG_CREATED)),
	), { dispatch: false });


	loadVorgaengeByVerfahren = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.LOAD_VERFAHREN_SINGLE_SUCCESS, VorgangActions.DELETE_SUCCESS),
		withLatestFrom(this.verfahrenFacade.verfahrenSingle),
		switchMap(([, verfahren]) => {
			return this.vorgangService.getVorgaengeByVerfahren(verfahren).pipe(
				map(vorgaenge => new LoadVorgaengeByVerfahrenSuccessAction(vorgaenge, verfahren)),
			);
		}),
	));


	loadVorgang = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.LOAD_VORGANG),
		withLatestFrom(this.store.select(selectedVorgangUriSelector)),
		switchMap(([, uri]) => {
			return this.vorgangService.getOne(uri).pipe(
				map(vorgang => new VorgangLoadedAction(vorgang)),
			);
		}),
	));


	loadVorgangByLink = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.LOAD_VORGANG_BY_LINK),
		mergeMap((action: LoadVorgangByLinkAction) => {
			return this.vorgangService.getByLink(action.resource, action.linkRel).pipe(
				map(vorgang => new VorgangLoadedAction(vorgang)),
			);
		}),
	));


	loadZugeordneteVorgaenge = createEffect(() => this.actions.pipe(
		ofType(PosteingangActions.POSTEINGANG_LOADED, VerfahrenActions.VERFAHREN_CREATED, VorgangActions.LOAD_ZUGEORDNETE_VORGAENGE),
		withLatestFrom(this.posteingangFacade.posteingang),
		switchMap(([, posteingangResource]) => {
			return this.vorgangService.getZugeordneteVorgaenge(posteingangResource).pipe(
				map(zugeordneteVorgaengeList => new ZugeordneteVorgaengeLoadedAction(zugeordneteVorgaengeList)),
			);
		}),
	));


	loadVorgaengeRechtspflege = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.LOAD_VORGAENGE_RECHTSPFLEGE),
		withLatestFrom(this.store.select(apiRootSelector)),
		switchMap(([, apiRoot]) => {
			return this.vorgangService.getAllForRechtspflege(apiRoot).pipe(
				map(vorgaengeList => new VorgaengeRechtspflegeLoadedAction(getEmbeddedResource(vorgaengeList, VorgangListLinkRel.VORGANG_LIST))),
			);
		}),
	));


	assignVorgang = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.ASSIGN_VORGANG),
		switchMap(action => {
			return this.vorgangService.assignVorgang((<AssignVorgangAction>action).vorgang, (<AssignVorgangAction>action).rechtspfleger).pipe(
				map(() => new LoadZugeordneteVorgaengeAction()),
			);
		}),
	));


	loadVorgaengeInRuecklauf = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.LOAD_VORGAENGE_IN_RUECKLAUF),
		withLatestFrom(this.apiRootFacade.apiRoot),
		switchMap(([, apiRoot]) => {
			return this.vorgangService.loadVorgaengeInRuecklauf(apiRoot).pipe(
				map(vorgaenge => new VorgaengeInRuecklaufLoadedAction(vorgaenge)),
			);
		}),
	));


	asktoAssignVorgangToPosteingang = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.ASK_TO_ASSIGN_VORGANG_TO_POSTEINGANG),
		switchMap(action => {
			let vorgang = (<AskToAssignVorgangToPosteingangAction>action).vorgangResource;
			return this.dialogService.openConfirmationDialog(VorgangMessages.ASSIGN_TO_POSTEINGANG)
				.pipe(
					map(confirmed => confirmed ? new AssignVorgangToPosteingangAction(vorgang) : new DoNothingAction()),
				);
		}),
	));


	assignVorgangToPosteingang = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.ASSIGN_VORGANG_TO_POSTEINGANG),
		withLatestFrom(this.posteingangFacade.posteingang),
		switchMap(([action, posteingang]) => {
			const vorgang: VorgangResource = (<AssignVorgangToPosteingangAction>action).vorgangResource;
			return this.vorgangService.assignVorgangToPosteingang(posteingang, vorgang).pipe(
				mergeMap(() => [new AssignVorgangToPosteingangSuccess(), new LoadZugeordneteVorgaengeAction()]),
			);
		}),
	));


	displayAssignVorgangToPosteingangSuccessNotification = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.ASSIGN_VORGANG_TO_POSTEINGANG_SUCCESS),
		tap(() => {
			this.notificationService.handleInfo(VorgangMessages.ASSIGN_TO_POSTEINGANG_SUCCESS);
		}),
	), { dispatch: false });


	updateNotiz = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.UPDATE_VORGANG_NOTIZ),
		switchMap((action: UpdateVorgangNotizAction) => {
			return this.vorgangService.updateNotiz(action.notiz, action.vorgang).pipe(
				map(vorgang => new UpdateVorgangNotizSuccessAction(vorgang)),
			);
		}),
	));


	markAsRechtspflege = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.MARK_AS_RECHTSPFLEGE),
		switchMap(action => {
			return this.vorgangService.markAsRechtspflege((<VorgangMarkAsRechtspflegeAction>action).vorgang).pipe(
				map(vorgang => new VorgangMarkedSuccessAction(vorgang)),
			);
		}),
	));


	markAsRuecklauf = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.MARK_AS_RUECKLAUF),
		switchMap(action => {
			return this.vorgangService.markAsRuecklauf((<VorgangMarkAsRuecklaufAction>action).vorgang).pipe(
				map(vorgang => new VorgangMarkedSuccessAction(vorgang)),
			);
		}),
	));


	markAsAbgeschlossen = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.MARK_AS_ABGESCHLOSSEN),
		switchMap(action => {
			return this.vorgangService.markAsAbgeschlossen((<VorgangMarkAsAbgeschlossenAction>action).vorgang).pipe(
				map(vorgang => new VorgangMarkedSuccessAction(vorgang)),
			);
		}),
	));

	delete = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.DELETE),
		switchMap(action => {
			const vorgang = (<VorgangDeleteAction>action).vorgang;
			return this.vorgangService.delete(vorgang).pipe(
				map(() => new VorgangDeleteSuccessAction(vorgang._links.self.href)),
			);
		}),
	));

	markAsWiedervorlage = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.MARK_AS_WIEDERVORLAGE),
		withLatestFrom(this.store.select(vorgangSelector)),
		switchMap(([action, vorgang]) =>
			this.vorgangService.markAsWiedervorlage(vorgang.resource, (<VorgangMarkAsWiedervorlageAction>action).wiedervorlageDatum).pipe(
				mergeMap(vorgang => [
					new VorgangMarkedSuccessAction(vorgang),
					new LoadVorgangByLinkAction(vorgang, VorgangLinkRel.SELF)]),
			)),
	));

	loadVorgaengeInWiedervorlage = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.LOAD_VORGAENGE_IN_WIEDERVORLAGE),
		withLatestFrom(this.apiRootFacade.apiRoot),
		switchMap(([action, apiRoot]) => {
			return this.vorgangService.getAllInWiedervorlage(apiRoot, (<LoadVorgaengeInWiedervorlageAction>action).von, (<LoadVorgaengeInWiedervorlageAction>action).bis).pipe(
				map(vorgaenge => new LoadVorgaengeInWiedervorlageSuccessAction(vorgaenge)),
			);
		}),
	));

	loadFristenListe = createEffect(() => this.actions.pipe(
		ofType(VorgangActions.LOAD_FRISTENLISTE),
		withLatestFrom(this.apiRootFacade.apiRoot),
		switchMap(([, apiRoot]) => {
			return this.vorgangService.getFristenListe(apiRoot).pipe(
				tap(resource => this.copyFristenListeToClipboard(resource)),
				map(resource => new LoadFristenListeSuccessAction(resource)));
		}),
	));

	copyFristenListeToClipboard(value: VorgangListResource) {
		let text = '';
		const vorgaenge: VorgangResource[] = getEmbeddedResource(value, VorgangListLinkRel.VORGANG_LIST);

		if (vorgaenge && vorgaenge.length > 0) {
			vorgaenge.forEach(vorgang => {
				text = text.concat(vorgang.aktenzeichen + '\n');
			});

			this.clipboardService.copyAndShowSnackBar(text, 'Die Fristenliste wurde in die Zwischenablage kopiert.');
		}
	}
}
