<div class="x-section__main">
	<div class="documents-button-bar">
		<h3 class="mat-body" data-test-id="title-documents">Dokumente</h3>
		<div class="documents-button-bar__buttons" *ngIf="documents as _documents">
			<button class="c-button c-button--round" data-test-id="attachment-button"
				*ngFor="let document of _documents; let i = index;" (click)="show(i)" [attr.aria-labelledby]="'bt-'+i">
				<span id="bt-{{i}}">{{i + 1}}</span>
			</button>
		</div>
	</div>
</div>
<div class="x-section__main">
	<schir-int-client-pdf-viewer *ngIf="documents as _documents" [documents]="_documents"
		[selectedDocumentIndex]="selectedDocumentIndex"></schir-int-client-pdf-viewer>
</div>
