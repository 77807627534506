import {Action} from '@ngrx/store';
import {
	MonthlyStatistik,
	StatistikResource,
	VerfahrenAktionStatistik,
	ZaehlblattStatistikResource,
} from './statistik.model';
import {Moment} from 'moment';

export enum StatistikActions {
	LOAD_STATISTIK = 'LOAD_STATISTIK',
	STATISTIK_LOADED = 'STATISTIK_LOADED',
	LOAD_MONTHLY_STATISTIK = 'LOAD_MONTHLY_STATISTIK',
	MONTHLY_STATISTIK_LOADED = 'MONTHLY_STATISTIK_LOADED',
	LOAD_ZAEHLBLATT_STATISTIK = 'LOAD_ZAEHLBLATT_STATISTIK',
	ZAEHLBLATT_STATISTIK_LOADED = 'ZAEHLBLATT_STATISTIK_LOADED',
	LOAD_VERFAHREN_AKTION_STATISTIK = 'LOAD_VERFAHREN_AKTION_STATISTIK',
	LOAD_VERFAHREN_AKTION_STATISTIK_SUCCESS = 'LOAD_VERFAHREN_AKTION_STATISTIK_SUCCESS',
}

export class LoadStatistikAction implements Action {
	type = StatistikActions.LOAD_STATISTIK;
}

export class StatistikLoadedAction implements Action {
	type = StatistikActions.STATISTIK_LOADED;

	constructor(public payload: StatistikResource) {}
}

export class LoadMonthlyStatistikAction implements Action {
	type = StatistikActions.LOAD_MONTHLY_STATISTIK;
}

export class MonthlyStatistikLoadedAction implements Action {
	type = StatistikActions.MONTHLY_STATISTIK_LOADED;

	constructor(public payload: MonthlyStatistik) {}
}

export class LoadZaehlblattStatistikAction implements Action {
	type = StatistikActions.LOAD_ZAEHLBLATT_STATISTIK;
}

export class ZaehlblattStatistikLoadedAction implements Action {
	type = StatistikActions.ZAEHLBLATT_STATISTIK_LOADED;

	constructor(public payload: ZaehlblattStatistikResource) {}
}

export class LoadVerfahrenAktionStatistikAction implements Action {
	type = StatistikActions.LOAD_VERFAHREN_AKTION_STATISTIK;

	constructor(public von: Moment, public bis: Moment) {}

}

export class LoadVerfahrenAktionStatistikSuccessAction implements Action {
	type = StatistikActions.LOAD_VERFAHREN_AKTION_STATISTIK_SUCCESS;

	constructor(public statistik: VerfahrenAktionStatistik) {}
}
