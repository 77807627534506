import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AlternativeTextSharedModule} from '@schir-int-client/alternative-text-shared';
import {RegisterSharedModule} from '@schir-int-client/register-shared';
import {TechModule} from '@schir-int-client/tech';
import {AmtlichenAusdruckErstellenDialogComponent} from './amtlichen-ausdruck-erstellen-dialog/amtlichen-ausdruck-erstellen-dialog.component';
import {AmtlichenAusdruckErstellenEntryComponent} from './amtlichen-ausdruck-erstellen-dialog/amtlichen-ausdruck-erstellen-entry/amtlichen-ausdruck-erstellen-entry.component';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatInputModule,
		MatDialogModule,
		AlternativeTextSharedModule,
		RegisterSharedModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		TechModule,
	],
	declarations: [
		AmtlichenAusdruckErstellenDialogComponent,
		AmtlichenAusdruckErstellenEntryComponent,
	],
	exports: [
		AmtlichenAusdruckErstellenDialogComponent,
	],
})
export class AlternativeTextModule {}
