import {Component, OnDestroy, OnInit} from '@angular/core';
import {StatistikFacade, ZaehlblattStatistik} from '@schir-int-client/statistik-shared';
import {Subscription} from 'rxjs';
import {ClipboardService} from '@schir-int-client/tech';
import {MatTableDataSource} from '@angular/material/table';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {
	orderedZaehlblattAktionTypes,
	ZaehlblattAktionTyp,
	ZaehlblattAktionTypLabel,
	ZaehlblattAktionTypShortLabel,
} from '@schir-int-client/verfahren-shared';
import {isNil} from 'lodash-es';

@Component({
	selector: 'schir-int-client-zaehlblatt-statistik',
	templateUrl: './zaehlblatt-statistik.component.html',
	styleUrls: ['./zaehlblatt-statistik.component.scss'],
})
export class ZaehlblattStatistikComponent implements OnInit, OnDestroy {
	dataSource = new MatTableDataSource<any[]>();
	displayedColumns: string[] = [];
	cols: string[];

	readonly types: ZaehlblattAktionTyp[];

	private subscriptions: Subscription[] = [];

	constructor(private statistikFacade: StatistikFacade, private clipboardService: ClipboardService, apiRootFacade: ApiRootFacade) {
		const aktionen = Object.values(apiRootFacade.zaehlblattAktionTypenByRegister).flat();
		this.types = orderedZaehlblattAktionTypes.filter(aktion => aktionen.includes(aktion));
	}

	ngOnInit(): void {
		this.statistikFacade.loadZaehlblattStatistik();
		this.subscriptions.push(this.statistikFacade.zaehlblattStatistik.subscribe(value => {
			this.prepareTableData(value);
		}));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	private prepareTableData(werte: ZaehlblattStatistik) {
		if (isNil(werte)) {
			return;
		}

		this.cols = werte.data.map(v => v.header);
		this.displayedColumns = ['Typ', ...this.cols];
		let tableEntries: any[] = [];
		for (let type of this.types) {
			let entry = {};
			entry['Typ'] = ZaehlblattAktionTypShortLabel[type];
			entry['typLang'] = (ZaehlblattAktionTypShortLabel[type] as string) != (ZaehlblattAktionTypLabel[type] as string) ? ZaehlblattAktionTypLabel[type] : null;
			for (let v of werte.data) {
				entry[v.header] = v.count[type];
			}
			tableEntries.push(entry);
		}
		this.dataSource.data = tableEntries;
	}

	exportZaehlblatt() {
		let csvString: string = this.toCsvLine(this.displayedColumns);
		for (let entry of this.dataSource.data) {
			csvString += this.toCsvLine(this.displayedColumns.map(c => entry[c]));
		}
		this.clipboardService.copyAndShowSnackBar(csvString, 'Das Zählblatt wurde in die Zwischenablage kopiert.');
	}

	toCsvLine(values: any[]) {
		const formattedValues = values.map(v => this.formatString(v, 11, true));
		formattedValues[0] = this.formatString(values[0], 29);
		return formattedValues.join(', ') + '\n';
	}

	formatString(value: string | number, minLength: number, alignRight: boolean = false): string {
		const text = value != null ? value.toString() : '';
		const padding = text.length < minLength ? new Array(minLength - text.length).join(' ') : '';
		return alignRight ? padding + text : text + padding;
	}
}
