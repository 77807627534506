<div class="x-section__headline">
	<h2 class="mat-h1">Verfahrensaktionsstatistik</h2>
</div>
<div class="x-section__subline">
	<div class="date-wrapper">
		<label class="date-prefix" for="vonDatum-input">Von:</label>
		<input id="vonDatum-input" class="date-input" [formControl]="vonDatumControl" matInput
		       [matDatepicker]="pickerVon" (dateInput)="dateChosen()" data-test-id="vonDatum-input">
		<mat-datepicker-toggle [for]="pickerVon" matTooltip="Kalender öffnen"
		                       aria-label="Kalender öffnen"></mat-datepicker-toggle>
		<mat-datepicker #pickerVon class="focus-inwards-light"></mat-datepicker>
	</div>
	<div class="date-wrapper">
		<label class="date-prefix" for="bisDatum-input">Bis:</label>
		<input id="bisDatum-input" class="date-input" [formControl]="bisDatumControl" matInput
		       [matDatepicker]="pickerBis" (dateInput)="dateChosen()" data-test-id="bisDatum-input">
		<mat-datepicker-toggle [for]="pickerBis" matTooltip="Kalender öffnen"
		                       aria-label="Kalender öffnen"></mat-datepicker-toggle>
		<mat-datepicker #pickerBis></mat-datepicker>
	</div>
</div>
<table mat-table [dataSource]="dataSource" aria-label="Anzahl Vorgänge nach Status und Register"
       data-test-id="statistik-table">
	<ng-container matColumnDef="Typ">
		<th mat-header-cell *matHeaderCellDef> Typ</th>
		<td mat-cell *matCellDef="let element" class="col-center"> {{ element.typ }}</td>
	</ng-container>
	<ng-container matColumnDef="SSR">
		<th mat-header-cell *matHeaderCellDef data-test-id="ssr-header"> SSR</th>
		<td mat-cell *matCellDef="let element"> {{ element[RegisterName.SSR] }}</td>
	</ng-container>
	<ng-container matColumnDef="BSR">
		<th mat-header-cell *matHeaderCellDef data-test-id="bsr-header"> BSR</th>
		<td mat-cell *matCellDef="let element"> {{ element[RegisterName.BSR] }}</td>
	</ng-container>
	<ng-container matColumnDef="SBR">
		<th mat-header-cell *matHeaderCellDef data-test-id="sbr-header"> SBR</th>
		<td mat-cell *matCellDef="let element"> {{ element[RegisterName.SBR] }}</td>
	</ng-container>
	<ng-container matColumnDef="LR">
		<th mat-header-cell *matHeaderCellDef data-test-id="lr-header"> LR</th>
		<td mat-cell *matCellDef="let element"> {{ element[RegisterName.LR] }}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="helper"></tr>
</table>
