import {CommonModule, TitleCasePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {Route, RouterModule} from '@angular/router';
import {AufgabeSharedModule} from '@schir-int-client/aufgabe-shared';
import {TechModule} from '@schir-int-client/tech';
import {AdressverwaltungSharedModule} from '@schir-int-client/adressverwaltung-shared';
import {AdressatenInAufgabeContainerComponent} from './adressaten-in-aufgabe-container/adressaten-in-aufgabe-container.component';
import {AdressatInAufgabeComponent} from './adressaten-in-aufgabe/adressat-in-aufgabe/adressat-in-aufgabe.component';
import {AdressatenInAufgabeComponent} from './adressaten-in-aufgabe/adressaten-in-aufgabe.component';
import {KontaktListContainerComponent} from './kontakt-list-container/kontakt-list-container.component';
import {AddAdressatToAufgabeDialogComponent} from './kontakt-list/add-adressat-to-aufgabe-dialog/add-adressat-to-aufgabe-dialog.component';
import {AdressverwaltungDialogComponent} from './kontakt-list/adressverwaltung-dialog/adressverwaltung-dialog.component';
import {AdressverwaltungKontaktListComponent} from './kontakt-list/adressverwaltung-dialog/adressverwaltung-kontakt-list/adressverwaltung-kontakt-list.component';
import {KontaktSearchFieldComponent} from './kontakt-list/adressverwaltung-dialog/adressverwaltung-kontakt-list/kontakt-search-field/kontakt-search-field.component';
import {AdressverwaltungKontaktViewComponent} from './kontakt-list/adressverwaltung-dialog/adressverwaltung-kontakt-view/adressverwaltung-kontakt-view.component';
import {KontaktFormService} from './kontakt-list/adressverwaltung-dialog/adressverwaltung-kontakt-view/kontakt.formservice';
import {KontaktListComponent} from './kontakt-list/kontakt-list.component';
import {KontaktInKategorieComponent} from './kontakt-list/kontakte-in-kategorie/kontakt-in-kategorie/kontakt-in-kategorie.component';
import {KontakteInKategorieComponent} from './kontakt-list/kontakte-in-kategorie/kontakte-in-kategorie.component';
import {NameByKontaktPipe} from './kontakt-name-pipe/kontakt-name-pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {KontaktAssignMenuComponent} from './kontakt-list/adressverwaltung-dialog/adressverwaltung-kontakt-list/kontakt-assign-menu/kontakt-assign-menu.component';

export const adressverwaltungListRoute: Route = {
	path: 'adressverwaltung/:verfahrenUri',
	component: KontaktListComponent,
	pathMatch: 'full',
};

@NgModule({
	imports: [
		AdressverwaltungSharedModule,
		AufgabeSharedModule,
		CommonModule,
		FormsModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatIconModule,
		MatIconModule,
		MatInputModule,
		MatMenuModule,
		MatOptionModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatTooltipModule,
		NgbDropdownModule,
		ReactiveFormsModule,
		RouterModule,
		TechModule,
	],
	declarations: [
		AddAdressatToAufgabeDialogComponent,
		AdressatenInAufgabeComponent,
		AdressatenInAufgabeContainerComponent,
		AdressatInAufgabeComponent,
		AdressverwaltungDialogComponent,
		AdressverwaltungKontaktListComponent,
		AdressverwaltungKontaktViewComponent,
		KontaktAssignMenuComponent,
		KontakteInKategorieComponent,
		KontaktInKategorieComponent,
		KontaktListComponent,
		KontaktListContainerComponent,
		KontaktSearchFieldComponent,
		NameByKontaktPipe,
	],
	exports: [
		AdressatenInAufgabeComponent,
		AdressatenInAufgabeContainerComponent,
		AdressatInAufgabeComponent,
		KontaktListComponent,
		KontaktListContainerComponent,
	],
	providers: [
		KontaktFormService,
		TitleCasePipe,
	],
})
export class AdressverwaltungModule {}
