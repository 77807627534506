import {Component, Input} from '@angular/core';
import {TooltipPosition} from '@angular/material/tooltip';

@Component({
	selector: 'schir-int-client-button-round',
	templateUrl: './button-round.component.html',
	styleUrls: ['./button-round.component.scss'],
})
export class ButtonRoundComponent {

	@Input() materialIcon: string;
	@Input() fontelloIcon: string;
	@Input() buttonType: 'submit' | 'button' = 'button';
	@Input() toolTip: string;
	@Input() toolTipPosition: TooltipPosition = 'below';
	@Input() popup: string;
	@Input() cssClass: string; //TODO einheitlich verwenden

	get isIconSmall(): boolean {
		return this.materialIcon === 'border_color' || this.fontelloIcon === 'eraser' || this.fontelloIcon === 'teilroeten';
	}
}
