import {Action} from '@ngrx/store';
import {
	LoadVerfahrenAktionStatistikSuccessAction,
	MonthlyStatistikLoadedAction,
	StatistikActions,
	StatistikLoadedAction,
	ZaehlblattStatistikLoadedAction,
} from './statistik.actions';
import {
	MonthlyStatistik,
	StatistikResource,
	VerfahrenAktionStatistik,
	ZaehlblattStatistikResource,
} from './statistik.model';

export interface StatistikState {
	statistik: StatistikResource;
	monthlyStatistik: MonthlyStatistik;
	zaehlblattStatistik: ZaehlblattStatistikResource;
	verfahrenAktionStatistik: VerfahrenAktionStatistik;
}

export const initialState: StatistikState = {
	statistik: null,
	monthlyStatistik: null,
	zaehlblattStatistik: null,
	verfahrenAktionStatistik: null,
};

export function statistikReducer(state: StatistikState = initialState, action: Action): StatistikState {
	switch (action.type) {
		case StatistikActions.STATISTIK_LOADED:
			return {
				...state,
				statistik: (<StatistikLoadedAction>action).payload,
			};
		case StatistikActions.MONTHLY_STATISTIK_LOADED:
			return {
				...state,
				monthlyStatistik: (<MonthlyStatistikLoadedAction>action).payload,
			};
		case StatistikActions.ZAEHLBLATT_STATISTIK_LOADED:
			return {
				...state,
				zaehlblattStatistik: (<ZaehlblattStatistikLoadedAction>action).payload,
			};
		case StatistikActions.LOAD_VERFAHREN_AKTION_STATISTIK_SUCCESS:
			return {
				...state,
				verfahrenAktionStatistik: (<LoadVerfahrenAktionStatistikSuccessAction>action).statistik,
			};
		default:
			return state;
	}
}
