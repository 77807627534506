import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AppNotificationService} from './app-notification.service';
import {ApiError} from './tech.model';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

	readonly errorMessage: string = 'Der Server hat mit einem Fehler geantwortet:\n';
	readonly copyAttachment: string = '\n\nBitte kopieren sie die Details zum Fehler in eine E-Mail und senden Sie diese an Ihren technischen Support.';

	constructor(
		private notificationService: AppNotificationService,
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((response: HttpErrorResponse) => {
				const { status, error } = response;
				const apiError: ApiError = error;


				if (!this.displaySufficientSpecialMessage(status, error)) {
					switch (true) {
						case (status >= 500 && status < 600 && response.error instanceof Blob):
							this.convertBlobResponse(response).then((error: ApiError) => {
								this.notificationService.handleError(this.createErrorMessage(), response.error.id, error);
							});
							break;
						case (status === 403):
							this.notificationService.handleError(this.createErrorMessage(apiError), response.error.id, response.error);
							break;
						default:
							this.notificationService.handleError(this.createErrorMessage(apiError), response.error.id, response.error);
					}
				}
				return throwError(() => response);
			}),
		);
	}

	private createErrorMessage(apiError?: ApiError) {
		return apiError?.issues ? this.errorMessage + apiError.issues[0].message : this.errorMessage + this.copyAttachment;
	}

	private convertBlobResponse(err: HttpErrorResponse): Promise<ApiError> {
		return new Promise<any>((resolve, reject) => {
			let reader = new FileReader();
			reader.onload = (e: Event) => {
				try {
					const errmsg = JSON.parse((<any>e.target).result);
					resolve(errmsg);
				} catch (e) {
					reject(err);
				}
			};
			reader.onerror = (e) => {
				reject(err);
			};
			reader.readAsText(err.error);
		});
	}

	/**
	 * Displays a message with special content which  contains all relevant information so that id and attachments should not be displayed.
	 * @param status
	 * @param error
	 */
	private displaySufficientSpecialMessage(status: number, error: any): boolean {
		if (!error || error instanceof Blob) {
			return false;
		}
		const msg = error.issues[0].message;
		if (status === 404 && msg === 'next available u-signal') {
			this.notificationService.handleError('Es stehen keine freien U-Signale mehr zur Verfügung');
			return true;
		}
		return false;
	}
}
