import {Injectable} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class ClipboardService {

	constructor(private clipboard: Clipboard, private bar: MatSnackBar) {}

	copyAndShowSnackBar(value: string, message: string) {
		this.clipboard.copy(value);
		this.bar.open(message, null, {
			horizontalPosition: 'center',
			verticalPosition: 'top',
			duration: 2500,
		});
	}
}
