<h2 mat-dialog-title data-test-id="dialog-title" tabindex="0" class="narrow">Kennzeichen für
	Verfahren {{ aktenzeichenRendered }}</h2>
<mat-dialog-content>
	<schir-int-client-one-value-editor [type]="'text'" label="Kennzeichen" data-test-id="kennzeichen-input"
	                                   [fieldControl]="getFormControl()" [formGroup]="form">
	</schir-int-client-one-value-editor>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-stroked-button (click)="onCancel()" data-test-id="cancel-button" class="focus-inwards-dark">Abbrechen
	</button>
	<button mat-flat-button (click)="onSave()" [disabled]="!canSafe()" color="primary"
	        class="icon-right focus-inwards-dark"
	        data-test-id="save-button">Speichern
	</button>
</mat-dialog-actions>
