import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {GerichtProfileService} from './gerichtprofile.service';
import {
	GerichtProfileActions,
	LoadGerichtProfileSuccessAction,
	UpdateGerichtProfileAction,
	UpdateGerichtProfileSuccessAction,
} from './gerichtprofile.actions';
import {GerichtProfileFacade} from './gerichtprofile.facade';
import {Store} from '@ngrx/store';
import {isNil} from 'lodash-es';
import {adminRootSelector} from 'libs/admin-shared/src/lib/admin.selectors';
import {AppState} from '../../../../../../apps/int-client/src/app/shared/store/app-state.model';

@Injectable()
export class GerichtProfileEffects {
	constructor(private actions: Actions,
	            private gerichtProfileService: GerichtProfileService,
	            private gerichtProfileFacade: GerichtProfileFacade,
	            private store: Store<AppState>) {}


	loadGerichtProfile = createEffect(() => this.actions.pipe(
		ofType(GerichtProfileActions.LOAD_GERICHT_PROFILE),
		withLatestFrom(this.store.select(adminRootSelector)),
		filter(([, adminRoot]) => !isNil(adminRoot)),
		switchMap(([, adminRoot]) => {
			return this.gerichtProfileService.getProfile(adminRoot).pipe(
				map(gerichtProfile => new LoadGerichtProfileSuccessAction(gerichtProfile)),
			);
		}),
	));


	updateGerichtProfile = createEffect(() => this.actions.pipe(
		ofType(GerichtProfileActions.UPDATE_GERICHT_PROFILE),
		withLatestFrom(this.gerichtProfileFacade.profile),
		switchMap(([action, profileToUpdate]) => {
			return this.gerichtProfileService.updateProfile(profileToUpdate, (<UpdateGerichtProfileAction>action).gerichtProfile).pipe(
				map(updateProfile => new UpdateGerichtProfileSuccessAction(updateProfile)),
			);
		}),
	));
}
