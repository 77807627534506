<div class="dialog">
	<h1>Entschuldigung,</h1>
	<p>Ihr Browser kann diese Seite momentan nicht darstellen.</p>
	<p>Bitte benutzen Sie innerhalb der Anwendung nicht den Zurück-Button des Browsers und speichern Sie keine
		Lesezeichen auf Seiten innerhalb der Anwendung. Verwenden Sie zur Navigation ausschließlich die Steuerelemente
		der Seite.
	</p>
	<p>Sollte das Problem wiederholt auftreten, wenden Sie sich bitte an Ihren Administrator unter Angabe der Aktionen,
		welche Sie vor Auftreten dieses Fehlers ausgeführt haben bzw. ausführen wollten.
	</p>
	<a href="/">Zurück zur Anwendung</a>
</div>
