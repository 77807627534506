import {InjectionToken} from '@angular/core';
import {routerReducer} from '@ngrx/router-store';
import {ActionReducerMap} from '@ngrx/store';
import {apiRootFeatureState, apiRootReducer} from '@schir-int-client/api-root';
import {reducer as environmentReducer} from '@schir-int-client/environment';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {AppState} from './app-state.model';
import {adminRootFeatureState} from '@schir-int-client/admin-shared';

export function getReducers(): ActionReducerMap<AppState> {
	return {
		[adminRootFeatureState]: null,
		[apiRootFeatureState]: apiRootReducer,
		environment: environmentReducer,
		router: routerReducer,
	};
}

export const reducersInjectionToken = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers');

export const reducerConfig: ReducerConfig<AppState> = {
	reducers: getReducers(),
	injectionToken: reducersInjectionToken,
};
