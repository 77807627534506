import {Component, Inject} from '@angular/core';
import {BaseEditorComponent} from '@schir-int-client/tech';
import {FormControl, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {
	orderedZaehlblattAktionTypes,
	VerfahrenResource,
	ZaehlblattAktionen,
	ZaehlblattAktionTyp,
	ZaehlblattAktionTypLabel,
} from '@schir-int-client/verfahren-shared';
import {ZaehlblattFacade} from './zaehlblatt.facade';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ApiRootFacade} from '@schir-int-client/api-root';

@Component({
	selector: 'schir-int-client-zaehlblatt-erfassen',
	templateUrl: './zaehlblatt-erfassen.component.html',
	styleUrls: ['./zaehlblatt-erfassen.component.scss'],
})
export class ZaehlblattErfassenComponent extends BaseEditorComponent {

	private readonly MAX_AKTIONEN = 20;

	private readonly verfahren: VerfahrenResource;

	form: UntypedFormGroup = this.createForm();

	readonly labels = ZaehlblattAktionTypLabel;
	readonly types: ZaehlblattAktionTyp[];

	readonly aktenzeichenRendered: string;

	private alreadyClicked = [];

	constructor(@Inject(MAT_DIALOG_DATA) data, private facade: ZaehlblattFacade, apiRootFacade: ApiRootFacade) {
		super();
		this.verfahren = data['verfahren'];
		this.aktenzeichenRendered = data['aktenzeichen'];
		const aktionen = apiRootFacade.zaehlblattAktionTypenByRegister[this.verfahren.register];
		this.types = orderedZaehlblattAktionTypes.filter(aktion => aktionen.includes(aktion));
	}

	createForm(): UntypedFormGroup {
		var controls = {};
		for (const key in ZaehlblattAktionTyp) {
			controls[key] = new FormControl<number>(null, [Validators.min(0), Validators.max(this.MAX_AKTIONEN)]);
		}
		return new FormGroup(controls);
	}

	async submit(): Promise<boolean> {
		if (!this.canSafe()) {
			return false;
		}
		this.facade.addZaehlblattAktionen(<ZaehlblattAktionen>this.form.value, this.verfahren);
		return true;
	}

	getFormControl(control: string) {
		return <FormControl<number>>this.form.controls[control];
	}


	canSafe() {
		return this.form.valid && Object.values(this.form.controls).some(control => control.value > 0);
	}

	increment(controlName: string) {
		if (!this.alreadyClicked.includes(controlName)) {
			this.alreadyClicked.push(controlName);
			this.getControl(controlName).patchValue(1);
		}
	}

	getControl(controlName: string): FormControl {
		return this.form.get(controlName) as FormControl;
	}
}
