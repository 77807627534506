<div class="x-section__headline" data-test-id="headline">
	<h2 class="mat-h1" tabindex="0" aria-labelledby="sr-label-adre">{{ aufgabe | labelByAufgabe }}</h2>
</div>
<div class="x-section__subline">
	<i class="material-icons" aria-hidden="true">contact_mail</i>
	<h3 class="mat-body margin-right-auto">Adressaten</h3>
	<schir-int-client-button-round *ngIf="aufgabe | hasResourceLink: linkRel.DOWNLOAD_KORRESPONDENZEN_ARCHIV"
	                               materialIcon="archive" data-test-id="download-korrespondenzen-archiv-button"
	                               (click)="downloadKorrespondenzenArchiv()"
	                               toolTip="Alle Korrespondenzen im Archiv downloaden" cssClass="focus-inwards-dark">
	</schir-int-client-button-round>
	<schir-int-client-button-round *ngIf="aufgabe | hasResourceLink: linkRel.DOWNLOAD_KORRESPONDENZEN_ARCHIV"
	                               materialIcon="text_snippet" data-test-id="download-korrespondenzen-merge-button"
	                               (click)="downloadKorrespondenzenMerge()"
	                               toolTip="Alle Korrespondenzen in einem Dokument downloaden"
	                               cssClass="focus-inwards-dark">
	</schir-int-client-button-round>
</div>
<div class="x-section__main" (drop)="drop($event)" (dragover)="allowDrop($event)" data-test-id="drop-kontakt-area">
	<div class="c-spinner" data-test-id="spinner" #spinner>
		<mat-spinner></mat-spinner>
	</div>
	<ul class="c-list" *ngIf="kontakteInAufgabe | async as _kontakteInAufgabe">
		<schir-int-client-adressat-in-aufgabe *ngFor="let kontakt of _kontakteInAufgabe" [adressat]="kontakt">
		</schir-int-client-adressat-in-aufgabe>
	</ul>
</div>
<span id="sr-label-adre" class="sr-only">{{ aufgabe | labelByAufgabe }} Adressaten</span>
