import {Component, Inject, OnInit} from '@angular/core';
import {getUrl, ResourceUri} from '@ngxp/rest';
import {VorgangFacade, VorgangResource, VorgangStatus} from '@schir-int-client/vorgang-shared';
import {RechtspflegerFacade, UserResource} from '@schir-int-client/user-shared';
import {Observable} from 'rxjs';
import {addAriaAttributesToMatSelect, BaseEditorComponent} from '@schir-int-client/tech';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'schir-int-client-assign-vorgang-dialog',
	templateUrl: './assign-vorgang-dialog.component.html',
	styleUrls: ['./assign-vorgang-dialog.component.scss'],
})
export class AssignVorgangDialogComponent extends BaseEditorComponent implements OnInit {

	readonly selectedRechtspflegerControlName = 'selectedRechtspfleger';

	vorgang: VorgangResource;
	rechtspfleger: Observable<UserResource[]>;

	constructor(@Inject(MAT_DIALOG_DATA) private data,
	            private vorgangFacade: VorgangFacade,
	            private rechtspflegerFacade: RechtspflegerFacade) {
		super();

		this.vorgang = this.data.vorgang;
		this.rechtspfleger = this.rechtspflegerFacade.rechtspfleger;

		this.form.controls[this.selectedRechtspflegerControlName].patchValue(this.vorgang.rechtspfleger);
	}

	form: UntypedFormGroup = new UntypedFormGroup({
		[this.selectedRechtspflegerControlName]: new UntypedFormControl(null, Validators.required),
	});

	ngOnInit(): void {
		addAriaAttributesToMatSelect();
	}

	getUrl(rechtspflegerResource: UserResource): ResourceUri {
		return getUrl(rechtspflegerResource);
	}

	async submit(): Promise<boolean> {
		const selectedRechtspfleger: string = this.form.controls[this.selectedRechtspflegerControlName].value;
		this.vorgangFacade.assignVorgang(this.vorgang, selectedRechtspfleger, VorgangStatus.RECHTSPFLEGE);

		return true;
	}
}
