import {Action} from '@ngrx/store';
import {KontaktKategorie, KontaktResource} from '@schir-int-client/adressverwaltung-shared';
import {RegisterName} from '@schir-int-client/register-shared';
import {
	NewVerfahren, RegisterAusdruckType,
	VerfahrenListResource,
	VerfahrenResource,
	VerfahrenSearchQuery,
	ZaehlblattAktionen,
	ZaehlblattEntry,
	ZaehlblattResource,
} from './verfahren.model';

export enum VerfahrenActions {
	ASKTO_CREATE_NEUEINTRAGUNG = 'ASKTO_CREATE_NEUEINTRAGUNG',
	ASKTO_MARK_AS_ABGELEHNT = 'ASKTO_MARK_AS_ABGELEHNT',
	MARK_AS_ABGELEHNT = 'MARK_AS_ABGELEHNT',
	LOAD_VERFAHREN = 'LOAD_VERFAHREN',
	VERFAHREN_LOADED = 'VERFAHREN_LOADED',
	LOAD_VERFAHREN_SINGLE = 'LOAD_VERFAHREN_SINGLE',
	LOAD_VERFAHREN_SINGLE_SUCCESS = 'LOAD_VERFAHREN_SINGLE_SUCCESS',
	VERFAHREN_UPDATED_SUCCESS = 'VERFAHREN_UPDATED_SUCCESS',

	VERFAHREN_SELECTED = 'VERFAHREN_SELECTED',
	ASKTO_DELETE = 'ASKTO_DELETE',
	DELETE_VERFAHREN = 'DELETE_VERFAHREN',
	DOWNLOAD_AMTLICHER_AUSZUG = 'DOWNLOAD_AMTLICHER_AUSZUG',
	DOWNLOAD_SCHIFFSZERTIFIKAT = 'DOWNLOAD_SCHIFFSZERTIFIKAT',
	DOWNLOAD_SCHIFFSBRIEF = 'DOWNLOAD SCHIFFSBRIEF',
	DOWNLOAD_AMTLICHER_AUSZUG_SUCCESS = 'DOWNLOAD_AMTLICHER_AUSZUG_SUCCESS',
	DOWNLOAD_SCHIFFSZERTIFIKAT_SUCCESS = 'DOWNLOAD_SCHIFFSZERTIFIKAT_SUCCESS',
	DOWNLOAD_SCHIFFSBRIEF_SUCCESS = 'DOWNLOAD_SCHIFFSBRIEF_SUCCESS',
	DOWNLOAD_AUSDRUCK = 'DOWNLOAD_REGISTER_AUSDRUCK',
	DOWNLOAD_AUSDRUCK_SUCCESS = 'DOWNLOAD_AUSDRUCK_SUCCESS',
	CREATE_VERFAHREN = 'CREATE_VERFAHREN',
	VERFAHREN_CREATED = 'VERFAHREN_CREATED',
	VERFAHREN_CREATE_FAIL = 'VERFAHREN_CREATE_FAIL',
	SAVE_REGISTER_AUFSCHRIFT = 'SAVE_REGISTER_AUFSCHRIFT',
	VERFAHREN_UPDATE_NOTIZ = 'VERFAHREN_UPDATE_NOTIZ',
	ASSIGN_USIGNAL = 'ASSIGN_USIGNAL',
	REPORT_NO_USIGNAL_LEFT = 'REPORT_NO_USIGNAL_LEFT',
	REPORT_DUPLICATE_USIGNAL = 'REPORT_DUPLICATE_USIGNAL',
	RELOAD_SEARCH_RESULT = 'RELOAD_SEARCH_RESULT',
	CREATE_VERAHREN_WITHOUT_POSTEINGANG = 'CREATE_VERAHREN_WITHOUT_POSTEINGANG',
	BEARBEITEN = 'BEABEITEN',
	BEARBEITEN_BEENDEN = 'BEARBEITEN_BEENDEN',
	UEBERNEHMEN = 'UEBERNEHMEN',
	ASKTO_UNASSIGN_KONTAKT = 'ASKTO_UNASSIGN_KONTAKT',
	UNASSIGN_KONTAKT = 'UNASSIGN_KONTAKT',
	UNASSIGN_KONTAKT_SUCCESS = 'UNASSIGN_KONTAKT_SUCCESS',

	SEARCH_VERFAHREN = 'SEARCH_VERFAHREN',
	SEARCH_VERFAHREN_GLOBAL = 'SEARCH_VERFAHREN_GLOBAL',
	SEARCH_VERFAHREN_SUCCESS = 'SEARCH_VERFAHREN_SUCCESS',
	RESET_VERFAHREN_SEARCH = 'RESET_VERFAHREN_SEARCH',
	SET_PROPERTY = 'SET_VERFAHREN_PROPERTY',

	LOAD_ZAEHLBLATT = 'LOAD_ZAEHLBLATT',
	LOAD_ZAEHLBLATT_SUCCESS = 'LOAD_ZAEHLBLATT_SUCCESS',
	ADD_ZAEHLBLATT_AKTIONEN = 'ADD_ZAEHLBLATT_AKTIONEN',
	DELETE_ZAEHLBLATT_AKTION = 'DELETE_ZAEHLBLATT_AKTION',

	EXPORT_VERFAHREN = 'EXPORT_VERFAHREN',
	EXPORT_VERFAHREN_SUCCESS = 'EXPORT_VERFAHREN_SUCCESS'
}

export class CreateNeueintragungAskToAction implements Action {
	type = VerfahrenActions.ASKTO_CREATE_NEUEINTRAGUNG;

	constructor(public register: RegisterName) { }
}

export class AskToDeleteVerfahrenAction implements Action {
	type = VerfahrenActions.ASKTO_DELETE;

	constructor(public verfahren: VerfahrenResource) { }
}

export class DeleteVerfahrenAction implements Action {
	type = VerfahrenActions.DELETE_VERFAHREN;

	constructor(public verfahren: VerfahrenResource) { }
}

export class LoadVerfahrenAction implements Action {
	type = VerfahrenActions.LOAD_VERFAHREN;
}

export class VerfahrenLoadedAction implements Action {
	type = VerfahrenActions.VERFAHREN_LOADED;

	constructor(public verfahrenList: VerfahrenListResource) { }
}

export class LoadVerfahrenSingleAction implements Action {
	type = VerfahrenActions.LOAD_VERFAHREN_SINGLE;

	constructor(public verfahrenUri: string) {}
}

export class LoadVerfahrenSingleSuccessAction implements Action {
	type = VerfahrenActions.LOAD_VERFAHREN_SINGLE_SUCCESS;

	constructor(public verfahren: VerfahrenResource, public predecessor?: VerfahrenResource) { }
}

export class VerfahrenUpdatedSuccessAction implements Action {
	type = VerfahrenActions.VERFAHREN_UPDATED_SUCCESS;

	constructor(public verfahren: VerfahrenResource) { }
}

export class VerfahrenSearchAction implements Action {
	type = VerfahrenActions.SEARCH_VERFAHREN;

	constructor(public query: VerfahrenSearchQuery) { }
}

export class VerfahrenSearchSuccessAction implements Action {
	type = VerfahrenActions.SEARCH_VERFAHREN_SUCCESS;

	constructor(public verfahren: VerfahrenListResource) { }
}

export class DownloadAmtlicherAuszugAction implements Action {
	type = VerfahrenActions.DOWNLOAD_AMTLICHER_AUSZUG;

	constructor() { }
}

export class DownloadAusdruckAction implements Action {
	type = VerfahrenActions.DOWNLOAD_AUSDRUCK;

	constructor(public ausdruckType: RegisterAusdruckType) { }
}

export class DownloadAusdruckSuccessAction implements Action {
	type = VerfahrenActions.DOWNLOAD_AUSDRUCK_SUCCESS;

	constructor(public data: Blob) { }
}

export class DownloadSchiffszertifikatAction implements Action {
	type = VerfahrenActions.DOWNLOAD_SCHIFFSZERTIFIKAT;

	constructor() { }
}

export class DownloadSchiffsbriefAction implements Action {
	type = VerfahrenActions.DOWNLOAD_SCHIFFSBRIEF;

	constructor() { }
}

export class DownloadAmtlicherAuszugSuccessAction implements Action {
	type = VerfahrenActions.DOWNLOAD_AMTLICHER_AUSZUG_SUCCESS;

	constructor(public data: Blob) { }
}

export class DownloadSchiffszertifikatSuccessAction implements Action {
	type = VerfahrenActions.DOWNLOAD_SCHIFFSZERTIFIKAT_SUCCESS;

	constructor(public data: Blob) { }
}

export class DownloadSchiffsbriefSuccessAction implements Action {
	type = VerfahrenActions.DOWNLOAD_SCHIFFSBRIEF_SUCCESS;

	constructor(public data: Blob) { }
}

export class CreateVerfahrenAction implements Action {
	type = VerfahrenActions.CREATE_VERFAHREN;

	constructor(public register: RegisterName) { }
}

export class VerfahrenCreatedAction implements Action {
	type = VerfahrenActions.VERFAHREN_CREATED;
}

export class VerfahrenCreateFailAction implements Action {
	type = VerfahrenActions.VERFAHREN_CREATE_FAIL;

	constructor(public error: any) { }
}

export class UpdateVerfahrenNotizAction implements Action {
	type = VerfahrenActions.VERFAHREN_UPDATE_NOTIZ;

	constructor(public notiz: string, public verfahrenUri: string) { }
}

export class AssignUSignalAction implements Action {
	type = VerfahrenActions.ASSIGN_USIGNAL;

	constructor(public verfahren: VerfahrenResource) { }
}

export class ReloadSearchResultAction implements Action {
	type = VerfahrenActions.RELOAD_SEARCH_RESULT;
}

export class CreateVerfahrenWithoutPosteingangAction implements Action {
	type = VerfahrenActions.CREATE_VERAHREN_WITHOUT_POSTEINGANG;

	constructor(public newVerfahren: NewVerfahren) { }
}

export class VerfahrenBearbeitenAction implements Action {
	type = VerfahrenActions.BEARBEITEN;

	constructor() { }
}

export class VerfahrenBearbeitenBeendenAction implements Action {
	type = VerfahrenActions.BEARBEITEN_BEENDEN;

	constructor() { }
}

export class VerfahrenUebernehmenAction implements Action {
	type = VerfahrenActions.UEBERNEHMEN;

	constructor() { }
}

export class MarkAsAbgelehntAction implements Action {
	type = VerfahrenActions.MARK_AS_ABGELEHNT;

	constructor(public verfahren: VerfahrenResource) { }
}

export class AskToMarkAsAbgelehntAction implements Action {
	type = VerfahrenActions.ASKTO_MARK_AS_ABGELEHNT;

	constructor(public verfahren: VerfahrenResource) { }
}

export class AskToUnassignKontaktAction implements Action {
	type = VerfahrenActions.ASKTO_UNASSIGN_KONTAKT;

	constructor(public kontakt: KontaktResource, public kategorie: KontaktKategorie) { }
}

export class UnassignKontaktAction implements Action {
	type = VerfahrenActions.UNASSIGN_KONTAKT;

	constructor(public kontakt: KontaktResource, public kategorie: KontaktKategorie) { }
}

export class UnassignKontaktSuccessAction implements Action {
	type = VerfahrenActions.UNASSIGN_KONTAKT_SUCCESS;

	constructor(public verfahren: VerfahrenResource, public kategorie: KontaktKategorie) { }
}

// corresponding success action is VerfahrenUpdatedSuccessAction
export class SetVerfahrenPropertyAction implements Action {
	type = VerfahrenActions.SET_PROPERTY;

	constructor(public verfahren: VerfahrenResource, public linkRel: string, public value: string) { }
}

export class LoadZaehlblattAction implements Action {
	type = VerfahrenActions.LOAD_ZAEHLBLATT;

	constructor(public verfahren: VerfahrenResource) { }
}

export class LoadZaehlblattSuccessAction implements Action {
	type = VerfahrenActions.LOAD_ZAEHLBLATT_SUCCESS;

	constructor(public zaehlblatt: ZaehlblattResource) { }
}

export class AddZaehlblattAktionenAction {
	type = VerfahrenActions.ADD_ZAEHLBLATT_AKTIONEN;

	constructor(public values: ZaehlblattAktionen, public zaehlblatt: ZaehlblattResource) { }
}

export class DeleteZaehlblattAktionAction {
	type = VerfahrenActions.DELETE_ZAEHLBLATT_AKTION;

	constructor(public entry: ZaehlblattEntry) {}
}

export class ExportVerfahrenAction implements Action {
	type = VerfahrenActions.EXPORT_VERFAHREN;

	constructor(public url: string) { }
}


export class ExportVerfahrenSuccessAction implements Action {
	type = VerfahrenActions.EXPORT_VERFAHREN_SUCCESS;

	constructor(public data: Blob) { }
}



