import * as moment from 'moment';

export function initDeLocal() {
	moment.locale('de');
}

/**
 * moment() macht Probleme, wenn für Playwright benutzt. Daher hier die "modernere" Alternative.
 * Wenn getestet ist, dass sie auch überall funktioniert, sollten alle Methoden hier umgestellt werden.
 * In der aktuellen Situation möchte ich keine Änderung in diversen Protractor-Tests.
 */
export function todayWithoutMoment(): string {
	const formatter = new Intl.DateTimeFormat('de', { day: '2-digit', month: '2-digit', year: 'numeric' });
	return formatter.format(new Date());
}

export function today(): string {
	return format(new Date());
}

export function getDateWithDaysInFuture(offsetDays: number): string {
	let date = new Date();
	date.setDate(date.getDate() + offsetDays);

	return format(date);
}

export function format(date: Date) {
	return moment(date).format('DD.MM.yyyy');
}

export function formatAsDatepicker(date: Date) {
	return moment(date).format('DD.MM.YYYY');
}

export function stringToDate(dateString: string) {
	const split = dateString.split('.');

	return new Date(split[2] + '-' + split[1] + '-' + split[0]);
}
