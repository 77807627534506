<li class="c-list__item focus-inwards-dark"
    [attr.data-test-id]="'verfahren-single-item ' + aktenzeichen">

	<div class="c-list__link list"
	     data-test-id="set-selected-verfahren"
	     tabindex="0"
	     (click)="openVerfahren($event)"
	     [elementFocus]="(isSelected | async) === true"
	     (keydown.enter)="openVerfahren()">
		<div data-test-id="aktenzeichen" class="headline">
			<schir-int-client-aktenzeichen [verfahren]="verfahren"></schir-int-client-aktenzeichen>
		</div>
		<!--		nvda sends click event on keydown; nvda switches focus on inner element by itself-->
		<div data-test-id="notiz" *ngIf="verfahren.notiz">
			<span>Notiz</span>: {{ verfahren.notiz }}
		</div>
		<div class="regiter-data">
			<div class="data-item">
				<div data-test-id="schiffsname" *ngIf="stammdaten.schiffName">
					<span>Schiffsname</span>: {{ stammdaten.schiffName }}
				</div>
				<div data-test-id="historische-schiffsnamen" *ngIf="stammdaten.historischeSchiffNamen">
					<span>Historische Schiffsnamen</span>: {{ stammdaten.historischeSchiffNamen }}
				</div>
				<div data-test-id="unsigned-schiffsnamen" *ngIf="stammdaten.unsignedSchiffName">
					<span>Unsignierter Schiffsname</span>: {{ stammdaten.unsignedSchiffName }}
				</div>
				<div data-test-id="status">
					<span>Status</span>: {{ verfahren.status | enumToLabel: verfahrenStatusLabel }}
				</div>
			</div>

			<div class="data-item">
				<div data-test-id="eigentuemer" *ngIf="stammdaten.eigentuemerNamen">
					<span>Eigentümer</span>: {{ stammdaten.eigentuemerNamen }}
				</div>
				<div data-test-id="unsigned-eigentuemer" *ngIf="stammdaten.unsignedEigentuemerNamen">
					<span>Unsignierte Eigentümer</span>: {{ stammdaten.unsignedEigentuemerNamen }}
				</div>
				<div data-test-id="u-signal" *ngIf="stammdaten.unterscheidungssignal">
					<span>U-Signal</span>: {{ stammdaten.unterscheidungssignal }}
				</div>
				<div data-test-id="unsigned-u-signale" *ngIf="stammdaten.unsignedUnterscheidungssignal">
					<span>Unsignierte U-Signale</span>: {{ stammdaten.unsignedUnterscheidungssignal }}
				</div>
				<div data-test-id="eigentuemer-imo-nr" *ngIf="stammdaten.imoNummer">
					<span>IMO-Nr.</span>: {{ stammdaten.imoNummer }}
				</div>
				<div data-test-id="unsigned-imo-nrn" *ngIf="stammdaten.unsignedImoNummer">
					<span>Unsignierte IMO-Nrn.</span>: {{ stammdaten.unsignedImoNummer }}
				</div>
				<div data-test-id="eintragungszeichen" *ngIf="stammdaten.eintragungszeichen">
					<span>Eintragungszeichen</span>: {{ stammdaten.eintragungszeichen }}
				</div>
				<div data-test-id="unsigned-eintragungszeichen" *ngIf="stammdaten.unsignedEintragungszeichen">
					<span>Unsigniertes Eintragungszeichen</span>: {{ stammdaten.unsignedEintragungszeichen }}
				</div>
				<div data-test-id="standort-akte" *ngIf="stammdaten.standortAkte">
					<span>Standort Akte</span>: {{ stammdaten.standortAkte }}
				</div>
				<div data-test-id="kennzeichen" *ngIf="stammdaten.arKennzeichen">
					<span>Kennzeichen</span>: {{ stammdaten.arKennzeichen }}
				</div>
			</div>
		</div>
	</div>

	<schir-int-client-verfahren-single-buttons [verfahren]="verfahren"
	                                           (openAmtlichenAuszugErstellenDialog)="openAmtlichenAuszugErstellenDialog()"
	                                           (downloadSchiffsbrief)="downloadSchiffsbrief()"
	                                           (downloadSchiffszertifikat)="downloadSchiffszertifikat()"
	                                           (openRegisterblatt)="openRegisterBlatt()"
	                                           (openNotizDialog)="openNotizDialog()"
	                                           (markAsAbgelehnt)="markAsAbgelehnt()"
	                                           (deleteVerfahren)="deleteVerfahren()"
	                                           (assignUSignal)="openUsignalZuordnenDialog()">
	</schir-int-client-verfahren-single-buttons>
</li>
