<div class="x-section" data-test-id="eingang-list-root">
	<div class="x-section__headline">
		<i class="material-icons" aria-hidden="true">{{ icon }}</i>
		<h2 class="mat-h1 margin-right-auto" tabindex="0">{{ headline }}</h2>
	</div>
	<div class="x-section__subline"></div>
	<div class="x-section__main">
		<ul class="c-list" *ngIf="vorgaenge">
			<li class="c-list__item focus-inwards-dark" data-test-id="eintrag" *ngFor="let vorgang of vorgaenge">
				<a class="c-list__link" data-test-id="set-selected-vorgang" (click)="goToVorgang(vorgang)"
				   (keyup.enter)="goToVorgang(vorgang)" tabindex="0">
					<div class="c-row c-row--header c-wrap">
						<schir-int-client-aktenzeichen class="c-row__item c-row__item--header"
						                               data-test-id="aktenzeichen"
						                               [vorgang]="vorgang"></schir-int-client-aktenzeichen>
						<span class="c-row__item c-row__item--secondary"
						      data-test-id="vorgang-status">{{ vorgang.status | enumToLabel: vorgangStatusLabel }}</span>
					</div>
					<div class="c-row c-wrap">
						<span class="c-row__item"
						      data-test-id="vorgang-typ">{{ vorgang.typ | enumToLabel: vorgangsTypen }}</span>
						<span class="c-row__item"
						      data-test-id="vorgang-datum">{{ vorgang.anlageDatum | formatDate }}</span>
					</div>
					<div class="c-row c-row--secondary c-wrap">
						<span class="c-row__item" data-test-id="vorgang-notiz">{{ vorgang.notiz }}</span>
						<span class="c-row__item" *ngIf="getRechtspfleger(vorgang) | async as _rechtspfleger"
						      data-test-id="vorgang-rechtspfleger">
							{{ _rechtspfleger.lastName }}
						</span>
					</div>
					<div *ngIf="getStandOrtAkteForVorgang(vorgang)" class="c-row c-row--secondary c-wrap">
						<span class="c-row__item"
						      data-test-id="vorgang-standortAkte">Standort Akte: {{ getStandOrtAkteForVorgang(vorgang) }}</span>
					</div>
				</a>
				<div class="c-button-bar c-button-bar--overlay">
					<schir-int-client-button-round data-test-id="open-status-dialog-button"
					                               (click)="openStatusDialog(vorgang)"
					                               popup="dialog" materialIcon="send"
					                               toolTip="Status des Vorgangs setzen"
					                               cssClass="focus-inwards-dark">
					</schir-int-client-button-round>
				</div>
			</li>
		</ul>
	</div>
</div>
