<form [formGroup]="form" data-test-id="formular">
	<h2 mat-dialog-title tabindex="0">Versandart für Adressat auswählen</h2>
	<div mat-dialog-content data-test-id="add-adressat-to-aufgabe-dialog-content">
		<div class="bordered-field">
			<h3>Kontakt</h3>
			<div data-test-id="kontakt-fullname" class="bold">{{ kontakt | nameByKontaktPipe }}</div>
			<div data-test-id="kontakt-kategorie">{{ kategorie | enumToLabel: kontaktKategorieLabel }}</div>
		</div>
		<fieldset class="bordered-field">
			<legend>Kanal wählen</legend>
			<mat-checkbox formControlName="channelBrief" color="primary" data-test-id="brief-checkbox">Brief
			</mat-checkbox>
			<mat-checkbox formControlName="channelFax" color="primary" data-test-id="fax-checkbox">Fax
			</mat-checkbox>
			<mat-checkbox formControlName="channelEmail" color="primary" data-test-id="email-checkbox">E-Mail
			</mat-checkbox>
			<mat-checkbox formControlName="channelAbholung" color="primary" data-test-id="abholung-checkbox">Abholung
			</mat-checkbox>
			<mat-checkbox formControlName="channelEgvp" color="primary" data-test-id="egvp-checkbox">EGVP
			</mat-checkbox>
		</fieldset>
	</div>
	<mat-dialog-actions data-test-id="add-adressat-to-aufgabe-dialog-actions">
		<button mat-stroked-button type="button" (click)="onCancel()" class="focus-inwards-dark"
		        data-test-id="cancel-button">Abbrechen
		</button>
		<button mat-flat-button type="submit" (click)="onSave()" [disabled]="!isValid()" color="primary"
		        class="icon-right focus-inwards-dark"
		        data-test-id="submit-button">Speichern
		</button>
	</mat-dialog-actions>
</form>
