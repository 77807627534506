<div class="x-section__headline">
	<h2 class="mat-h1">Vorlagen</h2>
</div>
<div class="x-section__subline">
	<i class="material-icons"></i>
	<schir-int-client-button-round data-test-id="open-dialog-button" (click)="openDialog()" popup="dialog"
	                               materialIcon="add" toolTip="Vorlage hinzufügen"
	                               cssClass="focus-inwards-dark schir-inline-button">
	</schir-int-client-button-round>
</div>
<schir-int-client-vorlage-list class="x-section__main"></schir-int-client-vorlage-list>
