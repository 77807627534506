import {AfterContentInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {
	Adressat,
	AdressatDragData,
	AdressatFacade,
	AdressatWithResource,
	AufgabeActions,
	AufgabeFacade,
	AufgabeLinkRel,
	AufgabeResource,
} from '@schir-int-client/aufgabe-shared';
import {Spinner} from '@schir-int-client/tech';
import {Observable, Subscription} from 'rxjs';
import {AdressatFormService} from '../kontakt-list/add-adressat-to-aufgabe-dialog/adressat.formservice';
import {AdressverwaltungService} from '@schir-int-client/adressverwaltung-shared';
import {KorrespondenzFacade} from '@schir-int-client/korrespondenz-shared';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'schir-int-client-adressaten-in-aufgabe',
	templateUrl: './adressaten-in-aufgabe.component.html',
	styleUrls: ['./adressaten-in-aufgabe.component.scss'],
	providers: [AdressatFormService],
})
export class AdressatenInAufgabeComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {

	@Input() aufgabe: AufgabeResource;
	@ViewChild('spinner', { static: true }) spinnerRef: ElementRef;

	readonly linkRel = AufgabeLinkRel;

	kontakteInAufgabe: Observable<AdressatWithResource[]>;

	private spinner: Spinner;
	dialogRef: MatDialogRef<any>;

	adressatenSubscription: Subscription;
	adressaten: AdressatWithResource[];

	constructor(
		private aufgabeFacade: AufgabeFacade,
		private actions: Actions,
		private adressatFacade: AdressatFacade,
		private formService: AdressatFormService,
		private adressverwaltungService: AdressverwaltungService,
		private korrespondenzFacade: KorrespondenzFacade,
	) { }

	get form() {
		return this.formService.form;
	}

	ngOnInit(): void {
		this.kontakteInAufgabe = this.aufgabeFacade.getAdressatenByAufgabe(this.aufgabe);

		this.adressatenSubscription = this.kontakteInAufgabe.subscribe((adressaten) => {
			this.adressaten = adressaten;
		});
	}

	ngOnChanges(): void {
		this.kontakteInAufgabe = this.aufgabeFacade.getAdressatenByAufgabe(this.aufgabe);
	}

	ngAfterContentInit() {
		this.spinner = new Spinner(this.actions, AufgabeActions.LOAD_ADRESSATEN, AufgabeActions.LOAD_ADRESSATEN_SUCCESS, this.spinnerRef);
		this.adressverwaltungService.adressatenColumDisplayed(true);
	}

	ngOnDestroy() {
		this.spinner.destroy();
		this.adressverwaltungService.adressatenColumDisplayed(false);
		this.adressatenSubscription.unsubscribe();
	}

	drop(event) {
		event.preventDefault();
		const transferdData: AdressatDragData = JSON.parse(event.dataTransfer.getData('text'));
		this.formService.patchChannelBriefToTrue();
		this.createAdressat(transferdData);

		if (this.adressaten?.length == 0) {
			this.aufgabeFacade.updateAufgabe(this.aufgabe);
		}
	}

	createAdressat(transferdData) {
		const adressat: Adressat = {
			...this.formService.getChannel(),
			kategorie: transferdData.kategorie,
			kontakt: transferdData.uri,
			notiz: '',
		};
		this.adressatFacade.createAdressat(adressat);
	}

	downloadKorrespondenzenArchiv() {
		this.korrespondenzFacade.getKorrespondenzenArchiv(this.aufgabe);
	}

	downloadKorrespondenzenMerge() {
		this.korrespondenzFacade.getKorrespondenzenMerge(this.aufgabe);
	}

	allowDrop(event) {
		event.preventDefault();
	}

}
