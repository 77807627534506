<mat-dialog-content>
	<h2 data-test-id="profil-heading">Profileinstellungen</h2>
	<form class="user-settings-form" [formGroup]="form">
		<h3>Nutzer(-in)</h3>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Telefonnummer'"
		                                   [fieldControl]="getSubFormControl('anwender', 'telefon')"
		                                   data-test-id="telefonnummer-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Amtsbezeichnung'"
		                                   [fieldControl]="getSubFormControl('anwender', 'amtsbezeichnung')"
		                                   data-test-id="amtsbezeichnung-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Dienstbezeichnung'"
		                                   [fieldControl]="getSubFormControl('anwender', 'dienstbezeichnung')"
		                                   data-test-id="dienstbezeichnung-input">
		</schir-int-client-one-value-editor>
		<h3>Rechtspfleger(-in)</h3>
		<schir-int-client-one-value-editor [type]="'select'" [formGroup]="form" [label]="'Anrede'"
		                                   [fieldControl]="getSubFormControl('rechtspfleger', 'anrede')"
		                                   [values]="anredeValues" data-test-id="rechtspfleger-anrede-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Name'"
		                                   [fieldControl]="getSubFormControl('rechtspfleger', 'name')"
		                                   data-test-id="rechtspfleger-name-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Amtsbezeichnung'"
		                                   [fieldControl]="getSubFormControl('rechtspfleger', 'amtsbezeichnung')"
		                                   data-test-id="rechtspfleger-amtsbezeichnung-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Dienstbezeichnung'"
		                                   [fieldControl]="getSubFormControl('rechtspfleger', 'dienstbezeichnung')"
		                                   data-test-id="rechtspfleger-dienstbezeichnung-input">
		</schir-int-client-one-value-editor>
		<h3>Serviceeinheit</h3>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Telefonnummer'"
		                                   [fieldControl]="getFormControl('serviceEinheitTelefon')"
		                                   data-test-id="serviceEinheitTelefon-input">
		</schir-int-client-one-value-editor>

	</form>

</mat-dialog-content>
<mat-dialog-actions data-test-id="user-settings-dialog-actions">
	<button mat-stroked-button data-test-id="cancel-button" class="focus-inwards-dark" (click)="onCancel()">Schließen
	</button>
	<button mat-flat-button data-test-id="save-button" type="submit" color="primary" class="focus-inwards-dark"
	        (click)="onSave()" [disabled]="!isValid()">Speichern
	</button>
</mat-dialog-actions>
