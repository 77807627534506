import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ChangeFileAction, FileType} from '@schir-int-client/binary-shared';
import {filter, map} from 'rxjs/operators';
import {DeleteVorlageAction, LoadVorlagenAction, SaveVorlageAction} from './vorlage.actions';
import {VorlageLinkRel} from './vorlage.linkrel';
import {CreateVorlage, VorlageResource} from './vorlage.model';
import {vorlagenSelector} from './vorlage.selectors';
import {VorlageRootState} from './vorlage.state';
import {doIfLoadingRequired} from '@schir-int-client/ngrx-helpers';
import {KorrespondenzVorlageResource} from '@schir-int-client/korrespondenz-shared';
import {Observable} from 'rxjs';

@Injectable()
export class VorlageFacade {
	constructor(private store: Store<VorlageRootState>) { }

	saveVorlage(vorlage: CreateVorlage) {
		this.store.dispatch(new SaveVorlageAction(vorlage));
	}

	deleteVorlage(vorlage: VorlageResource) {
		this.store.dispatch(new DeleteVorlageAction(vorlage));
	}

	changeFile(vorlage: VorlageResource, file: File) {
		this.store.dispatch(new ChangeFileAction(vorlage, VorlageLinkRel.UPLOAD_FILE, FileType.VORLAGE, file));
	}

	getVorlage(korrespondenzVorlage: KorrespondenzVorlageResource): Observable<VorlageResource> {
		return this.store.select(vorlagenSelector).pipe(
			filter(vorlagen => !doIfLoadingRequired(vorlagen, () => this.store.dispatch(new LoadVorlagenAction()))),
			map(vorlagen => (<VorlageResource[]>vorlagen.resource?._embedded?.vorlageList ?? []).find(vorlage => vorlage._links.self.href === korrespondenzVorlage.vorlageId)),
		);
	}

	getVorlagen(): Observable<VorlageResource[]> {
		return this.store.select(vorlagenSelector).pipe(
			filter(vorlagen => !doIfLoadingRequired(vorlagen, () => this.store.dispatch(new LoadVorlagenAction()))),
			map(vorlagen => <VorlageResource[]>vorlagen.resource?._embedded?.vorlageList ?? []),
		);
	}
}
