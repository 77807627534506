import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {VorgangFacade} from '@schir-int-client/vorgang-shared';
import {saveAs} from 'file-saver';
import {vorgangSelector} from 'libs/vorgang-shared/src/lib/vorgang.selectors';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
	CreateKorrespondenzVerfuegungByVorgangAction,
	CreateKorrespondenzVerfuegungByVorgangSuccessAction,
	CreateVerfuegungByVorgangAction,
	CreateVerfuegungByVorgangSuccessAction,
	CreateVerfuegungDruckSuccessAction,
	LoadVerfuegungAction,
	LoadVerfuegungenByVorgangAction,
	LoadVerfuegungenByVorgangSuccessAction,
	LoadVerfuegungSuccessAction,
	UpdateVerfuegungNotizAction,
	UpdateVerfuegungNotizSuccessAction,
	VerfuegungActions,
	VerfuegungDeleteAction,
	VerfuegungDeleteFailAction,
	VerfuegungDeleteSuccessAction,
	VerfuegungMarkAsErledigtAction,
} from './verfuegung.actions';
import {VerfuegungFacade} from './verfuegung.facade';
import {VerfuegungService} from './verfuegung.service';
import {of} from 'rxjs';
import {AppNotificationService} from '@schir-int-client/tech';
import {AppState} from '../../../../apps/int-client/src/app/shared/store/app-state.model';

@Injectable()
export class VerfuegungEffects {
	constructor(
		private store: Store<AppState>,
		private actions: Actions,
		private service: VerfuegungService,
		private vorgangFacade: VorgangFacade,
		private facade: VerfuegungFacade,
		private notificationService: AppNotificationService,
	) { }


	loadVerfuegungenByVorgang = createEffect(() => this.actions.pipe(
		ofType(VerfuegungActions.LOAD_VERFUEGUNGEN_BY_VORGANG, VerfuegungActions.MARK_AS_ERLEDIGT_SUCCESS),
		withLatestFrom(this.vorgangFacade.vorgang),
		mergeMap(([, vorgang]) => {
			return this.service.getVerfuegungenByVorgang(vorgang).pipe(
				map(verfuegungen => new LoadVerfuegungenByVorgangSuccessAction(vorgang, verfuegungen)),
			);
		}),
	));


	createVerfuegung = createEffect(() => this.actions.pipe(
		ofType(VerfuegungActions.CREATE_VERFUEGUNG_BY_VORGANG),
		withLatestFrom(this.store.select(vorgangSelector)),
		mergeMap(([action, vorgang]) => {
			return this.service.createVerfuegung((<CreateVerfuegungByVorgangAction>action).notiz, vorgang.resource)
				.pipe(map(verfuegung => new CreateVerfuegungByVorgangSuccessAction(vorgang.resource, verfuegung)));
		}),
	));

	deleteVerfuegung = createEffect(() => this.actions.pipe(
		ofType(VerfuegungActions.DELETE_VERFUEGUNG),
		switchMap(action => {
			const verfuegung = (<VerfuegungDeleteAction>action).verfuegung;
			return this.service.deleteVerfuegung(verfuegung).pipe(
				map(verfuegungen => {
						return new VerfuegungDeleteSuccessAction(verfuegungen, verfuegung);
					},
				),
				catchError(error => {
					return of(new VerfuegungDeleteFailAction(error));
				}),
			);
		}),
	));

	/**
	 * Aktuell wird der Delete-Button für alle Nicht-Administartoren ausgeblendet.
	 * Backend wirft Exception für den Fall eines unberechtigten Api-Aufrufs.
	 **/
	deleteVerfuegungFail = createEffect(() => this.actions.pipe(
		ofType(VerfuegungActions.DELETE_VERFUEGUNG_FAIL),
		tap(action => {
			this.notificationService.handleInfo((<VerfuegungDeleteFailAction>action).error.error.issues[0]['message'],
				null,
				'Fehlende Berechtigung');
		})), { dispatch: false });


	createKorrespondenzVerfuegung = createEffect(() => this.actions.pipe(
		ofType(VerfuegungActions.CREATE_KORRESPONDENZ_VERFUEGUNG_BY_VORGANG),
		withLatestFrom(this.store.select(vorgangSelector)),
		mergeMap(([action, vorgang]) => {
			return this.service.createKorrespondenzVerfuegung((<CreateKorrespondenzVerfuegungByVorgangAction>action).notiz, vorgang.resource)
				.pipe(map(verfuegung => new CreateKorrespondenzVerfuegungByVorgangSuccessAction(vorgang.resource, verfuegung)));
		}),
	));


	loadVerfuegung = createEffect(() => this.actions.pipe(
		ofType(VerfuegungActions.LOAD_VERFUEGUNG),
		switchMap(action => {
			const verfuegungUri = (<LoadVerfuegungAction>action).verfuegungUri;
			return this.service.getOne(verfuegungUri).pipe(
				map(verfuegung => new LoadVerfuegungSuccessAction({ ...verfuegung, aufgaben: [] })),
			);
		}),
	));


	markAsErledigt = createEffect(() => this.actions.pipe(
		ofType(VerfuegungActions.MARK_AS_ERLEDIGT),
		switchMap(action => {
			return this.service.markAsErledigt((<VerfuegungMarkAsErledigtAction>action).verfuegung).pipe(
				map(() => new LoadVerfuegungenByVorgangAction()),
			);
		}),
	));


	updateNotiz = createEffect(() => this.actions.pipe(
		ofType(VerfuegungActions.UPDATE_VERFUEGUNG_NOTIZ),
		switchMap(action => {
			const notiz: string = (<UpdateVerfuegungNotizAction>action).notiz;
			return this.service.updateNotiz(notiz, (<UpdateVerfuegungNotizAction>action).verfuegungUri).pipe(
				mergeMap(verfuegung => [new UpdateVerfuegungNotizSuccessAction(verfuegung), new LoadVerfuegungenByVorgangAction()]),
			);
		}),
	));


	drucken = createEffect(() => this.actions.pipe(
		ofType(VerfuegungActions.VERFUEGUNG_DRUCKEN),
		withLatestFrom(this.facade.verfuegung),
		switchMap(([, verfuegung]) => {
			return this.service.drucken(verfuegung).pipe(
				map(verfuegungDruck => new CreateVerfuegungDruckSuccessAction(verfuegungDruck)),
			);
		}),
	));


	download = createEffect(() => this.actions.pipe(
		ofType(VerfuegungActions.VERFUEGUNG_DRUCKEN_SUCCESS),
		tap(action => {
			const blob: Blob = (<CreateVerfuegungDruckSuccessAction>action).file;
			// response header "ContentDisposition" nicht mehr verfuegbar:
			const filename = blob.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ?
				'verfuegung.docx' : 'verfuegung.pdf';
			saveAs(blob, filename);
		}),
	), { dispatch: false });
}
