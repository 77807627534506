import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NotificationAlertComponent} from './notification-alert/notification-alert.component';
import {AppNotification, NotificationType} from './notification.model';

@Injectable({
	providedIn: 'root',
})
export class AppNotificationService {

	constructor(private matDialog: MatDialog) { }

	handleError(message: string, errorId?: string, attachment?: any) {
		const notification: AppNotification = {
			message,
			id: errorId,
			notificationType: NotificationType.ERROR,
			attachment,
			attachmentLabel: 'Details zum Fehler kopieren',
			headline: 'Es ist ein Fehler aufgetreten',
		};
		this.openAlert(notification);
	}

	handleInfo(message: string, errorId?: string, headline?: string) {
		const notification: AppNotification = {
			message,
			id: errorId,
			notificationType: NotificationType.INFO,
			headline,
		};
		this.openAlert(notification);
	}

	openAlert(notification: AppNotification) {
		this.matDialog.open(NotificationAlertComponent, {
			data: notification,
		});
	}
}
