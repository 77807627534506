<fieldset [ngClass]="formControl.invalid ? 'error' : 'noerror'">
	<legend aria-label="Empfänger auswählen"> {{ formControl.valid ? "Empfänger" : "Empfänger *" }}</legend>
	<mat-checkbox [formControl]="eigentuemerNeuFormControl" color="primary" class="focus-inwards-dark"
	              data-test-id="kontakt-kategorie-checkbox-eigentuemer-neu">
		Eigentümer neu
	</mat-checkbox>
	<mat-checkbox [formControl]="eigentuemerAltFormControl" color="primary" class="focus-inwards-dark"
	              data-test-id="kontakt-kategorie-checkbox-eigentuemer-alt">
		Eigentümer alt
	</mat-checkbox>
	<mat-checkbox [formControl]="behoerdeFormControl" color="primary" class="focus-inwards-dark"
	              data-test-id="kontakt-kategorie-checkbox-behoerden">
		Behörden
	</mat-checkbox>
	<mat-checkbox [formControl]="vertreterFormControl" color="primary" class="focus-inwards-dark"
	              data-test-id="kontakt-kategorie-checkbox-vertreter">
		Vertreter
	</mat-checkbox>
	<mat-checkbox [formControl]="glaeubigerNeuFormControl" color="primary" class="focus-inwards-dark"
	              data-test-id="kontakt-kategorie-checkbox-glaeubiger-neu">
		Gläubiger neu
	</mat-checkbox>
	<mat-checkbox [formControl]="glaeubigerAltFormControl" color="primary" class="focus-inwards-dark"
	              data-test-id="kontakt-kategorie-checkbox-glaeubiger-alt">
		Gläubiger alt
	</mat-checkbox>
	<mat-checkbox [formControl]="notarFormControl" color="primary" class="focus-inwards-dark"
	              data-test-id="kontakt-kategorie-checkbox-notar">
		Notar
	</mat-checkbox>
	<mat-checkbox [formControl]="rechtsanwaltFormControl" color="primary" class="focus-inwards-dark"
	              data-test-id="kontakt-kategorie-checkbox-rechtsanwalt">
		Rechtsanwalt
	</mat-checkbox>
	<mat-checkbox [formControl]="phgFormControl" color="primary" class="focus-inwards-dark"
	              data-test-id="kontakt-kategorie-checkbox-phg">
		PhG
	</mat-checkbox>
	<mat-checkbox [formControl]="gesellschafterFormControl" color="primary" class="focus-inwards-dark"
	              data-test-id="kontakt-kategorie-checkbox-gesellschafter">
		Gesellschafter
	</mat-checkbox>
	<mat-checkbox [formControl]="eingetragenerEigentuemerFormControl" class="focus-inwards-dark" color="primary"
	              data-test-id="kontakt-kategorie-checkbox-eingetragener-eigentuemer">
		Eingetragener Eigentümer
	</mat-checkbox>
	<mat-checkbox [formControl]="sonstigeFormControl" color="primary" class="focus-inwards-dark"
	              data-test-id="kontakt-kategorie-checkbox-sonstige">
		Sonstige
	</mat-checkbox>
</fieldset>

<mat-error *ngIf="formControl.invalid" data-test-id="invalid-checkbox-message">
	Bitte Empfänger wählen
</mat-error>
