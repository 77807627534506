<div class="fileupload">
	<input #fileInput style="display: none;" (change)="onFileChanged($event.target.files)" id="input-file-id"
	       type="file"
	       accept=".doc, .docx" data-test-id="file-path-input"/>
	<label for="input-file-id">
		<a mat-flat-button color="primary" class="focus-inwards-dark" (keyup.enter)="fileInput.click()">Word-Dokument
			hochladen</a>
	</label>
	<span *ngIf="vorlageFile" data-test-id="file-name">{{ vorlageFile.name }}</span>
</div>
