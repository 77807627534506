<h2 mat-dialog-title data-test-id="dialog-title" tabindex="0" class="narrow">Standort der
	Akte {{ aktenzeichenRendered }}</h2>
<mat-dialog-content>
	<schir-int-client-one-value-editor [type]="'select'" label="Standort" [values]="standortValues"
	                                   data-test-id="standort-input" [fieldControl]="getFormControl('standort')"
	                                   [formGroup]="form">
	</schir-int-client-one-value-editor>
	<schir-int-client-one-value-editor *ngIf="getFormControl('updatedAt').value" readonly="true" label="Seit"
	                                   data-test-id="updated-at-input" [fieldControl]="getFormControl('updatedAt')"
	                                   [formGroup]="form">
	</schir-int-client-one-value-editor>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-stroked-button (click)="onCancel()" data-test-id="cancel-button" class="focus-inwards-dark">Abbrechen
	</button>
	<button mat-flat-button (click)="onSave()" [disabled]="!canSafe()" color="primary"
	        class="icon-right focus-inwards-dark"
	        data-test-id="save-standort-button">Speichern
	</button>
</mat-dialog-actions>
