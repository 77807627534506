<fieldset class="grid" *ngIf="formGroup.enabled" data-test-id="aufgabe-versand-root">
	<legend [attr.aria-label]="'Einstellungen zu '+aufgabenTyp"></legend>
	<div class="column" [formGroup]="bodyGroup">
		<fieldset [ngClass]="formGroup.invalid ? 'error' : 'noerror'" class="column empfaenger">
			<legend aria-label="Empfänger auswählen"> {{ formGroup.valid ? "Empfänger" : "Empfänger *" }}</legend>

			<div>
				<mat-form-field appearance="outline" class="narrow">
					<mat-label>Anzahl</mat-label>
					<input min="0" matInput type="number" data-test-id="einfache-input"
					       [formControlName]="formServiceClass.feldLfprAbschriftEinfach">
				</mat-form-field>
				einfache an:
			</div>
			<fieldset *ngIf="requested(formServiceClass.feldLfprAbschriftEinfach)"
			          [formGroup]="subGroup(formServiceClass.groupLfprAbschriftEinfach)" class="inline-group"
			          data-test-id="abschrift-einfach-fieldset">
				<mat-checkbox [formControlName]="formServiceClass.feldLfprAbschriftAnGlaeubigerNeu" color="primary"
				              class="focus-inwards-dark"
				              data-test-id="abschrift-einfach-checkbox-glaeubiger-neu">
					Gläubiger neu II/
				</mat-checkbox>
				<schir-int-client-one-value-editor
						[type]="'text'" [label]="'Nr. in Abt. II'" [fieldControl]="glaeubigerNeuNrEinfach"
						color="primary" data-test-id="abschrift-einfach-input-glaeubiger-neu">
				</schir-int-client-one-value-editor>
				<br>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprAbschriftAnGlaeubigerAlt" color="primary"
				              class="focus-inwards-dark"
				              data-test-id="abschrift-einfach-checkbox-glaeubiger-alt">
					Gläubiger alt II/
				</mat-checkbox>
				<schir-int-client-one-value-editor
						[type]="'text'" [label]="'Nr. in Abt. II'" [fieldControl]="glaeubigerAltNrEinfach"
						color="primary" data-test-id="abschrift-einfach-input-glaeubiger-alt">
				</schir-int-client-one-value-editor>
				<br>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprAbschriftAnNotar" color="primary"
				              class="focus-inwards-dark"
				              data-test-id="abschrift-einfach-checkbox-notar">
					Notar
				</mat-checkbox>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprAbschriftAnEigentuemerNeu" color="primary"
				              class="focus-inwards-dark"
				              data-test-id="abschrift-einfach-checkbox-eigentuemer-neu">
					Eigentümer neu
				</mat-checkbox>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprAbschriftAnEigentuemerAlt" color="primary"
				              class="focus-inwards-dark"
				              data-test-id="abschrift-einfach-checkbox-eigentuemer-alt">
					Eigentümer alt
				</mat-checkbox>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprAbschriftAnRechtsanwalt" color="primary"
				              class="focus-inwards-dark"
				              data-test-id="abschrift-einfach-checkbox-rechtsanwalt">
					Rechtsanwalt
				</mat-checkbox>
			</fieldset>

			<div>
				<mat-form-field appearance="outline" class="narrow">
					<mat-label>Anzahl</mat-label>
					<input min="0" matInput type="number" data-test-id="beglaubigte-input"
					       [formControlName]="formServiceClass.feldLfprAbschriftBeglaubigt">
				</mat-form-field>
				beglaubigte an:
			</div>
			<fieldset *ngIf="requested(formServiceClass.feldLfprAbschriftBeglaubigt)"
			          [formGroup]="subGroup(formServiceClass.groupLfprAbschriftBeglaubigt)" class="inline-group"
			          data-test-id="abschrift-beglaubigt-fieldset">
				<mat-checkbox [formControlName]="formServiceClass.feldLfprAbschriftAnGlaeubigerNeu" color="primary"
				              class="focus-inwards-dark"
				              data-test-id="abschrift-beglaubigt-checkbox-glaeubiger-neu">
					Gläubiger neu II/
				</mat-checkbox>
				<schir-int-client-one-value-editor
						[type]="'text'" [label]="'Nr. in Abt. II'" [fieldControl]="glaeubigerNeuNrBeglaubigt"
						color="primary" data-test-id="abschrift-beglaubigt-input-glaeubiger">
				</schir-int-client-one-value-editor>
				<br>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprAbschriftAnGlaeubigerAlt" color="primary"
				              class="focus-inwards-dark"
				              data-test-id="abschrift-beglaubigt-checkbox-glaeubiger-alt">
					Gläubiger alt II/
				</mat-checkbox>
				<schir-int-client-one-value-editor
						[type]="'text'" [label]="'Nr. in Abt. II'" [fieldControl]="glaeubigerAltNrBeglaubigt"
						color="primary" data-test-id="abschrift-beglaubigt-input-glaeubiger-alt">
				</schir-int-client-one-value-editor>
				<br>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprAbschriftAnNotar" color="primary"
				              class="focus-inwards-dark"
				              data-test-id="abschrift-beglaubigt-checkbox-notar">
					Notar
				</mat-checkbox>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprAbschriftAnEigentuemerNeu" color="primary"
				              class="focus-inwards-dark"
				              data-test-id="abschrift-beglaubigt-checkbox-eigentuemer-neu">
					Eigentümer neu
				</mat-checkbox>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprAbschriftAnEigentuemerAlt" color="primary"
				              class="focus-inwards-dark"
				              data-test-id="abschrift-beglaubigt-checkbox-eigentuemer-alt">
					Eigentümer alt
				</mat-checkbox>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprAbschriftAnRechtsanwalt" color="primary"
				              class="focus-inwards-dark"
				              data-test-id="abschrift-beglaubigt-checkbox-rechtsanwalt">
					Rechtsanwalt
				</mat-checkbox>
			</fieldset>
		</fieldset>
		<mat-error *ngIf="formGroup.invalid" data-test-id="invalid-checkbox-message">
			Bitte Empfänger wählen
		</mat-error>
	</div>
	<schir-int-client-notiz-in-aufgabe-editor class="row note" [aufgabenTyp]="aufgabenTyp">
	</schir-int-client-notiz-in-aufgabe-editor>
</fieldset>
