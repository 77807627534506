<div class="x-section__headline">
	<h2 class="mat-h1 overflowing" data-test-id="headline" aria-labelledby="sr-label-vorg">
		<schir-int-client-aktenzeichen [verfahren]="verfahren"></schir-int-client-aktenzeichen>
	</h2>
	<schir-int-client-button-with-menu [verfahren]="verfahren"></schir-int-client-button-with-menu>
</div>
<div class="x-section__subline">
	<i class="material-icons" aria-hidden="true">file_copy</i>
	<h3 class="mat-body margin-right-auto">Vorgänge</h3>
	<schir-int-client-create-vorgang-button *ngIf="verfahren | hasResourceLink: verfahrenLinkRel.CREATE_VORGANG"
	                                        [verfahren]="verfahren"
	                                        cssClass="focus-inwards-light schir-inline-button"></schir-int-client-create-vorgang-button>
</div>
<div class="x-section__main">
	<div class="c-spinner" data-test-id="spinner" #spinner>
		<mat-spinner></mat-spinner>
	</div>
	<ul class="c-list" *ngIf="vorgaengeInVerfahren | async as _vorgaengeInVerfahren">
		<schir-int-client-vorgang-in-verfahren *ngFor="let vorgang of _vorgaengeInVerfahren" [vorgang]="vorgang"
		                                       [selected]="isSelected(vorgang) | async"
		                                       (selectedChange)="setSelected(vorgang)"
		                                       (notizChange)="updateNotiz(vorgang, $event)">
		</schir-int-client-vorgang-in-verfahren>
	</ul>
</div>
<span id="sr-label-vorg" class="sr-only">{{ verfahren.aktenzeichen }} Vorgänge</span>
