<div class="x-section" data-test-id="wiedervorlage-list-root">
	<div class="x-section__headline">
		<i class="material-icons" aria-hidden="true">email</i>
		<h2 class="mat-h1 margin-right-auto" tabindex="0">Wiedervorlage</h2>
		<schir-int-client-button-round class="headline-button" materialIcon="description" (click)="loadFristenListe()"
		                               cssClass="focus-inwards-light"
		                               toolTip="Fristenliste in Zwischenablage kopieren"
		                               data-test-id="fristenliste-clipboard-button"
		                               popup="dialog"></schir-int-client-button-round>
	</div>
	<div class="x-section__subline wrapping-subline">
		<div class="date-wrapper">
			<label class="date-prefix" for="vomDatum-input">Von:</label>
			<input [formControl]="vonDatumControl" matInput [matDatepicker]="pickerVom" class="date-input"
			       id="vomDatum-input"
			       data-test-id="vomDatum-input"
			       (dateInput)="dateChosen($event)">
			<mat-datepicker-toggle [for]="pickerVom" matTooltip="Kalender öffnen"
			                       aria-label="Kalender öffnen"></mat-datepicker-toggle>
			<mat-datepicker #pickerVom class="focus-inwards-light"></mat-datepicker>
		</div>
		<div class="date-wrapper">
			<label class="date-prefix" for="bisDatum-input">Bis:</label>
			<input [formControl]="bisDatumControl" matInput [matDatepicker]="pickerBis" class="date-input"
			       id="bisDatum-input"
			       data-test-id="bisDatum-input"
			       (dateInput)="dateChosen($event)">
			<mat-datepicker-toggle [for]="pickerBis" class="" matTooltip="Kalender öffnen"
			                       aria-label="Kalender öffnen"></mat-datepicker-toggle>
			<mat-datepicker #pickerBis></mat-datepicker>
		</div>
	</div>
	<div class="x-section__main" *ngIf="vorgaenge | async as _vorgaenge">
		<schir-int-client-spinner [stateResource]="_vorgaenge">
			<ul class="c-list">
				<schir-int-client-wiedervorlage *ngFor="let vorgang of _vorgaenge.resource"
				                                data-test-id="wiedervorlage"
				                                [vorgang]="vorgang"
				                                [aktenStandort]="getStandOrtAkteForVorgang(vorgang)"
				                                [assignedRechtspfleger]="getRechtspfleger(vorgang) | async"
				                                (vorgangSelected)="navigateToVorgang($event)">
				</schir-int-client-wiedervorlage>
			</ul>
		</schir-int-client-spinner>
	</div>
</div>
