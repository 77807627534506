import {Component, Input} from '@angular/core';
import {DocumentResource} from '@schir-int-client/posteingang-shared';

@Component({
	selector: 'schir-int-client-posteingang-document-viewer',
	templateUrl: './posteingang-document-viewer.component.html',
	styleUrls: ['./posteingang-document-viewer.component.scss'],
})
export class PosteingangDocumentViewerComponent {

	@Input() documents: DocumentResource[];
	selectedDocumentIndex: number;

	constructor() { }

	show(documentIndex: number) {
		this.selectedDocumentIndex = documentIndex;
	}
}
