<fieldset class="grid" *ngIf="formGroup.enabled" data-test-id="aufgabe-schuldtitel-root" [formGroup]="bodyGroup">
	<legend aria-label="Einstellungen zum Schuldtitel"></legend>
	<div class="column">
		<mat-checkbox class="column focus-inwards-dark" color="primary" data-test-id="eintragung-checkbox"
		              [formControlName]="formServiceClass.feldLfprSchuldtitelEintragung">
			Eintragung auf Schuldtitel vermerken
		</mat-checkbox>
		<mat-checkbox class="column focus-inwards-dark" color="primary" data-test-id="abschrift-checkbox"
		              [formControlName]="formServiceClass.feldLfprSchuldtitelAbschrift">
			Beglaubigte Abschrift des Titels z.d.A.
		</mat-checkbox>

		<mat-checkbox class="column focus-inwards-dark" color="primary" data-test-id="abschrift-checkbox"
		              [formControlName]="formServiceClass.feldLfprSchuldtitelVollstreckungsunterlagen">
			Vollstreckungsunterlagen an Einsender / Gläubiger
		</mat-checkbox>
	</div>
	<schir-int-client-notiz-in-aufgabe-editor class="row note" data-test-id="aufgabe-notiz"
	                                          [aufgabenTyp]="aufgabenTyp">
	</schir-int-client-notiz-in-aufgabe-editor>
</fieldset>
