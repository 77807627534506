import {Component, EventEmitter, HostListener, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {DialogAcknowledgeComponent} from './dialog-acknowledge/dialog-acknowledge.component';
import {DialogYesNoComponent} from './dialog-yes-no/dialog-yes-no.component';
import {NotificationType} from './notification.model';
import {DialogSelectionComponent} from './dialog-selection/dialog-selection.component';
import {ActiveEditorService} from './activeEditor.service';
import {ComponentType} from '@angular/cdk/overlay';
import {
	DialogButtonType,
	DialogDataAcknowledge,
	DialogDataSelection,
	DialogDataYesNo,
} from '@schir-int-client/dialog-shared';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormGroup} from '@angular/forms';
import {PotentiallyDirty, Submittable} from './dialog-warn/dialog-warn-interfaces';

export const JaNeinLabels: string[] = ['Ja', 'Nein'];

@Injectable({
	providedIn: 'root',
})
export class DialogService implements OnDestroy {

	private subscriptions: Subscription[] = [];

	constructor(private dialog: MatDialog, private activeEditorService: ActiveEditorService) {}

	openDialogYesNo(message: string,
	                label: string[] = JaNeinLabels,
	                msgParam: string[] = [],
	                additionalContent?: string[]): Observable<any> {
		const dialogRef = this.dialog.open(DialogYesNoComponent, {
			data: <DialogDataYesNo>{
				message: this.fillMessage(message, msgParam),
				additionalContent: additionalContent,
				label: [label[0], label[1]],
				agree: null,
			},
			panelClass: 'c-dialog--small',
		});
		return dialogRef.afterClosed();
	}

	openDialogSelection(message: string, label: string[],
	                    buttonType: DialogButtonType[],
	                    disabled?: boolean[],
	                    msgParam: string[] = [],
	                    additionalContent?: string[]): Observable<any> {
		const dialogRef = this.dialog.open(DialogSelectionComponent, {
			data: <DialogDataSelection>{
				message: this.fillMessage(message, msgParam),
				additionalContent: additionalContent,
				label: label,
				buttonType: buttonType,
				disabled: disabled,
				selection: null,
			},
			panelClass: 'c-dialog--small',
		});
		return dialogRef.afterClosed();
	}

	openConfirmationDialog(message: string,
	                       msgParam: string[] = [],
	                       buttonLabels: string[] = JaNeinLabels): Observable<boolean> {
		return this.openDialogYesNo(message, buttonLabels, msgParam).pipe(map(data => data ? data.agree : false));
	}

	openAcknowledgeDialog(message: string,
	                      notificationType: NotificationType,
	                      msgParam: string[] = [],
	                      buttonLabel: string = 'Ok'): Observable<boolean> {
		const dialogRef = this.dialog.open(DialogAcknowledgeComponent, {
			data: <DialogDataAcknowledge>{
				message: this.fillMessage(message, msgParam),
				notificationType,
				buttonLabel,
				acknowledged: null,
			},
			panelClass: 'c-dialog--small',
		});

		return dialogRef.afterClosed().pipe(map((data: DialogDataAcknowledge) => data ? data.acknowledged : false));
	}

	private fillMessage(message: string, msgParam: string[]): string {
		let filledMessage = message;
		msgParam.forEach((param, index) => filledMessage = filledMessage.replace('{' + index + '}', param));
		return filledMessage;
	}

	openEditorDialog<T extends SupportsWarningDialogBeforeClose & Submittable>(owner: HandlesBackdropClickAndEscapeKey<T>,
	                                                                           editorType: ComponentType<T>,
	                                                                           config?: any): MatDialogRef<T> {
		owner.matDialogRef = this.dialog.open(editorType, {
			...config,
			disableClose: true,
		});

		this.activeEditorService.setActiveEditor(owner.matDialogRef.componentInstance, owner.matDialogRef);
		owner.preventCloseOfDirtyForm();

		this.closeDialogOnSubmit(owner.matDialogRef);

		return owner.matDialogRef;
	}

	closeDialogOnSubmit(dialogRef: MatDialogRef<Submittable>): void {
		this.subscriptions.push(dialogRef.componentInstance.submitted.pipe(filter(submitted => submitted), take(1)).subscribe(_ => {
			dialogRef.close();
		}));
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}

@Component({
	template: '',
})
export abstract class SupportsWarningDialogBeforeClose implements PotentiallyDirty {

	onKeyUpEsc: EventEmitter<boolean> = new EventEmitter();
	onClickButtonClose: EventEmitter<any> = new EventEmitter();

	warningDialogVisible: boolean = false;

	/**
	 * Ermöglicht Parent-Komponenten auf das KeyUp-Event zu reagieren, ohne dass es zu einer Mehrfach-Ausführung kommt.
	 */
	@HostListener('window:keydown.esc')
	public onEsc(): void {
		if (!this.warningDialogVisible) {
			this.onKeyUpEsc.emit();
		}
	}

	warningDialogOpened(): void {
		this.warningDialogVisible = true;
	}

	warningDialogClosed(): void {
		this.warningDialogVisible = false;
	}

	abstract submit(): Promise<boolean>

	abstract reset();

	abstract isValid(): boolean;

	abstract isDirty(): boolean;
}

export abstract class BaseEditorComponent extends SupportsWarningDialogBeforeClose implements Submittable {

	submitted = new EventEmitter<boolean>();

	abstract get form(): UntypedFormGroup;

	async onSave() {
		this.submitted.emit(await this.submit());
	}

	onCancel() {
		this.onClickButtonClose.emit();
	}

	isValid(): boolean {
		return this.form.valid;
	}

	isDirty(): boolean {
		return this.form.dirty;
	}

	reset(): void {
		this.form.reset();
	}

}

export abstract class HandlesBackdropClickAndEscapeKey<T extends SupportsWarningDialogBeforeClose> {

	getConfirmMessage(): string {
		return RegisterValidationMessages.ASK_EXIT_DIALOG_WITHOUT_SAVING;
	}

	protected constructor(protected dialogService: DialogService) {}

	public matDialogRef: MatDialogRef<T>;

	public preventCloseOfDirtyForm() {
		//Escape-Key abfangen
		this.matDialogRef.componentInstance.onKeyUpEsc.subscribe(editMode => {
			this.showConfirmDialogConditionally(editMode);
		});
		//Klick auf Hintergrund abfangen
		this.matDialogRef.backdropClick().subscribe(() => {
			this.showConfirmDialogConditionally(false);
		});
		//Close-Event auch abfangen
		this.matDialogRef.componentInstance.onClickButtonClose.subscribe(() => {
			this.showConfirmDialogConditionally(false);
		});
	}

	/**
	 * Achtung! - muss manchmal anders implementiert werden.
	 * @protected
	 */
	protected showConfirmDialogConditionally(editMode: boolean) {
		if (this.matDialogRef.componentInstance['warningDialogOpened']) {
			this.matDialogRef.componentInstance.warningDialogOpened();
		}

		if (this.matDialogRef.componentInstance.isDirty()) {
			this.createConfirmDialogAndCloseOnAgree();
		} else {
			this.closeAndSetActiveEditorNull();
		}
	}

	protected createConfirmDialogAndCloseOnAgree() {
		const dialogSubscription = this.dialogService.openDialogSelection(
			this.getConfirmMessage(),
			['Abbrechen', 'Verwerfen und Schließen', 'Speichern und Schließen'],
			[DialogButtonType.CANCEL, DialogButtonType.CANCEL, DialogButtonType.SAVE],
			[false, false, !this.matDialogRef.componentInstance.isValid()],
		).subscribe(async data => {
			if (data) {
				if (data.selection == 1) {
					this.matDialogRef.componentInstance.reset();
					this.closeAndSetActiveEditorNull();
				} else if (data.selection == 2) {
					await this.matDialogRef.componentInstance.submit();
					this.closeAndSetActiveEditorNull();
				}
			}

			if (this.matDialogRef.componentInstance['warningDialogClosed']) {
				this.matDialogRef.componentInstance.warningDialogClosed();
			}

			dialogSubscription.unsubscribe();
		});
	}

	protected closeAndSetActiveEditorNull(): void {
		this.matDialogRef.close();

		if (this.matDialogRef.componentInstance['warningDialogClosed']) {
			this.matDialogRef.componentInstance.warningDialogClosed();
		}
	}
}

export enum RegisterValidationMessages {
	MISSING_REQUIRED_SELECT_VALUE = 'muss ausgewählt werden',
	WRONG_DECIMAL_NUMBER = 'maximal zwei Nachkommastellen',
	NO_VALID_VALUE = 'Bitte geben sie einen gültigen Wert ein.',
	ASK_EXIT_DIALOG_WITHOUT_SAVING = 'Die Daten wurden noch nicht gespeichert.'
}
