<fieldset class="grid" *ngIf="formGroup.enabled" data-test-id="aufgabe-mitteilung-root" [formGroup]="bodyGroup">
	<legend aria-label="Einstellungen zur Eintragungsmitteilung"></legend>
	<fieldset class="mitteilung">
		<legend aria-label="Gruppen von Adressaten"></legend>
		<!-- EIGENTUEMER_NEU -->
		<div class="row">
			<mat-checkbox class="column focus-inwards-dark" color="primary" data-test-id="eigentuemer-neu-checkbox"
			              [formControlName]="formServiceClass.feldEintragungsmitteilungEigentuemerNeuMitteilung">
				Eigentümer neu
			</mat-checkbox>
		</div>
		<fieldset class="row" [formGroup]="eigentuemerNeuGroup">
			<legend aria-label="Optionen wählen"></legend>
			<div class="column-sub" data-test-id="eigentuemer-neu-mitteilung">
				<mat-checkbox color="primary" class="focus-inwards-dark"
				              data-test-id="eigentuemer-neu-blattabschrift-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungEigentuemerNeuBlattabschrift">
					{{ mitteilungen.BLATTABSCHRIFT }}
				</mat-checkbox>
				<mat-checkbox color="primary" class="focus-inwards-dark"
				              data-test-id="eigentuemer-neu-zertifikatsberichtigung-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungEigentuemerNeuZertifikatsberichtigung">
					{{ mitteilungen.SCHIFFSZERTIFIKAT }}
				</mat-checkbox>
				<mat-checkbox color="primary" class="focus-inwards-dark"
				              data-test-id="eigentuemer-neu-auszugseinreichung-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungEigentuemerNeuAuszugseinreichung">
					{{ mitteilungen.ZERTIFIKATSAUSZUG }}
				</mat-checkbox>
			</div>
		</fieldset>

		<!-- EIGENTUEMER_ALT -->
		<div class="row">
			<mat-checkbox class="column focus-inwards-dark" color="primary" data-test-id="eigentuemer-alt-checkbox"
			              [formControlName]="formServiceClass.feldEintragungsmitteilungEigentuemerAltMitteilung">
				Eigentümer alt
			</mat-checkbox>
		</div>
		<fieldset class="row" [formGroup]="eigentuemerAltGroup">
			<legend aria-label="Optionen wählen"></legend>
			<div class="column-sub" data-test-id="eigentuemer-alt-mitteilung">
				<mat-checkbox color="primary" class="focus-inwards-dark"
				              data-test-id="eigentuemer-alt-blattabschrift-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungEigentuemerAltBlattabschrift">
					{{ mitteilungen.BLATTABSCHRIFT }}
				</mat-checkbox>
				<mat-checkbox color="primary" class="focus-inwards-dark"
				              data-test-id="eigentuemer-alt-zertifikatsberichtigung-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungEigentuemerAltZertifikatsberichtigung">
					{{ mitteilungen.SCHIFFSZERTIFIKAT }}
				</mat-checkbox>
				<mat-checkbox color="primary" class="focus-inwards-dark"
				              data-test-id="eigentuemer-alt-auszugseinreichung-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungEigentuemerAltAuszugseinreichung">
					{{ mitteilungen.ZERTIFIKATSAUSZUG }}
				</mat-checkbox>
			</div>
		</fieldset>

		<!-- GLAEUBIGER_NEU -->
		<div class="row">
			<mat-checkbox class="column focus-inwards-dark" color="primary" data-test-id="glaeubiger-neu-checkbox"
			              [formControlName]="formServiceClass.feldEintragungsmitteilungGlaeubigerNeuMitteilung">
				Gläubiger neu
			</mat-checkbox>
		</div>
		<fieldset class="row" [formGroup]="glaeubigerNeuGroup">
			<legend aria-label="Optionen wählen"></legend>
			<div class="column-sub" data-test-id="glaeubiger-neu-mitteilung">
				<mat-checkbox color="primary" class="focus-inwards-dark"
				              data-test-id="glaeubiger-neu-blattabschrift-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungGlaeubigerNeuBlattabschrift">
					{{ mitteilungen.BLATTABSCHRIFT }}
				</mat-checkbox>
				<mat-checkbox color="primary" class="focus-inwards-dark"
				              data-test-id="glaeubiger-neu-zertifikatsberichtigung-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungGlaeubigerNeuZertifikatsberichtigung">
					{{ mitteilungen.SCHIFFSZERTIFIKAT }}
				</mat-checkbox>
			</div>
		</fieldset>

		<!-- GLAEUBIGER_ALT -->
		<div class="row">
			<mat-checkbox class="column focus-inwards-dark" color="primary" data-test-id="glaeubiger-alt-checkbox"
			              [formControlName]="formServiceClass.feldEintragungsmitteilungGlaeubigerAltMitteilung">
				Gläubiger alt
			</mat-checkbox>
		</div>
		<fieldset class="row" [formGroup]="glaeubigerAltGroup">
			<legend aria-label="Optionen wählen"></legend>
			<div class="column-sub" data-test-id="glaeubiger-alt-mitteilung">
				<mat-checkbox color="primary" class="focus-inwards-dark"
				              data-test-id="glaeubiger-alt-blattabschrift-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungGlaeubigerAltBlattabschrift">
					{{ mitteilungen.BLATTABSCHRIFT }}
				</mat-checkbox>
				<mat-checkbox color="primary" class="focus-inwards-dark"
				              data-test-id="glaeubiger-alt-zertifikatsberichtigung-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungGlaeubigerAltZertifikatsberichtigung">
					{{ mitteilungen.SCHIFFSZERTIFIKAT }}
				</mat-checkbox>
			</div>
		</fieldset>

		<!-- Notar -->
		<div class="row">
			<mat-checkbox class="column focus-inwards-dark" color="primary" data-test-id="notar-checkbox"
			              [formControlName]="this.formServiceClass.feldEintragungsmitteilungNotarMitteilung">
				Notar
			</mat-checkbox>
		</div>
		<fieldset class="row" [formGroup]="notarGroup">
			<legend aria-label="Optionen wählen"></legend>
			<div class="column-sub" data-test-id="notar-mitteilung">
				<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="notar-blattabschrift-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungNotarBlattabschrift">
					{{ mitteilungen.BLATTABSCHRIFT }}
				</mat-checkbox>
			</div>
		</fieldset>

		<!-- Behörden -->
		<div class="row">
			<mat-checkbox class="column focus-inwards-dark" color="primary" data-test-id="behoerden-checkbox"
			              [formControlName]="this.formServiceClass.feldEintragungsmitteilungBehoerdenMitteilungen">
				Behörden
			</mat-checkbox>
		</div>

		<!-- BSH -->
		<div class="row">
			<mat-checkbox class="column focus-inwards-dark" color="primary" data-test-id="bsh-checkbox"
			              [formControlName]="this.formServiceClass.feldEintragungsmitteilungBshMitteilung">
				{{ mitteilungen.BSH }}
			</mat-checkbox>
		</div>
		<fieldset class="row" [formGroup]="bshGroup">
			<legend aria-label="Optionen wählen"></legend>
			<div class="column-sub" data-test-id="bsh-mitteilung">
				<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="bsh-blattabschrift-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungBshBlattabschrift">
					{{ mitteilungen.BEGLAUBIGTE_BLATTABSCHRIFT }}
				</mat-checkbox>
			</div>
		</fieldset>

		<!-- Rechtsanwaelte -->
		<div class="row">
			<mat-checkbox class="column focus-inwards-dark" color="primary" data-test-id="rechtsanwaelte-checkbox"
			              [formControlName]="formServiceClass.feldEintragungsmitteilungRechtsanwaelteMitteilung">
				Rechtsanwalt
			</mat-checkbox>
		</div>
		<fieldset class="row">
			<legend aria-label="Optionen wählen"></legend>
			<div class="column-sub" data-test-id="rechtsanwaelte-mitteilung" [formGroup]="rechtsanwaelteGroup">
				<mat-checkbox color="primary" class="focus-inwards-dark"
				              data-test-id="rechtsanwaelte-blattabschrift-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungRechtsanwaelteBlattabschrift">
					{{ mitteilungen.BLATTABSCHRIFT }}
				</mat-checkbox>
				<mat-checkbox color="primary" class="focus-inwards-dark"
				              data-test-id="rechtsanwaelte-zertifikatsberichtigung-checkbox"
				              [formControlName]="formServiceClass.feldEintragungsmitteilungRechtsanwaelteZertifikatsberichtigung">
					{{ mitteilungen.SCHIFFSZERTIFIKAT }}
				</mat-checkbox>
			</div>
		</fieldset>
	</fieldset>
	<schir-int-client-notiz-in-aufgabe-editor class="row note" [aufgabenTyp]="aufgabenTyp">
	</schir-int-client-notiz-in-aufgabe-editor>
</fieldset>
