<mat-form-field appearance="outline">
	<mat-label>{{ label }}</mat-label>
	<input *ngIf="!hasInitialFocus()" type="text" matInput [formControl]="control" [name]="label"
	       [matAutocomplete]="auto"
	       data-test-id="one-value-autocomplete-input">
	<input *ngIf="hasInitialFocus()" cdkFocusInitial type="text" matInput [formControl]="control" [name]="label"
	       [matAutocomplete]="auto"
	       data-test-id="one-value-autocomplete-input">

	<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
		<mat-option *ngFor="let value of filteredOptions | async" [value]="value.value" class="focus-inwards-dark"
		            data-test-id="one-value-autocomplete-option">
			{{ value.label }}
		</mat-option>
	</mat-autocomplete>

</mat-form-field>
