<mat-dialog-content data-test-id="dialog-message" tabindex="0" [innerHTML]="data.message"></mat-dialog-content>
<mat-list *ngIf="data.additionalContent" data-test-id="additional-content-list">
	<mat-list-item *ngFor="let c of data.additionalContent" tabindex="0"
	               data-test-id="additional-content-item">{{ c }}
	</mat-list-item>
</mat-list>
<mat-dialog-actions>
	<button mat-stroked-button dialogFocus color="primary" [mat-dialog-close]="data" (mousedown)="agree(false, false)"
	        (keydown.enter)="agree(false, true)" data-test-id="dialog-false-button"
	        class="focus-inwards-dark">{{ data.label[1] }}
	</button>
	<button mat-flat-button color="primary" [mat-dialog-close]="data" (mousedown)="agree(true, false)"
	        (keydown.enter)="agree(true, true)" data-test-id="dialog-true-button"
	        class="focus-inwards-dark">{{ data.label[0] }}
	</button>
</mat-dialog-actions>
