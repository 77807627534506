import {ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {apiRootIsLoadedSelector, ApiRootResource, apiRootSelector} from '@schir-int-client/api-root';
import {EipRegistration} from '@schir-int-client/eip-shared';
import {Observable, Subscription} from 'rxjs';
import {AppState} from './shared/store/app-state.model';
import {ErrorService} from '@schir-int-client/error';
import {ActiveEditorService} from '@schir-int-client/tech';
import {UserResource, userSelector} from '@schir-int-client/user-shared';
import {filter} from 'rxjs/operators';
import {isNil} from 'lodash-es';

@Component({
	selector: 'schir-int-client-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'int-client';

	apiRoot: Observable<ApiRootResource>;
	currentUser: Observable<UserResource>;
	isReady: Observable<boolean>;
	isError: Observable<boolean>;
	apiRootSubscription: Subscription;

	favicon: HTMLLinkElement = document.querySelector('#appIcon');

	constructor(private store: Store<AppState>,
	            private eipRegistration: EipRegistration, private errorService: ErrorService, private activeEditorService: ActiveEditorService) {
		this.apiRoot = this.store.select(apiRootSelector);
		this.currentUser = this.store.select(userSelector);
		this.isReady = this.store.select(apiRootIsLoadedSelector);
		this.isError = this.errorService.getObservable();
	}

	ngOnInit() {
		this.eipRegistration.storage.init();

		this.apiRootSubscription = this.apiRoot.pipe(filter(value => !isNil(value))).subscribe(apiRoot => {
			if (apiRoot.features.lfpr) {
				this.favicon.href = 'lfpr-favicon.ico';
			}
		});
	}

	/**
	 * Wenn der aktuell registrierte noch ungespeicherte Änderungen besitzt, wird das Schließen des Fensters mit einem Dialog abgefangen.
	 */
	@HostListener('window:beforeunload')
	onBeforeUnload() {
		// Aufruf von sendClosePartRequest() führt dazu, dass der Browser einen "Unsaved changes Dialog" einblendet, welcher bestätigt werden muss (der Aufruf schlug fehl).
		// this.eipRegistration.sendClosePartRequest();
		const editor = this.activeEditorService.getActiveEditor();
		return editor ? !editor.isDirty() : true;
	}

	ngOnDestroy(): void {
		this.apiRootSubscription?.unsubscribe();
	}
}
