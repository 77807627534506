import {Injectable, OnDestroy} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
	VerfahrenFacade,
	VerfahrenSearchKey,
	VerfahrenSearchQuery,
	VerfahrenStatus,
} from '@schir-int-client/verfahren-shared';
import {isNil} from 'lodash-es';
import {Subject, Subscription} from 'rxjs';
import {MatCheckbox} from '@angular/material/checkbox';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {filter} from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class VerfahrenGlobalSearchFormService implements OnDestroy {
	readonly form: UntypedFormGroup;

	readonly fieldSearchString = 'searchString';
	readonly fieldSearchIn = 'searchIn';
	readonly all = 'all';
	readonly options = 'options';

	private subscriptions: Subscription[] = [];
	readonly allChkbx: UntypedFormControl;
	private readonly filterChkbxs: { [p: string]: AbstractControl };

	searchFields: VerfahrenSearchKey[];
	checkBoxStatus = new Map<VerfahrenSearchKey, boolean>(); //muss separat gepflegt werden, weil die checkboxen über ihr parent-element getoggelt werden

	public allFiltersSelected: Subject<boolean> = new Subject<boolean>();

	constructor(formBuilder: UntypedFormBuilder, private facade: VerfahrenFacade, private apiRootFacade: ApiRootFacade) {
		this.subscriptions.push(this.apiRootFacade.getCurrentApiRoot().pipe(filter(apiRoot => !isNil(apiRoot))).subscribe(apiRoot => {
			this.searchFields = apiRoot.features.searchableFields;
		}));
		// TODO: oben wird this.searchFields asynchron gesetzt, in der folgenden Methode wird der Wert synchron ausgelesen.
		// this.form soll aber readonly-Feld bleiben, da sosnt diverse andere Zugriffe nicht mehr gehen
		this.form = this.createForm(formBuilder);
		this.subscriptions.push(this.subscribeToSearchQuery());
		this.subscriptions.push(this.facade.verfahrenCreatedSubject?.subscribe(msg => this.clearSearchString()));


		this.allChkbx = <UntypedFormControl>this.form.get(this.all);
		this.filterChkbxs = (<UntypedFormGroup>this.form.get(this.options)).controls;
	}

	private createForm(formBuilder: UntypedFormBuilder): UntypedFormGroup {
		return formBuilder.group({
			[this.fieldSearchString]: null,
			[this.fieldSearchIn]: new UntypedFormControl(null, [Validators.required]),
			[this.all]: true,
			[this.options]: this.createCheckboxFormGroup(formBuilder),
		});
	}

	private createCheckboxFormGroup(formBuilder: UntypedFormBuilder) {
		const config: { [key: string]: boolean } = {};

		this.searchFields.forEach((mode) => {
			config[mode] = true;
			this.checkBoxStatus.set(mode, true);
		});
		Object.keys(VerfahrenStatus).forEach(status => {
			config[status] = true;
			this.checkBoxStatus.set(status, true);
		});

		return formBuilder.group(config);
	}

	private subscribeToSearchQuery(): Subscription {
		return this.facade.verfahrenSearchBy.subscribe(query => {
			const newQuery = { ...query, filterByStatus: [query.filterByStatus] };
			this.form.patchValue(isNil(query) ? {} : newQuery);
		});
	}

	/**
	 * Ist kein searchString vorhanden:
	 * 	- wenn das Limit auf unlimitiert steht, wird als searchString leer ("") angenommen und eine Suche ausgeführt. Das Backend weiß, dass die
	 *    schnellere List-Methode verwendet werden kann
	 *  - sonst wird die Form resettet und der searchString auf null gesetzt. Letzteres hat zur Folge, dass die Methode getAll() statt search() im
	 *    Backend aufgerufen wird.
	 *
	 * Ist ein searchString vorhanden wird immer eine Suche ausgeführt.
	 *
	 * @param limit
	 */
	onSubmit(limit?: number) {
		const newQuery = {
			searchString: this.value.searchString === null ? '' : this.value.searchString,
			searchIn: this.getSearchModeFilters(),
			filterByStatus: this.getStatusFilters(),
		};

		this.facade.searchVerfahren(newQuery, limit);
	}

	getStatusFilters(): VerfahrenStatus[] {
		const selectedFilters: VerfahrenStatus[] = [];

		Object.keys(this.filterChkbxs)
			.filter(status => status in VerfahrenStatus && this.checkBoxStatus.get(status) === true)
			.forEach((status: VerfahrenStatus) => {
				selectedFilters.push(status);
			});

		return selectedFilters;
	}

	getSearchModeFilters(): string[] {
		const selectedFilters: string[] = [];

		Object.keys(this.filterChkbxs)
			.filter(mode => this.searchFields.indexOf(mode) > -1 && this.checkBoxStatus.get(mode) === true)
			.forEach((mode: string) => {
				selectedFilters.push(mode);
			});

		return selectedFilters;
	}

	enoughFiltersSelected() {
		return this.getSearchModeFilters().length > 0 && this.getStatusFilters().length > 0;
	}

	get value(): VerfahrenSearchQuery {
		return { ...this.form.value };
	}

	clearSearchString() {
		this.form.patchValue({ ...this.form.value, 'searchString': '' });
	}

	reset() {
		this.clearSearchString();
		this.onSubmit();
	}

	refreshAllChkbxState(cb: MatCheckbox) {
		this.updateCheckBoxStatus(cb);

		if (this.allChecked()) {
			this.allChkbx.setValue(true);
			this.allFiltersSelected.next(true);
		} else {
			this.allChkbx.setValue(false);
			this.allFiltersSelected.next(false);
		}
	}

	private updateCheckBoxStatus(cb: MatCheckbox) {
		const testId = cb._elementRef.nativeElement.getAttribute('id');
		const searchField = testId.replace('-checkbox', '');

		this.checkBoxStatus.set(searchField, !cb.checked);
	}

	private allChecked() {
		let allChecked = true;
		this.checkBoxStatus.forEach(value => {
			if (!value) {
				allChecked = false;
			}
		});
		return allChecked;
	}

	selectAllFilter() {
		if (this.allChkbx.value === false) {
			Object.keys(this.filterChkbxs).forEach(key => this.filterChkbxs[key].setValue(true));
			this.allChkbx.setValue(true);
			this.checkBoxStatus.forEach((v, key, map) => map.set(key, true));
			this.allFiltersSelected.next(true);
		} else {
			Object.keys(this.filterChkbxs).forEach(key => this.filterChkbxs[key].setValue(false));
			this.allChkbx.setValue(false);
			this.checkBoxStatus.forEach((v, key, map) => map.set(key, false));
			this.allFiltersSelected.next(false);
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
