<mat-dialog-content data-test-id="dialog-message" tabindex="0" [innerHTML]="data.message"></mat-dialog-content>
<mat-list *ngIf="data.additionalContent" data-test-id="additional-content-list">
	<mat-list-item *ngFor="let c of data.additionalContent" tabindex="0"
	               data-test-id="additional-content-item">{{ c }}
	</mat-list-item>
</mat-list>
<mat-dialog-actions>
	<button *ngFor="let label of data.label; let idx = index"
	        mat-flat-button [class.mat-stroked-button]="!checkSaveButton(data.buttonType[idx])"
	        [class.mat-flat-button]="checkSaveButton(data.buttonType[idx])"
	        [class.dialogFocus]="data.selection == 0"
	        color="primary"
	        [mat-dialog-close]="data"
	        (mousedown)="select(idx, false)"
	        (keydown.enter)="select(idx, true)"
	        data-test-id="dialog-selection-option-button"
	        [disabled]="isDisabled(idx)"
	        class="focus-inwards-dark">{{ data.label[idx] }}
	</button>
</mat-dialog-actions>
