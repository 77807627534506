import {createFeatureSelector, createSelector} from '@ngrx/store';
import {VorlageListResource} from './vorlage.model';
import {vorlageFeatureState, VorlageRootState} from './vorlage.state';
import {StateResource} from '@schir-int-client/ngrx-helpers';

export const selectFeature = createFeatureSelector<VorlageRootState>(vorlageFeatureState);

export const vorlagenSelector = createSelector(selectFeature, (state: VorlageRootState): StateResource<VorlageListResource> => {
	return state.vorlageRoot.vorlagen;
});
